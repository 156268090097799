import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, finalize, shareReplay } from 'rxjs/operators';
import { Banner } from 'src/app/shared/models/banner';
import { Search } from 'src/app/shared/models/search';
import { SearchItem } from 'src/app/shared/models/search-item';
import { BannerService } from 'src/app/shared/services/banner.service';
import { SateliteService } from 'src/app/shared/services/satelite.service';
import { EventHandler } from 'src/app/shared/util/handler.event';
import { SeoService } from 'src/app/shared/util/seo.service';
import { PropertyViewType, SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';
import { PAGE_TITLE, paramToUrl, transformCurrency } from 'src/globals';

declare let $: any; // jquery

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnChanges {
  paramToUrl = paramToUrl;
  transformCurrency = transformCurrency;
  isMobile: boolean = false;
  banner$: Observable<Banner>;
  bairrosDestaque: SearchItem[];
  bairros: SearchItem[];
  search = new Search();
  selectedPriceRange: number[][] = [];

  constructor(
    private ssr: SsrUtilService,
    private titleService: Title,
    private bannerService: BannerService,
    private sateliteService: SateliteService,
    private router: Router,
    private route: ActivatedRoute,
    private seo: SeoService,
    private sm: SessionManager
  ) {
    this.isMobile = this.ssr?.isMobileDevice();

    this.route.params.subscribe(params => {
      const { cidade } = params;
      if (cidade) {
        this.sm.regionDefined(cidade);
      } else {
        // define pelo cookie e retorna
        this.search = this.sm.defineSearchFromCookie();
        // muda url para auxiliar o gatilho dos chats
        if (this.search && this.search.cidade)
          // this.ssr?.getLocation().href = `home/cidade/${this.search.cidade}`;
          this.router.navigateByUrl(`/home/cidade/${this.search.cidade}`);
      }
    });
  }

  ngOnInit(): void {
    // Carrega do sessionData os parâmetros da busca
    this.search = this.sm.getSearch();
    // Reinicia o valor do viewType
    this.sm.defineViewType(PropertyViewType.GRID);

    // Limpa o corretor
    this.sm.setCorretorSlug(undefined);

    //  // Open Advice Modal on page load
    // if (this.ssr?.isBrowser) $(function () {
    //      $("#adviceModal").modal('show');
    //  });

    this.loadData();
    this.updateSEO();
    this.loadBanner();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.debug(JSON.stringify(changes));
  }

  updateSEO() {
    // title
    let title = PAGE_TITLE; // `${PAGE_TITLE} - Home`;
    this.titleService.setTitle(title);

    // Adiciona/Atualiza as Tags ao head da pagina
    this.seo
      .reset()
      .addTitle(title)
      .addDescription(
        `Private Imóveis imobiliária de alto padrão. Aqui você encontra os melhores investimentos em ${this.search?.cidadeNome}. Entre no nosso site e veja os imóveis que separamos para você!`
      );
  }

  loadData() {
    // bairros
    this.sateliteService.getBairros(this.search?.cidade).subscribe(
      result => {
        const lst = result[0].bairros;
        this.bairrosDestaque = lst.filter(x => x.imagemUrl).splice(0, 4);
        this.bairros = lst.splice(4);
      },
      error => console.error(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar bairros.'))
    );
  }

  loadBanner() {
    // banner
    this.banner$ = this.bannerService.getBanner(this.search?.cidade).pipe(
      finalize(() => {
        if (this.ssr?.isBrowser)
          $(function () {
            $('[data-toggle="popover"]').popover();
          });
      }),
      catchError(error => {
        console.log(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar banner.'));
        return of(null);
      }),
      shareReplay(1)
    );
  }

  /**
   * Vem do dropdown da busca na UI
   */
  onChangeCityUrl() {
    // muda url para permitir os gatilhos dos chats
    // tem que forçar por causa do gatilho do chat
    this.ssr.getLocation().href = `/home/cidade/${this.search?.cidade}`;
  }

  onChangeSearchStatus() {
    // avisa que o modo de busca mudou
    EventHandler.builder().searchChanged(this.search);
  }

  onChangePriceRange(val: number[]) {
    if ((val?.length || 0) <= 0) return;
    this.search.valorMin = val[0];
    if (val[1] > 0) this.search.valorMax = val[1];
    else this.search.valorMax = undefined;
  }

  goToSearch() {
    // monta params
    const params = this.search.toQuerySearch();

    // atualiza os parâmetros de consulta no sessionData
    this.sm.saveCookie(this.search);

    // muda url
    this.router.navigate(
      ['/imoveis/status', this.search.status === '0' ? 'venda' : 'alugar', 'cidade', this.search.cidade],
      {
        queryParamsHandling: 'merge',
        queryParams: params,
      }
    );
  }

  onSearchDiscounts() {
    // limpa os filtros existentes
    this.search = this.sm.clearSearch();

    // vai pra tela de busca com o filtro de descontos
    this.router.navigate(['/imoveis/status/', 'venda'], {
      queryParams: { desc: 'true', utm_source: 'home', utm_medium: 'website', utm_campaign: 'descontos-covid' },
    });
  }

  isRegionDefined(): boolean {
    return this.sm.isRegionDefined();
  }

  onRegionDefined(cidadeSlug: string) {
    this.sm.regionDefined(cidadeSlug);
    // tem que forçar por causa do gatilho do chat
    this.ssr.getLocation().href = `/home/cidade/${cidadeSlug}`;
  }
}
