import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Corretor } from '../models/corretor';
import { BaseService } from '../util/base.service';
import { SessionManager } from '../util/session-manager';

@Injectable()
export class ContatoService extends BaseService {
  constructor(
    private http: HttpClient,
    private sm: SessionManager
  ) {
    super();
  }

  envia(
    name: string,
    phone: string,
    email: string,
    city: string,
    uf: string,
    subject: string,
    message: string
  ): Observable<string> {
    const json = {
      nome: name,
      telefone: phone,
      email: email,
      cidade: city,
      uf: uf,
      assunto: subject,
      mensagem: message,
    };
    const headers = {
      corretor: this.sm.getCorretorSlug() ?? 'N/D',
    };

    return this.http.post<string>('contato', json, { headers }).pipe(catchError(this.handleError));
  }

  /**
   * Envia o contato para agendar uma visita
   */
  enviaAgendamento(
    nome: string,
    telefone: string,
    email: string,
    assunto: string,
    mensagem: string,
    data: string,
    hora: string,
    codigo: string
  ): Observable<string> {
    const json = {
      nome: nome,
      telefone: telefone,
      email: email,
      assunto: assunto,
      mensagem: mensagem,
      data: data,
      hora: hora,
      codigo: codigo,
    };
    const headers = {
      corretor: this.sm.getCorretorSlug() ?? 'N/D',
      imovel: codigo,
    };

    return this.http.post<string>('agendamento', json, { headers }).pipe(catchError(this.handleError));
  }

  /**
   * Envia o cadastro para newsletter.
   */
  enviaNewsletter(name: string, email: string): Observable<string> {
    const json = {
      nome: name,
      email: email,
    };

    return this.http.post<string>('newsletter', json).pipe(catchError(this.handleError));
  }

  /**
   * Envia o contato junto com o currículo em PDF
   */
  enviaTrabalheConosco(
    nome: string,
    telefone: string,
    email: string,
    uf: string,
    instagram: string,
    linkedin: string
  ): Observable<string> {
    const json = {
      nome,
      telefone,
      email,
      uf,
      instagram,
      linkedin,
      // mensagem: message,
      // arquivoBase64: fileBase64,
    };

    return this.http.post<string>('trabalhe-conosco', json).pipe(catchError(this.handleError));
  }

  /**
   * Envia o encomende seu imóvel
   */
  enviaEncomendeSeuImovel(
    name: string,
    phone: string,
    email: string,
    descricaoImovel: string,
    url: string
  ): Observable<string> {
    const json = {
      nome: name,
      telefone: phone,
      email: email,
      descricaoImovel: descricaoImovel,
      buscaUrl: url,
    };

    return this.http.post<string>('encomende-imovel', json).pipe(catchError(this.handleError));
  }

  /**
   *
   * @param nome
   * @param email
   * @param telefone
   * @returns
   */
  enviaParaCorretor(nome: string, email: string, telefone: string): Observable<string> {
    const json = {
      nome,
      email,
      telefone,
    };
    const headers = {
      corretor: this.sm.getCorretorSlug() ?? 'N/D',
    };

    return this.http.post<string>('corretor/contato', json, { headers }).pipe(catchError(this.handleError));
  }

  enviaWhatsappCorretor(
    corretorSlug: string,
    codigo: string,
    nome: string,
    email: string,
    telefone: string
  ): Observable<Corretor> {
    const json = {
      nome,
      telefone,
      email,
      corretorSlug: corretorSlug ?? this.sm.getCorretorSlug(),
      codigo,
    };
    const headers = {
      corretor: corretorSlug ?? this.sm.getCorretorSlug() ?? 'N/D',
    };

    return this.http.post<any>('corretor/whatsapp', json, { headers }).pipe(
      /* retryWithBackoff(), */
      map(result => new Corretor(result)),
      catchError(this.handleError)
    );
  }
}
