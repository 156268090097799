import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { BrokerList } from '../models/broker-list';
import { Corretor } from '../models/corretor';
import { BaseService } from '../util/base.service';

@Injectable()
export class CorretorService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getCorretor(slug: string): Observable<Corretor> {
    const params = {
      corretor: slug,
    };

    return this.http.get(`corretor/${slug}`, { params }).pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(Corretor, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  getCorretores(): Observable<BrokerList> {
    return this.http.get('corretor').pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(BrokerList, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }
}
