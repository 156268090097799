import { Type } from 'class-transformer';
import { paramToUrl, thisCityIsFromSP } from 'src/globals';
import { RealCurrencyPipe } from '../util/real-currency-pipe';
import { SessionManager } from '../util/session-manager';
import { Caracteristica } from './caracteristica';
import { Corretor } from './corretor';
import { ImovelFoto } from './imovel-foto';
import { ImovelVideo } from './imovel-video';
import { Infraestrutura } from './infraestrutura';

export enum StatusEnum {
  Sell = 0,
  Rent = 1,
}

export class Imovel {
  id?: string;
  aceitaDacao?: boolean;
  aceitaFinanciamento?: boolean;
  aceitaPermuta?: boolean;
  administradoraCondominio?: string;
  anoConstrucao?: number;
  aptosAndar?: number;
  aptosEdificio?: number;
  areaComum?: number;
  areaPrivativa?: number = 0;
  areaTerreno?: number;
  areaTotal?: number = 0;
  bairro?: string;
  bairroSlug?: string;
  categoria?: string;
  categoriaGrupo?: string;
  categoriaSlug?: string;
  cep?: string;
  cidade?: string;
  cidadeSlug?: string;
  codigo?: string;
  codigoAgencia?: string;
  codigoEmpreendimento?: string;
  construtora?: string;
  dataEntrega?: Date;
  dataHoraAtualizacao?: Date;
  dataLancamento?: Date;
  descricaoWeb?: string;
  destaqueWeb?: boolean;
  dimensoesTerreno?: string;
  dormitorios?: number = 0;
  empreendimento?: string;
  empreendimentoSlug?: string;
  exclusivo?: boolean;
  finalidade?: string;
  fotoDestaqueEmpreendimentoUrl?: string;
  fotoDestaqueUrl?: string;
  garagemTipo?: string;
  lancamento?: boolean;
  locacaoSobMedida?: boolean;
  pais?: string;
  paraReforma?: boolean;
  passeio360?: string;
  pavimentos?: string;
  salePrivate?: boolean;
  situacao?: number;
  status?: number;
  suites?: number = 0;
  superDestaqueWeb?: boolean;
  titulo?: string;
  tituloSite?: string;
  totalBanheiros?: number;
  uf?: string;
  vagas?: number = 0;
  valor?: number = 0; // já verifica qual mostrar
  valorAnterior?: number;
  valorCondominio?: number;
  valorIptu?: number;
  valorTotalAluguel?: number;
  varandaGourmet?: boolean;
  @Type(() => Caracteristica) caracteristicas?: Caracteristica[];
  @Type(() => Infraestrutura) infraestruturas?: Infraestrutura[];
  @Type(() => ImovelFoto) fotos?: ImovelFoto[];
  @Type(() => ImovelVideo) videos?: ImovelVideo[];
  //
  @Type(() => Corretor) private _corretor?: Corretor;
  @Type(() => Corretor) corretores?: Corretor[];
  mapaUrl?: string;

  constructor(values?: any) {
    this.codigo = '000000';
    this.caracteristicas = [];
    this.infraestruturas = [];
    this.fotos = [,]; // para iniciar o shimmer
    this.corretores = [];
    this.videos = [];

    if (values) Object.assign(this, values);
  }

  public getCorretor(sm: SessionManager): Corretor {
    let brokerSlug = sm?.getCorretorSlug();
    if ((brokerSlug?.length || 0) > 0) {
      console.log('SLUG', brokerSlug);
      this._corretor = this.corretores.filter(x => x.creciSlug == brokerSlug || x.slug == brokerSlug)?.[0];
      return this._corretor;
    }

    // 23/03/2022: Vanessa solicitou que para todas os lançamentos o whats deve ser (51) 99301-6838
    // 07/07/2022: Menos para SP
    // 03/06/2024: Desconsiderar agência 8 (incorporadoras)
    if (this.lancamento && this.uf != 'SP' && this.codigoAgencia != '8')
      return (this._corretor = new Corretor({
        id: 26,
        codigo: '232',
        nome: 'Larisson Hoff',
        fone: '(51) 99301-6838',
        celular: '(51) 99301-6838',
        fotoUrl: 'https://cdn.vistahost.com.br/privatei/vista.imobi/fotos/e_privatei_232_613f639af3608.png',
        slug: 'larisson-hoff',
      }));

    // rotaciona corretores
    if (!this._corretor) this._corretor = this.corretores[Math.floor(Math.random() * this.corretores?.length)];
    // se não tiver corretor, retorna vazio
    if (!this._corretor) return this._corretor;

    // Maurício (203)
    // Bruno Machado (250)
    // Marcelo Fortes (314)
    // Juan (337)
    // Nathalie Silva (364)
    // ... para todos estes o whats deve ser (51) 99110-6172
    if (['203', '250', '314', '337', '364'].some(x => x == this._corretor?.codigo))
      this._corretor.celular = '(51) 99110-6172';

    // 26/01/2022: Vanessa solicitou que para todas as locações de POA o whats deve ser (51) 99110-6172
    if (this.statusEx == StatusEnum.Rent && this.cidadeSlug == 'porto-alegre')
      this._corretor.celular = '(51) 99110-6172';

    return this._corretor;
  }

  public get area(): number {
    return Math.trunc(
      this.areaPrivativa && this.areaPrivativa > 0
        ? this.areaPrivativa
        : this.areaTotal && this.areaTotal > 0
        ? this.areaTotal
        : this.dimensoesTerreno
        ? parseInt(this.dimensoesTerreno)
        : 0
    );
  }

  public get isBuilding(): boolean {
    return this.categoria?.toUpperCase() == 'EMPREENDIMENTO';
  }

  public getStatus(): string {
    return paramToUrl(this.status === 0 ? 'venda' : 'alugar');
  }

  public get statusEx(): StatusEnum {
    return this.status === 0 ? StatusEnum.Sell : StatusEnum.Rent;
  }

  private getFeatures(): string {
    let s =
      this.suites && this.suites >= this.dormitorios
        ? `-${this.suites}-suite${this.suites == 1 ? '' : 's'}`
        : this.dormitorios && this.dormitorios > 0
        ? `-${this.dormitorios}-dormitorio${this.dormitorios == 1 ? '' : 's'}`
        : '';
    let b = this.bairro ? `-bairro-${this.bairro}` : '';
    let a = this.area > 0 ? `-${this.area}m2` : '';

    return paramToUrl(`${this.categoriaSlug}${s}${b}${a}`);
  }

  public get valorAluguelDetalhado(): string {
    if (this.statusEx == StatusEnum.Sell) return '';
    const rp = new RealCurrencyPipe();
    return (
      '' +
      `<div class="my-1 d-flex justify-content-between"><b>Aluguel:</b>&nbsp;&nbsp;<i>${rp.transform(
        this.valor
      )}</i></div>` +
      `<div class="my-1 d-flex justify-content-between"><b>Condomínio:</b>&nbsp;&nbsp;<i>${rp.transform(
        this.valorCondominio
      )}</i></div>` +
      `<div class="my-1 d-flex justify-content-between"><b>IPTU:</b>&nbsp;&nbsp;<i>${rp.transform(
        this.valorIptu
      )}</i></div>` +
      '<hr class="border border-gray">' +
      `<div class="my-1 d-flex justify-content-between"><b>Total:</b>&nbsp;<i>${rp.transform(
        this.valorTotalAluguel
      )}</i></div>` +
      '<span class="small font-italic">Valores de Condomínio e IPTU podem sofrer variações</span>' +
      ''
    );
  }

  public get internalUrl(): string {
    return `/imovel/${this.getStatus()}/${this.getFeatures()}/${paramToUrl(this.cidade)}/${paramToUrl(this.codigo)}`;
  }

  public get quartosEx(): number {
    return this.suites && this.suites >= this.dormitorios ? this.suites : this.dormitorios;
  }

  public get quartosLegendaEx(): string {
    return this.suites && this.suites >= this.dormitorios
      ? 'SUÍTE' + (this.quartosEx == 1 ? '' : 'S')
      : 'DORM' + (this.quartosEx == 1 ? '' : 'S') + '.';
  }

  public get isSP(): boolean {
    return thisCityIsFromSP(this.cidadeSlug);
  }

  public get podeMostrarValorAnterior(): boolean {
    return this.valorAnterior && this.valorAnterior > this.valor;
  }

  public get agro(): boolean {
    return this.categoriaGrupo == 'Agronegócio';
  }

  public get incorp(): boolean {
    return this.lancamento && this.codigoAgencia == '8';
  }
}
