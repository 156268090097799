import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { NewReleasesModule } from '../new-releases/new-releases.module';
import { PropertyModule } from '../property/property.module';
import { IncorporadoraComponent } from './components/page/incorporadora.component';
import { IncorporadoraRoutingModule } from './incorporadora-routing.module';

@NgModule({
  declarations: [IncorporadoraComponent],
  imports: [
    CommonModule,
    IncorporadoraRoutingModule,
    SharedModule,
    PropertyModule,
    NewReleasesModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    NgxPaginationModule,
    NgxHmCarouselModule,
  ],
})
export class IncorporadoraModule {
  static forRoot(): ModuleWithProviders<IncorporadoraModule> {
    return {
      ngModule: IncorporadoraModule,
    };
  }
}
