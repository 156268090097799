<ng-container *ngIf="suggests$ | async as suggests">
  <div *ngIf="isValid(suggests)" class="container-fluid text-center pb-5">
    <div class="i-title row i-results justify-content-center text-center pb-5">PESQUISE TAMBÉM POR</div>
    <div class="i-suggestions">
      <article *ngFor="let s of suggests" class="text-center px-4 pt-4 pb-3 m-2">
        <ng-container *ngIf="s.qtd > 0">
          <div class="i-item-title mb-4" [innerHtml]="s.titulo"></div>
          <div class="i-item-term mb-4" [innerHtml]="s.termo"></div>
          <div class="text-center ml-auto px-4 py-3">
            <button type="button" class="i-btn col" (click)="onSearch(s.url)">VER {{ s.qtd }}</button>
          </div>
        </ng-container>
      </article>
    </div>
  </div>
  <div class="i-extra pb-5"></div>
</ng-container>
