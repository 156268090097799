import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, startWith } from 'rxjs/operators';
import { Imovel } from 'src/app/shared/models/imovel';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { PAGE_TITLE } from 'src/globals';

@Component({
  selector: 'app-share-property',
  templateUrl: './share-property.component.html',
  styleUrls: ['./share-property.component.scss'],
})
export class SharePropertyComponent implements OnInit {
  title: string = 'Compartilhados comigo';
  properties$: Observable<Imovel[]>;
  codes: string[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private propertyService: ImovelService
  ) {
    this.titleService.setTitle(`${this.title} - ${PAGE_TITLE}`);

    this.route.params.subscribe(params => {
      const { codes } = params as { codes: string };
      this.codes = codes.split(',');
    });
  }

  ngOnInit(): void {
    if ((this.codes?.length || 0) <= 0) {
      this.router.navigateByUrl('/home');
      return;
    }

    this.properties$ = this.propertyService.getImoveis(this.codes).pipe(
      startWith(Array(3).fill(new Imovel())),
      catchError(error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar imóveis.');
        return of([]);
      })
    );
  }
}
