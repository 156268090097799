import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Pagina } from 'src/app/shared/models/pagina';
import { Search } from 'src/app/shared/models/search';
import { ContatoService } from 'src/app/shared/services/contato.service';
import { PaginaService } from 'src/app/shared/services/pagina.service';
import { showFeedbackDanger, showFeedbackSuccess } from 'src/app/shared/util/alert-swal';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { EventHandler } from 'src/app/shared/util/handler.event';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  fmGroup: UntypedFormGroup;
  pages$: Observable<Pagina[]>;
  search: Search;

  constructor(
    private ssr: SsrUtilService,
    private router: Router,
    private pageService: PaginaService,
    private formBuilder: UntypedFormBuilder,
    private contactService: ContatoService,
    private ga: AnalyticsService
  ) {
    // @diegodsp: Google Analytics em SPA
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ga.trackPage(event.urlAfterRedirects);

        // @diegodsp: bug tooltip, fica preso quando se clica nele
        if (this.ssr?.isBrowser) document.querySelectorAll('[role="tooltip"]').forEach(x => x.remove());
      }
    });

    // força nova rota
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    // captura o evento de busca para condicionar o layout à cidade
    EventHandler.builder()
      .getSearchEventEmitter()
      .subscribe(result => {
        this.search = result;
      });
  }

  ngOnInit(): void {
    this.createForm(); // form da newsletter

    this.pages$ = this.pageService.getPaginas().pipe(
      catchError(error => {
        // console.error('Erro ao consultar páginas', error);
        return of([] as Pagina[]);
      })
    );
  }

  /**
   * Rola o scroll para o topo quando mudar a rota
   *
   * @param event
   */
  onActivate(event: any) {
    if (this.ssr?.isBrowser)
      setTimeout(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }, 500);
  }

  // prepareAnimation(outlet: RouterOutlet) {
  //     return (outlet && outlet.isActivated ? outlet.activatedRoute : '');
  // }

  createForm() {
    this.fmGroup = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, Validators.required, Validators.email],
    });
  }

  onSendNewsletter() {
    // Valida os campos do form
    if (this.fmGroup.invalid) {
      showFeedbackDanger('Atenção', 'Informe seus dados corretamente no formulário para prosseguir.');
      return;
    }

    const { name, email } = this.fmGroup.value;

    this.contactService.enviaNewsletter(name, email).subscribe(
      () => {
        this.fmGroup.reset(); // limpa os campos do form
        showFeedbackSuccess(
          'Seu cadastro foi realizado!',
          'Na próxima newsletter você receberá uma cópia em seu e-mail.'
        );
      },
      error => {
        SessionManager.handleHttpError(error, 'Falha ao realizar o cadastro');
      }
    );
  }
}
