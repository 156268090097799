import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { SharedModule } from 'src/app/shared/shared.module';
import { IncorporadoraModule } from '../incorporadora/incorporadora.module';
import { PropertyModule } from '../property/property.module';
import { LancamentoEmpreendimentoComponent } from './components/lancamento-empreendimento/lancamento-empreendimento.component';
import { NewReleasesPageComponent } from './components/page/new-releases-page.component';
import { SummaryComponent } from './components/summary/summary.component';
import { NewReleasesRoutingModule } from './new-releases-routing.module';

@NgModule({
  declarations: [NewReleasesPageComponent, LancamentoEmpreendimentoComponent, SummaryComponent],
  exports: [LancamentoEmpreendimentoComponent, SummaryComponent],
  imports: [
    CommonModule,
    NewReleasesRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    NgxHmCarouselModule,
    PropertyModule,
  ],
})
export class NewReleasesModule {
  static forRoot(): ModuleWithProviders<NewReleasesModule> {
    return {
      ngModule: NewReleasesModule,
    };
  }
}
