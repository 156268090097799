import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { Client } from '../models/client';
import { Imovel } from '../models/imovel';
import { BaseService } from '../util/base.service';
import { Guid } from '../util/guid';
import { SessionManager } from '../util/session-manager';
import { AuthService } from './auth.service';

export enum LoginType {
  Email,
  Google,
  Facebook,
}

@Injectable()
export class ClientService extends BaseService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private sm: SessionManager
  ) {
    super();
  }

  /**
   * Cadastra novo cliente.
   *
   * @param user
   */
  insert(item: Client): Observable<any> {
    return this.http.post<any>('cliente', item).pipe(catchError(this.handleError));
  }

  /**
   * Retorna os meus dados
   *
   * @param id
   */
  getMyData(): Observable<Client> {
    return this.http
      .get<Client>('cliente/meus-dados')
      .pipe(/* retryWithBackoff(), */ catchError(this.handleError), shareReplay(1));
  }

  /**
   * Atualiza os dados do usuário corrente
   *
   * @param user
   */
  update(item: Client): Observable<any> {
    return this.http.put<any>('cliente', item).pipe(catchError(this.handleError));
  }

  /**
   * Remove o usuário
   *
   * @param clientID somente o ID
   * @returns
   */
  delete(): Observable<any> {
    return this.http.delete<any>('cliente').pipe(catchError(this.handleError));
  }

  getCompareList(): Observable<Imovel[]> {
    return this.http.get<any>('cliente/comparar').pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Imovel, x))),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  addToCompare(codigo: string): Observable<any> {
    return this.http.post<any>(`cliente/comparar/${codigo}`, {}).pipe(catchError(this.handleError));
  }

  delFromCompareList(codigo: string): Observable<any> {
    return this.http.delete<any>(`cliente/comparar/${codigo}`, {}).pipe(catchError(this.handleError));
  }

  getFavoriteList(): Observable<string[]> {
    return this.http
      .get<string[]>('cliente/favorito')
      .pipe(/* retryWithBackoff(), */ catchError(this.handleError), shareReplay(1));
  }

  saveFavorite(isFavorite: boolean, code: string) {
    if (!this.sm.hasToken()) return;

    if (isFavorite)
      this.addToFavorite(code).subscribe(
        () => console.log('Favorito adicionado'),
        error => console.error('Erro ao adicionar favorito', error)
      );
    else
      this.delFromFavoriteList(code).subscribe(
        () => console.log('Favorito removido'),
        error => console.error('Erro ao remover favorito', error)
      );
  }

  addToFavorite(codigo: string): Observable<any> {
    return this.http.post<any>(`cliente/favorito/${codigo}`, {}).pipe(catchError(this.handleError));
  }

  delFromFavoriteList(codigo: string): Observable<any> {
    return this.http.delete<any>(`cliente/favorito/${codigo}`, {}).pipe(catchError(this.handleError));
  }

  getDiscardedList(): Observable<string[]> {
    return this.http
      .get<string[]>('cliente/descartado')
      .pipe(/* retryWithBackoff(), */ catchError(this.handleError), shareReplay(1));
  }

  saveDiscard(isDiscarded: boolean, code: string) {
    if (!this.sm.hasToken()) return;

    if (isDiscarded)
      this.addToDiscard(code).subscribe(
        () => console.log('Descartado adicionado'),
        error => console.error('Erro ao adicionar descartado', error)
      );
    else
      this.delFromDiscardedList(code).subscribe(
        () => console.log('Descartado removido'),
        error => console.error('Erro ao remover descartado', error)
      );
  }

  addToDiscard(codigo: string): Observable<any> {
    return this.http.post<any>(`cliente/descartado/${codigo}`, {}).pipe(catchError(this.handleError));
  }

  delFromDiscardedList(codigo: string): Observable<any> {
    return this.http.delete<any>(`cliente/descartado/${codigo}`, {}).pipe(catchError(this.handleError));
  }

  signIn(type: LoginType, id: string, name: string, email: string, senha: string) {
    if (type != LoginType.Email) senha = id;

    return this.auth.login(email, senha).pipe(
      map(result => {
        this.sm.login(result.token, result.refreshToken);
      }),
      catchError(error => {
        if (type != LoginType.Email) return this.signUp(type, id, name, email);
        return throwError(error);
      })
    );
  }

  signUp(type: LoginType, id: string, name: string, email: string) {
    let cli = new Client();
    if (type == LoginType.Google) cli.googleID = id;
    else cli.facebookID = id;
    cli.nome = name;
    cli.email = email;
    cli.novaSenha = Guid.create().toString();
    return this.insert(cli).pipe(
      // switchMap para retornar outro Observable
      switchMap(() => this.signIn(LoginType.Email, null, name, email, cli.novaSenha))
    );
  }
}
