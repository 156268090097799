import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PropertyComponent } from './components/property/property.component';
import { SearchComponent } from './components/search/search.component';
import { SharePropertyComponent } from './components/share-property/share-property.component';

const routes: Routes = [
  { path: 'imoveis/status/:status', component: SearchComponent },
  { path: 'imoveis/status/:status/cidade/:cidade', component: SearchComponent },
  { path: 'imoveis/status/:status/cidade/:cidade/zona/:zone', component: SearchComponent },
  { path: 'imovel/:status/:features/:cidade/:code', component: PropertyComponent },
  // ------------------------------------------
  { path: 'share/imoveis/:codes', component: SharePropertyComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PropertyRoutingModule {}
