import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { FilteringType } from 'src/app/shared/components/filtering/filtering.component';
import { Bairro } from 'src/app/shared/models/bairro';
import { Search } from 'src/app/shared/models/search';
import { SateliteService } from 'src/app/shared/services/satelite.service';
import { EventHandler } from 'src/app/shared/util/handler.event';
import { SessionManager } from 'src/app/shared/util/session-manager';

@Component({
  selector: 'app-select-bairro',
  templateUrl: './select-bairro.component.html',
  styleUrls: ['./select-bairro.component.scss'],
})
export class SelectBairroComponent implements OnInit {
  grupoBairros: Bairro[];
  bairrosSelecionados: string[] = [];
  @ViewChild('selectNeighborhood') selectBairros: MatSelectionList;
  @Input() uniqueId: string;
  @Input() search: Search;
  @Input() showModal: boolean = true;
  @Output() changeSearch = new EventEmitter<Search>(true);

  constructor(
    private cd: ChangeDetectorRef,
    private sateliteService: SateliteService
  ) {}

  ngOnInit(): void {
    this.grupoBairros = [];
    this.bairrosSelecionados = [];
    this.loadData();
    this.cd.detectChanges();

    //
    EventHandler.builder()
      .getSearchEventEmitter()
      .subscribe(result => {
        this.search = result;
        this.loadData();
      });

    //
    EventHandler.builder()
      .getFilterClearingEventEmitter()
      .subscribe(callback => {
        if (callback.type == FilteringType.Todos || callback.type == FilteringType.Bairro) {
          this.search.bairros = [];
        }
      });
  }

  loadData() {
    this.sateliteService.getBairros(this.search?.cidade).subscribe(
      result => {
        this.grupoBairros = result;
        if (this.grupoBairros?.[0]?.bairros?.length <= 0 || this.grupoBairros?.[1]?.bairros?.length <= 0)
          this.showModal = false;
      },
      error => console.error(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar bairros.'))
    );
  }

  /**
   * Click no ok do modal para seleção dos bairros
   */
  onSelected(lst: MatSelectionList) {
    // Atualiza search com bairros selecionados
    this.search.bairros = lst.selectedOptions.selected.map(x => x.value);

    this.cd.detectChanges();
    this.changeSearch?.emit(this.search);
  }

  onItemsChecked(b: boolean, group: string) {
    if (!this.search.bairros || !this.grupoBairros) return;

    let lst: string[] = this.grupoBairros?.map(x => x.grupo == group && x.bairros.map(y => y.slug)).flat();
    if (lst?.length > 0) {
      // sempre limpa tudo
      this.search.bairros = this.search.bairros.filter(x => !lst.includes(x));
      // adiciona lista, se necessário
      if (b) this.search.bairros = this.search.bairros.concat(lst);
    }

    // atualiza a lista da tela, pois estava com bug
    this.selectBairros.options.forEach(x => {
      x.selected = this.search.bairros.includes(x.value);
    });

    this.cd.detectChanges();
    this.changeSearch.emit(this.search);
  }

  isItemSelected(slug: string) {
    return this.search?.bairros?.includes(slug);
  }
}
