import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Search } from 'src/app/shared/models/search';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { transformCurrency } from 'src/globals';
import { EventHandler } from '../../util/handler.event';

export enum FilteringType {
  Todos = -1,
  Tipo = 0,
  Bairro,
  Codigo,
  Dormitorio,
  Suite,
  Vaga,
  Area,
  Valor,
  Desconto,
  Permuta,
  Construcao,
  Novo,
  Exclusividade,
  Destaque,
  Construtora,
  Usado,
  Mobiliado,
  Empreendimento,
  Lancamento,
  ParaReforma,
  BolaVez,
}

export class FilteringCallback {
  type: FilteringType;
  search: Search;

  constructor(type: FilteringType, search: Search) {
    this.type = type;
    this.search = search;
  }
}

@Component({
  selector: 'app-filtering',
  templateUrl: './filtering.component.html',
  styleUrls: ['./filtering.component.scss'],
})
export class FilteringComponent {
  transformCurrency = transformCurrency;
  @Input() title: string = 'FILTRANDO POR:';
  @Input() showClear: boolean = true;
  @Input() forceShow: boolean = false;
  @Input() search: Search;
  @Input() class: string = 'mb-3';
  @Input() theme: string = 'gray';
  @Output() clear = new EventEmitter<FilteringCallback>();

  constructor(private sm: SessionManager) {}

  /**
   * Limpa um filtro específico ou todos os filtros
   *
   * @param typeFilter tipo do filtro
   */
  onClearFilter(typeFilter: number) {
    switch (typeFilter) {
      case -1:
        this.search = this.sm.clearSearch();
        break;
      case 0:
        this.search.categorias = []; // Tipo
        break;
      case 1:
        this.search.bairros = []; // Bairros
        break;
      case 2:
        this.search.codigo = undefined; // Código
        break;
      case 3:
        this.search.dormitorios = []; // Dormitórios
        break;
      case 4:
        this.search.suites = []; // Suites
        break;
      case 5:
        this.search.vagas = []; // Vagas
        break;
      case 6:
        this.search.areaMin = undefined; // Área min
        this.search.areaMax = undefined; // Área max
        break;
      case 7:
        this.search.valorMin = undefined; // Valor min
        this.search.valorMax = undefined; // Valor max
        break;
      case 8:
        this.search.desconto = []; // Desconto
        break;
      case 9:
        this.search.permuta = []; // Permuta
        break;
      case 10:
        this.search.construcao = []; // Construção
        this.search.lancamento = []; // Lançamento
        break;
      case 11:
        this.search.novo = []; // Novo (ex-Pronto)
        break;
      // case 12:
      //   this.search.exclusivo = []; // Exclusividade
      //   break;
      case 13:
        this.search.destaque = []; // Destaque
        break;
      case 14:
        this.search.construtoras = []; // Construtoras
        break;
      case 15:
        this.search.usado = []; // Usado
        break;
      case 16:
        this.search.mobiliado = []; // Mobiliado
        break;
      case 17:
        this.search.empreendimentos = []; // Empreendimentos
        break;
      case 18:
        this.search.construcao = []; // Construção
        this.search.lancamento = []; // Lançamento
        break;
      case 19:
        this.search.reforma = []; // Para reforma
        break;
      case 20:
        this.search.bolaVez = []; // Bola da vez
        break;
      default:
        break;
    }

    // diz que o filtro foi limpo
    this.clear?.emit(new FilteringCallback(typeFilter, this.search));
    EventHandler.builder().filterClearing(new FilteringCallback(typeFilter, this.search));
  }
}
