import { Type } from 'class-transformer';
import { UFS } from 'src/globals';
import { Cidade } from './cidade';

export class Uf {
  nome: string;
  @Type(() => Cidade) cidades: Cidade[];

  constructor(values?: any) {
    if (values) Object.assign(this, values);
  }

  public get nomeCompleto(): string {
    return UFS[this.nome];
  }

  public static get default(): Uf[] {
    return [
      {
        nome: 'RS',
        nomeCompleto: 'Rio Grande do Sul',
        cidades: [{ nome: 'Porto Alegre', slug: 'porto-alegre', uf: 'RS' }],
      },
      {
        nome: 'SP',
        nomeCompleto: 'São Paulo',
        cidades: [{ nome: 'São Paulo', slug: 'sao-paulo', uf: 'SP' }],
      },
    ];
  }
}
