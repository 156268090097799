import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Bairro } from '../models/bairro';
import { Category } from '../models/category';
import { Cidade } from '../models/cidade';
import { SearchItem } from '../models/search-item';
import { Uf } from '../models/uf';
import { BaseService } from '../util/base.service';

@Injectable()
export class SateliteService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Retorna as cidades
   */
  getCidades(): Observable<Cidade[]> {
    return this.http.get<any>('cidade').pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Cidade, x))),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna estados com cidades
   */
  getUfs(agro: boolean = false): Observable<Uf[]> {
    return this.http
      .get<any>('uf', {
        params: {
          agro,
        },
      })
      .pipe(
        /* retryWithBackoff(), */
        map(result => result.map(x => plainToClass(Uf, x))),
        catchError(this.handleError),
        shareReplay(1)
      );
  }

  /**
   * Retorna as categorias
   */
  getCategorias(): Observable<Category> {
    return this.http.get<any>('categoria').pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(Category, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna os bairros de uma cidade
   *
   * @param cidadeSlug Cidade
   */
  getBairros(cidadeSlug: string): Observable<Bairro[]> {
    return this.http.get<any>(`bairro/${cidadeSlug}`).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Bairro, x))),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna as construtoras disponíveis
   *
   * @param cidadeSlug Cidade
   */
  getConstrutoras(cidadeSlug: string): Observable<SearchItem[]> {
    /* retryWithBackoff(), */
    return this.http.get<any>(`construtora/${cidadeSlug}`).pipe(
      map(result => result.map(x => plainToClass(SearchItem, x))),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna os empreendimentos disponíveis
   *
   * @param cidadeSlug Cidade
   * @param status Status desejado
   */
  getEmpreendimentos(cidadeSlug: string, status: string): Observable<SearchItem[]> {
    return this.http.get<any>(`empreendimento/${cidadeSlug}/${status}`).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(SearchItem, x))),
      catchError(this.handleError),
      shareReplay(1)
    );
  }
}
