<!--HEADER-->
<div class="i-page-top">
  <div class="container">
    <!-- Menu -->
    <div class="i-menu row align-items-center pt-3 pb-2">
      <div class="navbar-brand col-6 col-sm-8 col-md-6 col-lg-4 mr-auto py-2 px-2 text-center text-sm-left">
        <a href="#" routerLink="/home"
          ><img src="assets/img/logo_cinza.svg" class="img-fluid my-auto" alt="Private"
        /></a>
      </div>
    </div>
  </div>
</div>
<!--BODY-->
<div>
  <router-outlet #outlet="outlet"></router-outlet>
</div>
<!--CINTA-->
<div class="i-belt pb-4 px-2"></div>
<!--FOOTER--->
<div class="i-footer container pt-4 pb-2">
  <div class="row pt-5 pb-1 text-center text-md-left">
    <div class="col-12 col-md-4 col-lg-4 py-4 py-md-5">
      <div class="i-address pt-4 px-1">
        Private Investimentos Imobiliários Ltda.<br />
        CNPJ 12.076.845/0001-40<br />
        <a routerLink="/home">Voltar ao site - www.privateimoveis.com</a>
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-4 py-4 py-md-5">
      <div class="i-address pt-3 px-1">
        Rua 24 de outubro, 1440, sala 1605<br />
        Porto Alegre, RS, CEP 90510-001<br />
        CRECI 22889-J
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-4 py-4 py-md-5">
      <div class="i-address pt-3 px-1">
        R. Dr. Fernandes Coelho, 85, 9 andar<br />
        Pinheiros, São Paulo, SP, CEP 05423-040<br />
        <!-- Av. Cidade Jardim, 400, Edifício Dacon<br />
        São Paulo, SP, CEP 01454-901<br /> -->
        CRECI 39391-J
      </div>
    </div>
  </div>
  <div class="i-bottom row pt-5">
    <div class="i-rights col-12 col-md-6 my-auto py-2 text-center text-md-left">
      <!-- Construído em 2019 -->
      &copy; Private 2024 &nbsp; |
      <a
        class="i-developer pb-2"
        href="http://www.isul.com.br/?utm_source=private&utm_medium=website&utm_campaign=web"
        target="_blank"
        rel="noopener"
        ><img
          lazyLoad="assets/img/logo_isul.svg"
          class="img-fluid pl-1 pb-1"
          alt="ISul Tecnologia"
          width="40px"
          height="13px"
      /></a>
    </div>
  </div>
</div>
