import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SearchSuggest } from 'src/app/shared/models/search-suggest';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { SessionManager } from 'src/app/shared/util/session-manager';

@Component({
  selector: 'app-search-suggest',
  templateUrl: './search-suggest.component.html',
  styleUrls: ['./search-suggest.component.scss'],
})
export class SearchSuggestComponent implements OnInit {
  suggests$: Observable<SearchSuggest[]>;
  @Input() code: string;

  constructor(
    private ga: AnalyticsService,
    private router: Router,
    private service: ImovelService,
    private sm: SessionManager
  ) {}

  ngOnInit(): void {
    this.loadSuggests();
  }

  loadSuggests() {
    this.suggests$ = this.service.getSugestoes(this.code).pipe(
      map(x => x.filter(y => y.termo != '-' && y.termo?.indexOf(' 0m') < 0)),
      catchError(error => {
        // SessionManager.handleHttpError(error, 'Falha ao consultar recomendações de busca');
        console.error('Falha ao consultar recomendações de busca', error);
        return of([] as SearchSuggest[]);
      })
    );
  }

  isValid(suggests: SearchSuggest[]): boolean {
    return suggests?.length > 0 && suggests?.filter(x => x.qtd > 0)?.length > 0;
  }

  onSearch(url: string) {
    // Evento do Google Analytics
    this.ga.trackEvent(this.code, 'search-suggest', url);
    // limpa a busca atual, para realizar uma nova
    this.sm.clearSearch();
    // busca de acordo com a url da sugestão
    this.router.navigateByUrl(url);
  }
}
