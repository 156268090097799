import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Imovel } from '../../models/imovel';
import { ClientService } from '../../services/client.service';
import { AnalyticsService } from '../../util/analytics.service';
import { SessionManager } from '../../util/session-manager';
import { SsrUtilService } from '../../util/ssr-util.service';

declare let $: any; // jquery

@Component({
  selector: 'app-box-carousel',
  templateUrl: './box-carousel.component.html',
  styleUrls: ['./box-carousel.component.scss'],
})
export class BoxCarouselComponent implements OnInit {
  @Input() property: Imovel;
  @Input() disableDrag = false;
  @Input() targetBlank: boolean = false;
  @Input() numberOfPhotos: number = 5;
  isFavorite: boolean;
  isDiscarded: boolean;
  currentIndex = 0;
  scrollEvent = new Event('scroll');

  constructor(
    readonly ssr: SsrUtilService,
    private router: Router,
    private ga: AnalyticsService,
    private sm: SessionManager,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    if (!this.property) return;

    this.isFavorite = this.sm.isFavorite(this.property.codigo);
    this.isDiscarded = this.sm.isDiscarded(this.property.codigo);
  }

  refreshTooltip() {
    // tooltip
    if (this.ssr?.isBrowser)
      $(function () {
        // tem que ser jquery
        $('[role="tooltip"]').remove();
        $('[data-toggle="tooltip"]').tooltip('dispose').tooltip();
      });
  }

  onChangeIndex() {
    // ng-lazyload-image
    if (this.ssr?.isBrowser) this.ssr?.getWindow().dispatchEvent(this.scrollEvent);
    // Evento do Google Analytics
    this.ga.trackEvent('photo', 'carousel-box', this.property?.codigo, this.currentIndex);
  }

  onChangeFavorite() {
    this.isFavorite = this.sm.toggleFavorite(this.property.codigo);
    this.clientService.saveFavorite(this.isFavorite, this.property?.codigo);
    // Evento do Google Analytics
    this.ga.trackEvent('click', this.isFavorite ? 'favorite' : 'unfavorite', this.property.codigo);

    this.refreshTooltip();
  }

  onChangeDiscarded() {
    if (!this.sm.hasToken()) {
      this.refreshTooltip();
      Swal.fire({
        title: '',
        text: 'Para usar este recurso, faça seu login ou cadastre-se.',
        showCancelButton: true,
        confirmButtonText: 'Fazer login',
        cancelButtonText: 'Continuar navegando',
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        if (result.isConfirmed)
          this.router.navigate(['/login'], { queryParams: { redirectUrl: this.ssr?.getLocation().href } });
      });
    } else {
      this.isDiscarded = this.sm.toggleDiscarded(this.property?.codigo);
      this.clientService.saveDiscard(this.isDiscarded, this.property?.codigo);
      // Evento do Google Analytics
      this.ga.trackEvent('click', this.isDiscarded ? 'discard' : 'undiscard', this.property?.codigo);

      this.refreshTooltip();
    }
  }
}
