import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { PropertyModule } from '../property/property.module';
import { CampaignRoutingModule } from './campaign-routing.module';
import { OrangesPropertiesComponent } from './components/orange-tag/oranges-properties/oranges-properties.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
  dropSpecialCharacters: false,
};

@NgModule({
  declarations: [OrangesPropertiesComponent],
  exports: [OrangesPropertiesComponent],
  imports: [
    CommonModule,
    CampaignRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    PropertyModule,
  ],
})
export class CampaignModule {
  static forRoot(): ModuleWithProviders<CampaignModule> {
    return {
      ngModule: CampaignModule,
    };
  }
}
