export class Client {
  id: string;
  facebookID: string;
  googleID: string;
  nome: string;
  cpf: string;
  email: string;
  telefone: string;

  novaSenha: string;
}
