import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay, startWith } from 'rxjs/operators';
import { appendArray, toStr } from 'src/globals';
import { BairroWalkScore } from '../models/bairro-walkscore';
import { Imovel } from '../models/imovel';
import { ImovelLancamento } from '../models/imovel-lancamento';
import { Search, SearchOrderEnum } from '../models/search';
import { ResultPage } from '../models/search-result';
import { SearchSuggest } from '../models/search-suggest';
import { BaseService } from '../util/base.service';
import { SessionManager } from '../util/session-manager';

@Injectable()
export class ImovelService extends BaseService {
  constructor(
    private http: HttpClient,
    private sm: SessionManager
  ) {
    super();
  }

  /**
   * Retorna os detalhes do imóvel
   *
   * @param codigo
   */
  getImovel(codigo: string): Observable<Imovel> {
    return this.http.get<any>(`imovel/${codigo}/detalhe`).pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(Imovel, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna os imóveis informados
   *
   * @param codigos
   */
  getImoveis(codigos: string[]): Observable<Imovel[]> {
    let params = new HttpParams();
    params = appendArray(params, 'codigos', codigos);

    return this.http.get<any>('imovel/codigos', { params }).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Imovel, x))),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna os imóveis em destaque
   *
   * @param cidadeSlug
   */
  getDestaques(cidadeSlug: string, status: string): Observable<Imovel[]> {
    let params = new HttpParams().set('status', toStr(status));

    return this.http.get<any>(`imovel/destaque/${cidadeSlug}`, { params }).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Imovel, x))),
      // filtra os descartados
      map(result => {
        const codes = this.sm.getDiscards();
        return result?.filter(x => !codes.some(y => y === x.codigo));
      }),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna os imóveis mais acessados
   *
   * @param cidadeSlug
   */
  getMaisAcessados(cidadeSlug: string): Observable<Imovel[]> {
    return this.http.get<any>(`imovel/acesso/${cidadeSlug}`).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Imovel, x))),
      // filtra os descartados
      map(result => {
        const codes = this.sm.getDiscards();
        return result?.filter(x => !codes.some(y => y === x.codigo));
      }),
      // pre-fetch da lista para shimmer
      startWith(Array(6).fill(new Imovel())),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna os empreendimentos em lançamento
   */
  getEmpreendimentosIncorporadoras(cidadeSlug: string): Observable<ImovelLancamento[]> {
    return this.http.get<any>(`empreendimento/lancamento/incorporadora/${cidadeSlug}`).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(ImovelLancamento, x))),
      // pre-fetch da lista para shimmer
      startWith(Array(4).fill(new ImovelLancamento())),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna os empreendimentos em lançamento
   */
  getEmpreendimentosEmLancamento(search: Search, home: boolean = true): Observable<ImovelLancamento[]> {
    let params = search.toParams();
    params = params.set('home', toStr(home));

    return this.http.get<any>(`empreendimento/lancamento`, { params }).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(ImovelLancamento, x))),
      // pre-fetch da lista para shimmer
      startWith(Array(home ? 4 : 6).fill(new ImovelLancamento())),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna os imóveis em lançamento
   */
  getLancamentos(cidadeSlug: string): Observable<Imovel[]> {
    return this.http.get<any>(`imovel/lancamento/${cidadeSlug}`).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Imovel, x))),
      // pre-fetch da lista para shimmer
      startWith(Array(6).fill(new Imovel())),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna X imóveis recentes.
   */
  getRecentes(cidadeSlug: string, max: number = 10): Observable<Imovel[]> {
    return this.search(
      new Search({
        cidade: cidadeSlug,
        ordem: SearchOrderEnum.Recencia,
        pag: 1,
      })
    ).pipe(
      // pega só a lista de imóveis
      map(result => result.items?.map(x => plainToClass(Imovel, x))),
      // reduz para o max desejado
      map(result => result.slice(0, max)),
      // pre-fetch da lista para shimmer
      startWith(Array(6).fill(new Imovel()))
    );
  }

  /**
   * Retorna os imóveis em recomendação
   *
   * @param codigo Código do imóvel
   */
  getRecomendacoes(codigo: string): Observable<Imovel[]> {
    return this.http.get<any>(`imovel/${codigo}/recomendacao`).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Imovel, x))),
      // filtra os descartados
      map(result => {
        const codes = this.sm.getDiscards();
        return result?.filter(x => !codes.some(y => y === x.codigo));
      }),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna os imóveis do empreendimento
   *
   * @param codigo Código do empreendimento
   */
  getImoveisDoEmpreendimento(codigo: string): Observable<Imovel[]> {
    return this.http.get<any>(`imovel/${codigo}/empreendimento`).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Imovel, x))),
      // filtra os descartados
      map(result => {
        const codes = this.sm.getDiscards();
        return result?.filter(x => !codes.some(y => y === x.codigo));
      }),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  getEtiquetaLaranja(cidadeSlug: string): Observable<Imovel[]> {
    return this.http.get<any>(`imovel/imovel-etiqueta-laranja/${cidadeSlug}`).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Imovel, x))),
      // pre-fetch da lista para shimmer
      startWith(Array(6).fill(new Imovel())),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna as sugestões de busca
   *
   * @param codigo Código do imóvel
   */
  getSugestoes(codigo: string): Observable<SearchSuggest[]> {
    return this.http.get<any>(`imovel/${codigo}/sugestao-busca`).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(SearchSuggest, x))),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  /**
   * Retorna a quantidade de itens da consulta de imóveis
   */
  searchCount(search: Search): Observable<number> {
    let params = search.toParams();

    return this.http
      .get<number>('imovel/count', { params })
      .pipe(/* retryWithBackoff(), */ catchError(this.handleError), shareReplay(1));
  }

  /**
   * Retorna os imóveis
   */
  search(search: Search): Observable<ResultPage<Imovel>> {
    let params = search.toParams();

    return this.http.get<any>('imovel', { params: params }).pipe(
      /* retryWithBackoff(), */
      map(x => new ResultPage(x, Imovel)),
      // filtra os descartados
      map(result => {
        if (!result?.items) return result;
        const codes = this.sm.getDiscards();
        result.items = result.items.filter(x => !codes.some(y => y === x.codigo));
        return result;
      }),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  getWalkscore(cidadeSlug: string, bairroSlug: string): Observable<BairroWalkScore> {
    return this.http.get<any>(`bairro/walkscore/${cidadeSlug}/${bairroSlug}`).pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(BairroWalkScore, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }
}
