import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { CorretorModule } from '../corretor/corretor.module';
import { PropertyListComponent } from './components/property-list/property-list.component';
import { PropertyComponent } from './components/property/property.component';
import { SchedulingComponent } from './components/scheduling/scheduling.component';
import { SearchOrderComponent } from './components/search-order/search-order.component';
import { SearchSuggestComponent } from './components/search-suggest/search-suggest.component';
import { SearchComponent } from './components/search/search.component';
import { SelectBairroComponent } from './components/select-bairro/select-bairro.component';
import { SelectCidadeComponent } from './components/select-cidade/select-cidade.component';
import { SelectTipoImovelComponent } from './components/select-tipo-imovel/select-tipo-imovel.component';
import { SharePropertyComponent } from './components/share-property/share-property.component';
import { PropertyRoutingModule } from './property-routing.module';
import { SelectConstrutoraComponent } from './components/select-construtora/select-construtora.component';
import { SelectEmpreendimentoComponent } from './components/select-empreendimento/select-empreendimento.component';
import { FormBuscaComponent } from './components/form-busca/form-busca.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
  dropSpecialCharacters: false,
};

@NgModule({
  declarations: [
    PropertyComponent,
    PropertyListComponent,
    SearchComponent,
    SearchSuggestComponent,
    SearchOrderComponent,
    SchedulingComponent,
    SharePropertyComponent,
    // busca
    FormBuscaComponent,
    SelectBairroComponent,
    SelectCidadeComponent,
    SelectTipoImovelComponent,
    SelectConstrutoraComponent,
    SelectEmpreendimentoComponent,
  ],
  exports: [
    PropertyListComponent,
    // busca
    FormBuscaComponent,
    SelectBairroComponent,
    SelectCidadeComponent,
    SelectTipoImovelComponent,
    SelectConstrutoraComponent,
    SelectEmpreendimentoComponent,
  ],
  imports: [
    CommonModule,
    PropertyRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    CorretorModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    MatAutocompleteModule,
    NgxHmCarouselModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxImageGalleryModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PropertyModule {
  static forRoot(): ModuleWithProviders<PropertyModule> {
    return {
      ngModule: PropertyModule,
    };
  }
}
