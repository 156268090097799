import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'randomize' })
export class RandomizePipe implements PipeTransform {
  constructor() {}

  transform(array: any[]): any[] {
    const shuffledArray = array.map((item, i) => ({ item, order: Math.random() }));
    shuffledArray.sort((a, b) => a.order - b.order);
    return shuffledArray.map(item => item.item);
  }
}
