import { Exclude, Expose, Transform } from 'class-transformer';

export class Incorporadora {
  id: string;
  nome: string;
  textoCurto: string;
  textoLongo: string;
  logoUrl: string;
  imagemFundoUrl: string;
  imagemBannerUrl: string;

  @Expose({ name: 'slugs' })
  @Transform(({ value }) => value?.split(','), { toClassOnly: true })
  @Transform(({ value }) => value?.join(','), { toPlainOnly: true })
  _slugs: string[];

  constructor(values?: any) {
    if (values) Object.assign(this, values);
  }

  public get firstSlug(): string {
    return this._slugs?.[0];
  }

  public set slugs(v: string) {
    this._slugs = v?.split(',');
  }

  public get slugs(): string {
    return this._slugs.join(',');
  }
}
