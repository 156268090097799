<mat-form-field class="w-100" floatLabel="never">
  <mat-select
    #selectBuilder
    name="construtoras"
    placeholder="CONSTRUTORAS"
    multiple
    class="i-text i-closable-select"
    [(ngModel)]="search.construtoras"
    role="combobox">
    <mat-select-trigger>
      CONSTRUTORAS
      <span *ngIf="search?.construtoras?.length > 0"> (+{{ search?.construtoras?.length }}) </span>
    </mat-select-trigger>
    <div class="d-flex justify-content-end">
      <button
        disableRipple
        class="pr-5 pr-sm-3 pt-2"
        (click)="selectBuilder.close()"
        style="background-color: transparent">
        <picture>
          <source type="image/webp" srcset="assets/img/btn_fechar_b.webp" />
          <img src="assets/img/btn_fechar_b.png" style="width: 17px; height: 16px" alt="Fechar" />
        </picture>
      </button>
    </div>
    <div class="py-3">
      <div class="input-group w-100">
        <input
          #filtroConstrutoras
          name="filtroConstrutoras"
          class="form-control i-input-filter pb-2"
          placeholder="FILTRAR CONSTRUTORAS..."
          autocomplete="off"
          (input)="onFilterBuilders(filtroConstrutoras.value)" />
        <div *ngIf="search?.construtoras?.length > 0" class="input-group-append">
          <button disableRipple class="i-btn" (click)="onClearBuilders()">
            <i class="iconify" data-icon="mdi-trash-can"></i>
          </button>
        </div>
      </div>
    </div>
    <mat-option *ngFor="let builder of filteredBuilders" [value]="builder.slug">
      {{ builder?.nome }}
    </mat-option>
  </mat-select>
</mat-form-field>
