import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ImovelLancamento } from '../models/imovel-lancamento';
import { Incorporadora } from '../models/incorporadora';
import { Search } from '../models/search';
import { BaseService } from '../util/base.service';

@Injectable()
export class IncorporadoraService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getBanner(): Observable<Incorporadora> {
    let params = new HttpParams().set('rnd', Math.random() * 99999);

    return this.http.get<any>('incorporadora/banner', { params }).pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(Incorporadora, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  getInfo(slug: string): Observable<Incorporadora> {
    return this.http.get<any>(`incorporadora/${slug}`).pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(Incorporadora, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  getEmpreendimentos(slug: string, search: Search): Observable<ImovelLancamento[]> {
    const params = search.toParams();

    return this.http.get<any>(`incorporadora/${slug}/empreendimento`, { params }).pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(ImovelLancamento, x))),
      catchError(this.handleError),
      shareReplay(1)
    );
  }
}
