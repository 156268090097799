<ng-container *ngIf="properties$ | async as properties">
  <div *ngIf="properties?.length > 1" class="container-fluid pb-5" style="min-height: 33rem">
    <div class="d-flex flex-row justify-content-center align-items-end">
      <div class="i-title row no-gutters i-results justify-content-center text-center pb-2">{{ titulo }}</div>
      <!--Etiqueta laranja
            <a [routerLink]="['/imoveis/status/venda/cidade', cidadeSlug]" [queryParams]="{etl: true}">
                <img *ngIf="etiquetaLaranja" src="assets/img/logo-etiqueta-laranja-texto.png" class="img-fluid"
                    title="Etiqueta Laranja" alt="Etiqueta Laranja" style="height: 3.5rem; margin-left: 0.2rem;" />
            </a>
            -->
    </div>
    <ng-container *ngIf="properties?.length > 4; else nonCarousel">
      <div class="i-drag-indicator text-center" title="Indicação de navegação">
        <i class="iconify" data-icon="mdi-arrow-left"></i>
        <i class="iconify mx-1" data-icon="mdi-hand-pointing-up"></i>
        <i class="iconify" data-icon="mdi-arrow-right"></i>
      </div>
      <ngx-hm-carousel
        *ngIf="ssr.isBrowser"
        #carousel
        [(ngModel)]="currentIndex"
        (ngModelChange)="onChangeIndex()"
        show-num="auto"
        [align]="'left'"
        [breakpoint]="breakpoint"
        [infinite]="true"
        [drag-many]="true"
        [aniTime]="200"
        [data]="properties"
        class="carousel"
        [autoplay]="autoplay"
        [autoplay-speed]="3000">
        <section ngx-hm-carousel-container class="content">
          <article
            class="item i-box cursor-pointer my-4"
            ngx-hm-carousel-item
            *ngFor="let property of properties; let i = index">
            <div class="img px-3">
              <app-box [property]="property"></app-box>
            </div>
          </article>
          <ng-template #infiniteContainer></ng-template>
        </section>
        <!-- only using in infinite mode or autoplay mode, that will render with-->
        <ng-template #carouselContent let-property let-i="index">
          <article class="item i-box cursor-pointer my-4">
            <div class="img px-3">
              <app-box [property]="property"></app-box>
            </div>
          </article>
        </ng-template>
      </ngx-hm-carousel>
    </ng-container>
    <ng-template #nonCarousel>
      <div class="row justify-content-center i-non-carousel">
        <article *ngFor="let property of properties" class="col col-lg-6 col-xl-3 item i-box cursor-pointer my-5">
          <div class="img px-3">
            <app-box [property]="property"></app-box>
          </div>
        </article>
      </div>
    </ng-template>
  </div>
</ng-container>
