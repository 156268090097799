<mat-form-field class="w-100" floatLabel="never">
  <mat-select
    name="cidade"
    placeholder="CIDADE"
    class="i-text"
    style="text-transform: uppercase"
    [(ngModel)]="search.cidade"
    (selectionChange)="onChangeCity()"
    role="combobox">
    <!-- desabilita a opção nula -->
    <ng-container *ngFor="let uf of ufs$ | async; index as idx">
      <mat-optgroup *ngIf="idx > 0" [label]="uf?.nomeCompleto"></mat-optgroup>
      <mat-option *ngFor="let city of uf?.cidades" [value]="city?.slug" [disabled]="!city" role="option">
        {{ city?.nome | uppercase }}
      </mat-option>
    </ng-container>
  </mat-select>
</mat-form-field>
