<div class="container-fluid" style="min-height: 6rem">
  <ngx-hm-carousel
    *ngIf="ssr.isBrowser"
    [align]="'left'"
    [(ngModel)]="currentIndex"
    [show-num]="'auto'"
    [breakpoint]="breakpoint"
    [infinite]="true"
    [drag-many]="true"
    [data]="awards"
    class="carousel"
    [between-delay]="100"
    [autoplay]="true"
    [autoplay-speed]="3000"
    [aniTime]="200">
    <section ngx-hm-carousel-container class="content d-flex">
      <article class="item cursor-pointer" ngx-hm-carousel-item *ngFor="let item of awards; let i = index">
        <img class="img-fluid" [src]="item.image" [alt]="item.name" [title]="item.name" />
      </article>
      <ng-template #infiniteContainer></ng-template>
    </section>
    <!-- only using in infinite mode or autoplay mode, that will render with-->
    <ng-template #carouselContent let-item let-i="index">
      <article class="item cursor-pointer">
        <img class="img-fluid" [src]="item.image" [alt]="item.name" [title]="item.name" />
      </article>
    </ng-template>
  </ngx-hm-carousel>
</div>
