import { ClassConstructor, plainToClass } from "class-transformer";

export class ResultPage<T> {
    items: T[];
    current: number = 0;
    size: number;
    total: number;

    constructor(values?: any, cls?: ClassConstructor<T>) {
        if (values) Object.assign(this, values);
        if (this.items && cls) this.items = this.items.map(x => plainToClass(cls, x));
    }
}
