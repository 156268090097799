import { AfterContentInit, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Search } from 'src/app/shared/models/search';
import { ContatoService } from 'src/app/shared/services/contato.service';
import { showFeedbackSuccess } from 'src/app/shared/util/alert-swal';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';
import { checkPhoneSintax } from 'src/globals';

declare let $: any; // jquery

@Component({
  selector: 'app-search-order',
  templateUrl: './search-order.component.html',
  styleUrls: ['./search-order.component.scss'],
})
export class SearchOrderComponent implements AfterContentInit {
  @Input() search?: Search;
  fmGroup: UntypedFormGroup;

  constructor(
    private ssr: SsrUtilService,
    private formBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private ga: AnalyticsService,
    private contactService: ContatoService
  ) {
    this.fmGroup = this.formBuilder.group({
      name: [null, Validators.required],
      phone: [
        null,
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(15),
        Validators.compose([checkPhoneSintax]),
      ],
      email: [null, Validators.required, Validators.email],
      message: [null],
    });
  }

  ngAfterContentInit(): void {
    // manda verificar atualizações
    this.cd.detectChanges();
  }

  onSend() {
    // Valida os campos do form
    if (this.fmGroup.invalid) return;

    // monta url da busca
    let params = new URLSearchParams();
    Object.entries(this.search?.toQuerySearch()).forEach(item => {
      if (item[1]) params.set(item[0], '' + item[1]);
    });
    let searchUrl = `https://privateimoveis.com/imoveis/status/${this.search?.statusEx}/cidade/${
      this.search?.cidade
    }?${params.toString()}`;

    // envia
    const { name, phone, email, message } = this.fmGroup.value;
    this.contactService.enviaEncomendeSeuImovel(name, phone, email, message, searchUrl).subscribe(
      _result => {
        this.fmGroup.reset(); // limpa os campos do form
        this.ga.trackEvent('order', 'form', 'website');
        showFeedbackSuccess('Sua solicitação foi enviada!', 'Em breve um de nossos consultores entrará em contato.');

        if (this.ssr?.isBrowser) $('#modal_encomende').modal('hide');
      },
      error => {
        SessionManager.handleHttpError(error, 'Falha ao enviar sua solicitação');
      }
    );
  }
}
