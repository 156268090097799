import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BolaDaVezComponent } from './components/page/bola-da-vez-page.component';

const routes: Routes = [{ path: 'imoveis/bola-da-vez', component: BolaDaVezComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BolaDaVezRoutingModule {}
