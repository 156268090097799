import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxHmCarouselBreakPointUp } from 'ngx-hm-carousel';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Imovel } from 'src/app/shared/models/imovel';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';

@Component({
  selector: 'app-property-list',
  templateUrl: './property-list.component.html',
  styleUrls: ['./property-list.component.scss'],
})
export class PropertyListComponent implements OnInit, OnChanges {
  @Input() titulo: string;
  @Input() cidadeSlug: string;
  @Input() status: string;
  @Input() code: string;
  @Input() empCode: string;
  @Input() autoplay: boolean = false;
  @Input() properties$: Observable<Imovel[]>;
  @Output() bindComplete = new EventEmitter<boolean>();
  scrollEvent = new Event('scroll');

  @Input() etiquetaLaranja: boolean = false;

  // configuração do ngx-hm-carousel
  currentIndex = 0;
  breakpoint: NgxHmCarouselBreakPointUp[] = [
    {
      width: 576,
      number: 1,
    },
    {
      width: 768,
      number: 2.15,
    },
    {
      width: 992,
      number: 2.5,
    },
    {
      width: 1024,
      number: 3.15,
    },
    {
      width: 1200,
      number: 3.5,
    },
    {
      width: 1366,
      number: 4.15,
    },
    {
      width: 1440,
      number: 4.5,
    },
    {
      width: 1680,
      number: 5.15,
    },
    {
      width: 1920,
      number: 5.5,
    },
    {
      width: 2900,
      number: 6.15,
    },
  ];

  constructor(
    readonly ssr: SsrUtilService,
    private propertyService: ImovelService,
    private sm: SessionManager
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }

  loadData() {
    try {
      if (this.properties$)
        // se a lista já existe, é carregada externamente
        return;
      if (this.cidadeSlug)
        this.loadHighlight(); // carrega imóveis em destaque
      else if (this.code)
        this.loadRecommendations(); // carrega outros imóveis
      else if (this.empCode) this.loadPropertiesFromBuilding(); // carrega imóveis do empreendimento
    } finally {
      // gera um evento para quem usa o componente saber se a lista está vazia
      this.properties$.subscribe(x => this.bindComplete.emit(x?.length > 0));
    }
  }

  /**
   * Carrega os imóveis em destaque de acordo com a cidade selecionada
   */
  loadHighlight() {
    var city = this.cidadeSlug;
    if (!this.sm.isRegionDefined()) city = undefined;
    this.properties$ = this.propertyService.getDestaques(city, this.status).pipe(
      catchError(error => {
        // SessionManager.handleHttpError(error, 'Falha ao consultar imóveis em destaque');
        console.error('Falha ao consultar imóveis em destaque', error);
        return of([] as Imovel[]);
      })
    );
  }

  /**
   * Carrega os imóveis em recomendação
   */
  loadRecommendations() {
    this.properties$ = this.propertyService.getRecomendacoes(this.code).pipe(
      catchError(error => {
        // SessionManager.handleHttpError(error, 'Falha ao consultar imóveis em recomendação');
        console.error('Falha ao consultar imóveis em recomendação', error);
        return of([] as Imovel[]);
      })
    );
  }

  /**
   * Carrega os imóveis do empreendimento
   */
  loadPropertiesFromBuilding() {
    this.properties$ = this.propertyService.getImoveisDoEmpreendimento(this.empCode).pipe(
      catchError(error => {
        // SessionManager.handleHttpError(error, 'Falha ao consultar imóveis do empreendimento');
        console.error('Falha ao consultar imóveis do empreendimento', error);
        return of([] as Imovel[]);
      })
    );
  }

  onChangeIndex() {
    // ng-lazyload-image
    if (this.ssr?.isBrowser) this.ssr?.getWindow().dispatchEvent(this.scrollEvent);
  }
}
