import { HttpErrorResponse } from '@angular/common/http';
import { isDevMode } from '@angular/core';
import { throwError } from 'rxjs';

export class BaseService {
  constructor() {}

  protected handleError(error: HttpErrorResponse) {
    try {
      if (error.error instanceof ErrorEvent) {
        if (isDevMode()) {
          console.error('ERRO:', error.error.message);
        }
      } else {
        if (isDevMode()) {
          console.error(`ERRO: ${error?.status} - ${error?.error?.mensagem}`);
        }

        if (error.status == 401) {
          // SessionManager.logout();
          // showFeedbackDanger('Atenção', 'Sessão inválida!');
          return throwError(error);
        }
      }
    } catch (error) {
      // nada
    }
    return throwError(error);
  }
}
