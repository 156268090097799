import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Pagina } from '../models/pagina';
import { Equipe } from '../models/team-member';
import { BaseService } from '../util/base.service';

@Injectable()
export class PaginaService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getPaginas(): Observable<Pagina[]> {
    return this.http.get<any>('pagina').pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Pagina, x))),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  getEquipe(): Observable<Equipe[]> {
    return this.http.get<any>('equipe').pipe(
      /* retryWithBackoff(), */
      map(result => result.map(x => plainToClass(Equipe, x))),
      catchError(this.handleError),
      shareReplay(1)
    );
  }
}
