import Swal from 'sweetalert2';

// ----------------------------------------------------------------
// Troca do sweetalert2
// ----------------------------------------------------------------

export const showFeedbackSuccess = (title: string, message?: string) => {
  return alert(title, message);
};

// export const showFeedbackWarning = (title: string = 'Atenção', message: string) => {
//     return alert(title, message);
// }

export const showFeedbackDanger = (title: string, message?: string) => {
  return alert(title, message);
};

export const alert = (title: string, message?: string, confirmText: string = 'OK') => {
  return Swal.fire({
    // type: null,
    title: title,
    text: message,
    confirmButtonText: confirmText,
  });
};
