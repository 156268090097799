import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { AwardsComponent } from './components/awards/awards.component';
import { IncorporadoraBannerComponent } from './components/banner-incorporadora/banner-incorporadora.component';
import { BoxCarouselComponent } from './components/box-carousel/box-carousel.component';
import { BoxComponent } from './components/box/box.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FilteringComponent } from './components/filtering/filtering.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MenuComponent } from './components/menu/menu.component';
import { PhonesComponent } from './components/phones/phones.component';
import { AuthService } from './services/auth.service';
import { BannerService } from './services/banner.service';
import { ClientService } from './services/client.service';
import { ContatoService } from './services/contato.service';
import { CorretorService } from './services/corretor.service';
import { ImovelService } from './services/imovel.service';
import { IncorporadoraService } from './services/incorporadora.service';
import { PaginaService } from './services/pagina.service';
import { SateliteService } from './services/satelite.service';
import { ArrayCsvPipe } from './util/array-csv-pipe';
import { EventHandler } from './util/handler.event';
import { HTMLPipe } from './util/html-pipe';
import { PluralPipe } from './util/plural-pipe';
import { PrepositionPipe } from './util/preposition-pipe';
import { RandomizePipe } from './util/randomize-pipe';
import { RealCurrencyPipe } from './util/real-currency-pipe';
import { SafePipe } from './util/safe-pipe';
import { SsrUtilService } from './util/ssr-util.service';

@NgModule({
  declarations: [
    SafePipe,
    RealCurrencyPipe,
    HTMLPipe,
    PluralPipe,
    PrepositionPipe,
    ArrayCsvPipe,
    RandomizePipe,
    MenuComponent,
    PhonesComponent,
    BoxComponent,
    BoxCarouselComponent,
    FilteringComponent,
    HeaderComponent,
    LoadingComponent,
    BreadcrumbComponent,
    AwardsComponent,
    IncorporadoraBannerComponent,
  ],
  exports: [
    SafePipe,
    RealCurrencyPipe,
    HTMLPipe,
    PluralPipe,
    PrepositionPipe,
    ArrayCsvPipe,
    MenuComponent,
    PhonesComponent,
    BoxComponent,
    FilteringComponent,
    HeaderComponent,
    LoadingComponent,
    BreadcrumbComponent,
    AwardsComponent,
    IncorporadoraBannerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxHmCarouselModule,
    LazyLoadImageModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AuthService,
        BannerService,
        CorretorService,
        ContatoService,
        PaginaService,
        ImovelService,
        IncorporadoraService,
        SateliteService,
        ClientService,
        SsrUtilService,
        EventHandler,
        RealCurrencyPipe,
        HTMLPipe,
        PluralPipe,
        RandomizePipe,
      ],
    };
  }
}
