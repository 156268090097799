<!--HEADER-->
<!--
    Sem isto aumenta a velocidade que a página abre, subiu 3.% no PageSpeed do Google.
    <div [@opacityAnimations]="prepareAnimation(outlet)">
-->
<div>
  <router-outlet #outlet="outlet" (activate)="onActivate($event)"></router-outlet>
</div>
<!--NEWSLETTER-->
<div class="i-newsletter pb-4 px-2">
  <div class="container">
    <div class="row justify-content-center align-items-center text-center py-5">
      <div class="col-12 col-lg-4 py-3 px-0 ml-lg-auto">
        <div class="i-title pt-3">NEWSLETTER</div>
        <br />
        <div class="i-text">
          CADASTRE-SE E RECEBA AS NOVIDADES<br />
          DO MERCADO IMOBILIÁRIO EM SEU E-MAIL
        </div>
      </div>
      <div class="col-9 col-lg-4 py-3 px-0 mr-lg-auto">
        <form (ngSubmit)="onSendNewsletter()" [formGroup]="fmGroup" class="pr-lg-5">
          <div class="form-group my-4">
            <input
              id="form-newsletter-name"
              type="text"
              class="form-control py-2 px-0"
              placeholder="NOME:"
              maxlength="255"
              name="name"
              formControlName="name"
              title="Nome" />
          </div>
          <div class="my-4">
            <div class="input-group-prepend">
              <input
                id="form-newsletter-email"
                type="email"
                class="form-control py-2 px-0"
                placeholder="E-MAIL:"
                maxlength="255"
                name="email"
                formControlName="email"
                title="E-mail" />
              <button id="form-newsletter-submit" type="submit" class="i-btn-ok">OK</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--FOOTER--->
<div class="i-footer container pt-4 pb-2">
  <div class="row pt-5 pb-1 text-center text-md-left">
    <div class="i-brand col-12 col-md-6 col-lg-4 mb-4 py-4 py-md-5">
      <img lazyLoad="assets/img/logo_cinza.svg" class="img-fluid" alt="Private" width="160px" height="22px" />
      <div class="i-address pt-4 px-1">
        Private Investimentos Imobiliários Ltda.<br />
        CNPJ 12.076.845/0001-40
      </div>
      <div class="i-address pt-3 px-1">
        Rua 24 de outubro, 1440, sala 1605<br />
        Porto Alegre, RS, CEP 90510-001<br />
        CRECI 22889-J
      </div>
      <div class="i-address pt-3 px-1">
        R. Dr. Fernandes Coelho, 85 - 9 andar<br />
        Pinheiros, São Paulo, SP, CEP 05423-040<br />
        <!-- Av. Cidade Jardim, 400, Edifício Dacon<br />
        São Paulo, SP, CEP 01454-901<br /> -->
        CRECI 39391-J
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-2 mb-4 mb-4 py-4 py-md-5">
      <div class="i-subtitle">SOBRE</div>
      <div class="i-about pt-3">
        <a href="#" routerLink="/institucional">Institucional</a><br />
        <ng-container *ngFor="let p of pages$ | async">
          <a [routerLink]="'/institucional/' + p.slug">{{ p.menu }}</a
          ><br />
        </ng-container>
        <a href="#" routerLink="/trabalhe-conosco">Trabalhe Conosco</a><br />
        <a href="#" href="https://blog.privateimoveis.com" target="_blank">Blog</a><br />
        <a href="#" routerLink="/contato">Contato</a><br />
        <a href="/politica-de-privacidade">Privacidade</a><br />
        <a href="/sitemap">Mapa do site</a><br />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-2 mb-4 mb-4 py-4 py-md-5">
      <div class="i-subtitle">EXPLORE</div>
      <div class="i-explore pt-3">
        <a href="#" routerLink="/imoveis/status/alugar">Alugar</a><br />
        <a href="#" routerLink="/imoveis/status/venda">Comprar</a><br />
        <a href="#" routerLink="/imoveis/agro">Agronegócio</a><br />
        <a href="#" routerLink="/imoveis/corporate">Corporate</a><br />
        <a href="#" routerLink="/imoveis/lancamentos">Lançamentos</a><br />
        <a href="#" routerLink="/imoveis/exclusividades">Exclusividades</a><br />
        <a href="#" routerLink="/imoveis/bola-da-vez">Bola da vez</a><br />
        <a href="#" routerLink="/contato/venda">Venda seu imóvel</a><br />
        <a href="#" routerLink="/contato/alugue">Alugue seu imóvel</a><br />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-2 mb-4 mb-4 py-4 py-md-5">
      <div class="i-subtitle">CIDADES</div>
      <div class="i-cities pt-3">
        <a href="#" routerLink="/imoveis/status/venda/cidade/porto-alegre">Porto Alegre</a><br />
        <a href="#" routerLink="/imoveis/status/venda/cidade/sao-paulo">São Paulo</a><br />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-2 mb-4 mb-4 py-4 py-md-5">
      <div class="i-subtitle">SEGUIR</div>
      <div class="i-media pt-3">
        <a href="https://www.instagram.com/privateimobiliaria/" target="_blank" rel="noopener"> Instagram </a><br />
        <a href="https://www.instagram.com/privateimobiliaria_sp/" target="_blank" rel="noopener"> Instagram SP </a
        ><br />
        <a href="https://www.instagram.com/privateimoveisagro/" target="_blank" rel="noopener"> Instagram +AGRO </a
        ><br />
        <!-- <a href="https://www.facebook.com/privateimoveis" target="_blank" rel="noopener"> Facebook </a><br /> -->
      </div>
    </div>
  </div>
  <app-phones></app-phones>
  <div class="i-bottom row pt-5">
    <div class="i-rights col-12 col-md-6 my-auto py-2 text-center text-md-left">
      <!-- Construído em 2019 -->
      &copy; Private 2024 &nbsp; |
      <a
        class="i-developer pb-2"
        href="http://www.isul.com.br/?utm_source=private&utm_medium=website&utm_campaign=web"
        target="_blank"
        rel="noopener"
        ><img
          lazyLoad="assets/img/logo_isul.svg"
          class="img-fluid pl-1 pb-1"
          alt="ISul Tecnologia"
          width="40px"
          height="13px"
      /></a>
    </div>
  </div>
</div>
