<mat-form-field class="w-100" floatLabel="never">
  <mat-select
    #selectType
    name="categoria"
    placeholder="TIPO"
    multiple
    class="i-text"
    [(ngModel)]="search.categorias"
    (selectionChange)="changeSearch?.emit(search)"
    role="combobox">
    <mat-select-trigger>
      TIPO
      <span *ngIf="search?.categorias?.length > 0"> (+{{ search?.categorias?.length }}) </span>
    </mat-select-trigger>
    <div class="d-flex justify-content-end">
      <button
        disableRipple
        class="close pr-5 pr-sm-3 pt-2"
        (click)="selectType.close()"
        style="background-color: transparent"
        role="button">
        <picture>
          <source type="image/webp" srcset="assets/img/btn_fechar_b.webp" />
          <img src="assets/img/btn_fechar_b.png" width="17px" height="16px" alt="Fechar" />
        </picture>
      </button>
    </div>
    <mat-checkbox disableRipple (change)="onItemsChecked($event?.checked, 'residencial')"> Residencial </mat-checkbox>
    <mat-option
      *ngFor="let residential of categories?.residencial"
      [value]="residential.slug"
      role="option"
      (onSelectionChange)="onItemChecked($event, residential?.slug)">
      {{ residential?.nome | uppercase }}
    </mat-option>
    <mat-checkbox disableRipple (change)="onItemsChecked($event?.checked, 'comercial')"> Comercial </mat-checkbox>
    <mat-option *ngFor="let commercial of categories?.comercial" [value]="commercial.slug" role="option">
      {{ commercial?.nome | uppercase }}
    </mat-option>
    <div class="d-flex justify-content-center">
      <button type="button" class="i-btn mr-2 my-2" (click)="selectType.close()" role="button">OK</button>
    </div>
  </mat-select>
</mat-form-field>
