<!--HOME-TOP-->
<div
  class="i-home-top"
  [style]="
    isMobile
      ? 'background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));'
      : 'background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' +
        (this.banner$ | async)?.arquivoUrl +
        ');'
  ">
  <div class="container-fluid">
    <!--Menu-->
    <div class="i-menu row align-items-center pt-3 pb-2 px-0 px-lg-5">
      <div class="navbar-brand col-8 col-sm-8 col-md-6 col-lg-3 col-xl-2 mr-auto p-2 text-center text-sm-left">
        <a class="px-3" href="#" routerLink="/home" aria-label="Private">
          <img src="assets/img/logo_branco.svg" class="img-fluid my-auto" alt="Private" width="208px" height="29px" />
        </a>
      </div>
      <div class="col-4 col-sm-4 col-md-6 col-lg-9 col-xl-10 ml-auto py-2 pr-4">
        <app-menu [isHome]="true"></app-menu>
      </div>
    </div>
    <!--Call-Text-->
    <div class="i-main-call py-3">
      <div class="text-center px-1 px-lg-2 py-3">MOVER AS PESSOAS<br />PARA O SEU MELHOR LUGAR</div>
    </div>
    <!--City choice-->
    <div *ngIf="!isRegionDefined()" class="row justify-content-center flex-column text-center pt-2 pb-5">
      <div class="i-title text-white pb-3">VOCÊ ESTÁ EM</div>
      <div class="d-flex justify-content-center flex-column flex-md-row pt-3 pb-5">
        <a href="home/cidade/porto-alegre" class="i-btn-light i-btn m-3" rel="nofollow"> PORTO ALEGRE / RS </a>
        <a href="home/cidade/sao-paulo" class="i-btn-light i-btn m-3" rel="nofollow"> SÃO PAULO / SP </a>
      </div>
    </div>
    <!--Search-Bar-->
    <form *ngIf="isRegionDefined()" #f="ngForm">
      <div class="i-search-bar container">
        <div class="i-home-search row justify-content-center align-items-center text-center">
          <!-- cidades -->
          <div class="col-12 col-md-6 col-lg-2 mb-4">
            <app-select-cidade [search]="search" (changeSearch)="onChangeCityUrl()"></app-select-cidade>
          </div>
          <!-- status -->
          <mat-form-field class="col-12 col-md-6 col-lg-2 mb-4" floatLabel="never">
            <mat-select
              name="status"
              class="i-text"
              value="search.status"
              [(ngModel)]="search.status"
              (selectionChange)="onChangeSearchStatus()"
              role="combobox">
              <mat-option value="0" role="option">COMPRAR</mat-option>
              <mat-option value="1" role="option">ALUGAR</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- tipo -->
          <div class="col-12 col-md-6 col-lg-2 mb-4">
            <app-select-tipo-imovel [(search)]="search"> </app-select-tipo-imovel>
          </div>
          <!-- bairros -->
          <div class="col-12 col-md-6 col-lg-2 mb-4">
            <app-select-bairro [(search)]="search"></app-select-bairro>
          </div>
          <!-- valor -->
          <mat-form-field class="col-12 col-md-6 col-lg-2 mb-4" floatLabel="never">
            <mat-label>VALOR</mat-label>
            <mat-select
              name="rangePrice"
              class="i-text"
              role="combobox"
              [ngModel]="selectedPriceRange"
              (ngModelChange)="onChangePriceRange($event)"
              (selectionChange)="onChangeSearchStatus()">
              <ng-container *ngIf="search">
                <mat-option
                  *ngFor="let option of search.priceRanges.slice().reverse(); let firstPosition = first"
                  [value]="option">
                  <ng-container *ngIf="!firstPosition">
                    {{ option[0] | number }} até {{ option[1] | number }}
                  </ng-container>
                  <ng-container *ngIf="firstPosition"> acima de {{ option[0] | number }} </ng-container>
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
          <!-- codigo -->
          <mat-form-field class="col-12 col-md-6 col-lg-2 mb-4">
            <mat-label>CÓDIGO DO IMÓVEL</mat-label>
            <input
              name="codigo"
              matInput
              class="pb-2"
              maxlength="10"
              autocomplete="off"
              [(ngModel)]="search.codigo"
              onkeydown="formatNumber(event, this);"
              (keyup.enter)="goToSearch()" />
          </mat-form-field>
        </div>
        <!-- Filtrando por -->
        <app-filtering [search]="search" class="row justify-content-center text-center py-1" theme="light">
        </app-filtering>
        <div class="row justify-content-center flex-column text-center py-2">
          <div class="py-4">
            <button class="i-btn-light i-btn-inverted btn btn-link" (click)="goToSearch()" rel="nofollow">
              <!-- BUSCA AVANÇADA -->
              BUSCAR
            </button>
          </div>
        </div>
      </div>
    </form>
    <!-- banner tira - melnick day
        <a href="https://api.whatsapp.com/send?phone=5551993016838&text=Desejo+saber+mais+sobre+o+maior+evento+imobili%C3%A1rio%21" target="_blank">
            <div class="row"
                style="background: url('assets/img/banner-melnick-day.jpg') center center no-repeat #021827;">
                <div class="col-12 d-flex flex-row justify-content-center align-items-center" style="height: 70px;">
                    <div>
                        <img src="assets/img/logo-melnick-day.png" title="Melnick Day na Private" style="height: 3rem;">
                    </div>
                    <div class="ml-3 text-white text-center" style="font-size: 0.8rem;">
                        <small style="font-size: 60%;">IMÓVEIS COM ATÉ 40% DE DESCONTO</small><br />
                        A PRIVATE TE LEVA PARA O<br />
                        MAIOR EVENTO IMOBILIÁRIO
                    </div>
                </div>
            </div>
        </a>
        -->
    <!-- etiqueta laranja
            <a href="#" target="_blank">
            <div class="row"
                style="background-color: #d88705;">
                <div class="col-12 d-flex flex-row justify-content-center align-items-center" style="height: 70px;">
                    <div>
                        <img src="assets/img/logo-etiqueta-laranja.png" title="Etiqueta Laranja" style="height: 3rem;">
                    </div>
                    <div class="ml-3 text-white text-center" style="font-size: 0.8rem; font-family: 'Times New Roman', Times, serif;">
                        IMÓVEIS & DESCONTOS INCRÍVEIS<br />
                        <small style="font-size: 80%;">CONFIRA AQUI</small>
                    </div>
                </div>
            </div>
        </a> -->
    <!-- info banner -->
    <ng-container *ngIf="this.banner$ | async as b">
      <div *ngIf="b?.hasInfo" class="d-flex justify-content-end pb-3">
        <a
          data-toggle="popover"
          data-container="body"
          data-placement="left"
          data-html="true"
          title="Sobre esta imagem"
          [attr.data-content]="b.infoTitle"
          target="_blank">
          <i class="iconify text-white" data-icon="mdi-information-outline"></i>
        </a>
      </div>
    </ng-container>
  </div>
</div>
<!--HOME-CONTENT-->
<div class="i-home-content">
  <!--Etiqueta Laranja
    <app-oranges-properties [search]="search"></app-oranges-properties>
    -->
  <!--Prêmios-->
  <div class="p-3 pt-5">
    <app-awards></app-awards>
  </div>
  <!--Spotlights-->
  <div class="i-spotlights mt-4 pt-4">
    <app-property-list
      titulo="IMÓVEIS EM DESTAQUE"
      [cidadeSlug]="search.cidade"
      [status]="search.status"
      [autoplay]="true">
    </app-property-list>
  </div>
  <!--New-Releases-->
  <app-lancamento-empreendimento [cidadeSlug]="search?.cidade"></app-lancamento-empreendimento>
  <!--Neighborhood-->
  <div
    *ngIf="isRegionDefined() && (bairrosDestaque?.length > 0 || bairros?.length > 0)"
    class="i-neighborhood container py-4">
    <div class="i-title row justify-content-center text-center pt-5">
      IMÓVEIS POR BAIRRO
      <ng-container *ngIf="search?.cidadeNome"> EM {{ search.cidadeNome | uppercase }} </ng-container>
    </div>
    <div class="row justify-content-center align-items-center py-5">
      <div
        *ngFor="let bairro of bairrosDestaque?.slice(0, 4)"
        class="i-thumb"
        defaultImage="assets/img/img_loading.webp"
        [lazyLoad]="bairro?.imagemUrl">
        <a
          class="i-text d-flex align-items-center justify-content-center text-center"
          href="#"
          [routerLink]="['/imoveis/status', search?.status === '0' ? 'venda' : 'alugar', 'cidade', search?.cidade]"
          [queryParams]="{ b: bairro.slug }">
          Apartamentos e Casas<br />
          em {{ bairro?.nome | uppercase }}
        </a>
      </div>
    </div>
    <div class="row justify-content-center pt-4 pb-5">
      <div class="i-text col-12 col-lg-9 text-center px-2 px-lg-5 px-0">
        <ng-container *ngFor="let bairro of bairros?.slice(4); let last = last">
          <a
            class="i-text px-1"
            href="#"
            [routerLink]="['/imoveis/status', search?.status === '0' ? 'venda' : 'alugar', 'cidade', search?.cidade]"
            [queryParams]="{ b: bairro?.slug }">
            Apartamentos e Casas em {{ bairro?.nome | uppercase }}
          </a>
          {{ last ? '' : '/' }}
        </ng-container>
      </div>
    </div>
  </div>
</div>
