<!--PAGE-TOP-->
<div class="i-page-top">
  <div class="container">
    <!-- Menu -->
    <div class="i-menu row align-items-center pt-3 pb-2">
      <div class="navbar-brand col-6 col-sm-8 col-md-6 col-lg-4 mr-auto py-2 px-2 text-center text-sm-left">
        <a href="#" routerLink="/home"><img [src]="logo" class="img-fluid my-auto" alt="Private" /></a>
      </div>
      <div class="col-6 col-sm-4 col-md-6 col-lg-8 ml-auto py-2">
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</div>
