import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BoxType } from 'src/app/shared/components/box/box.component';
import { Banner } from 'src/app/shared/models/banner';
import { Corretor } from 'src/app/shared/models/corretor';
import { Imovel } from 'src/app/shared/models/imovel';
import { ResultPage } from 'src/app/shared/models/search-result';
import { Search } from 'src/app/shared/models/search';
import { CorretorService } from 'src/app/shared/services/corretor.service';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';
import { PAGE_TITLE, formatPhone } from 'src/globals';

declare let $: any; // jquery

@Component({
  selector: 'app-corretor-properties',
  templateUrl: './corretor-properties.component.html',
  styleUrls: ['./corretor-properties.component.scss'],
})
export class CorretorPropertiesComponent implements OnInit {
  banner$: Observable<Banner>;
  // paginação
  loading: boolean;
  search = new Search();
  resultPage = new ResultPage<Imovel>();
  //
  formatPhone = formatPhone;
  BoxType = BoxType;
  boxType = BoxType.Grid;
  //
  broker: Corretor;

  constructor(
    readonly ssr: SsrUtilService,
    private titleService: Title,
    private ga: AnalyticsService,
    private router: Router,
    private route: ActivatedRoute,
    private corretorService: CorretorService,
    private imovelService: ImovelService,
    private sm: SessionManager
  ) {}

  ngOnInit(): void {
    //
    this.titleService.setTitle(`Imóveis - ${PAGE_TITLE}`);

    // Carrega do sessionData os parâmetros da busca
    this.search = this.sm.getSearch();

    // banner
    // this.banner$ = this.searchService.getBannerLancamento(undefined)
    //     .pipe(
    //         catchError(error => {
    //             return of(null);
    //         }),
    //         shareReplay(1),
    //     );

    // verifica qual corretor e grava na seção também
    this.route.params.subscribe(params => {
      const { slug } = params as { slug: string };
      if ((slug?.length || 0) <= 0) this.router.navigateByUrl('/home');
      this.search.corretor = slug;
      this.sm.setCorretorSlug(slug);

      //
      this.corretorService.getCorretor(slug).subscribe(
        result => {
          this.broker = result;

          this.titleService.setTitle(`${result.nome} - Imóveis - ${PAGE_TITLE}`);
        },
        error => {
          SessionManager.handleHttpError(error, 'Falha ao consultar imóveis. Tente novamente');
        }
      );
    });

    // scroll para o início da foto
    if (this.ssr?.isBrowser)
      setTimeout(() => {
        this.refreshTooltip();
      }, 500);

    // Observa as alterações nos params
    this.route.queryParams.subscribe(params => {
      this.checkParamsRoute(params);
    });
  }

  refreshTooltip() {
    // tooltip
    if (this.ssr?.isBrowser) {
      // tem que ser jquery
      $('[role="tooltip"]').remove();
      $('[data-toggle="tooltip"]').tooltip('dispose').tooltip();
    }
  }

  checkParamsRoute(params: Params) {
    const { pag } = params;
    if (pag) this.search.pag = pag;

    this.searchProperty();
  }

  async onChangePage(pag: number) {
    this.search.pag = pag;
    this.onChangeRoute();

    console.log('PAGE', pag);
  }

  onChangeRoute() {
    // monta params
    const params = this.search?.toQuerySearch();

    // muda url
    this.router.navigate(['/corretor', this.broker?.slug, 'imoveis'], {
      queryParams: params,
    });
  }

  searchProperty() {
    this.loading = true;
    // força corretor
    this.search.corretor = this.sm.getCorretorSlug();
    // ignora status
    this.search.status = undefined;
    // ignora ciadde
    this.search.cidade = undefined;
    // ignora ordem
    this.search.ordem = undefined;
    // busca
    this.imovelService.search(this.search).subscribe(
      result => {
        this.resultPage = result;
        this.loading = false;
      },
      error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar imóveis. Tente novamente');
      }
    );
  }

  onGaEvent(action: string, category: string, label: string, value: number = undefined) {
    this.ga.trackEvent(action, category, label, value);
  }
}
