import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Banner } from '../models/banner';
import { BaseService } from '../util/base.service';

@Injectable()
export class BannerService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Retorna o banner da home
   */
  getBanner(cidadeSlug: string): Observable<Banner> {
    return this.http.get<any>(`banner/${cidadeSlug}`).pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(Banner, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  getLancamento(cidadeSlug: string): Observable<Banner> {
    return this.http.get<any>(`banner/lancamento/${cidadeSlug}`).pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(Banner, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  getExclusivo(cidadeSlug: string): Observable<Banner> {
    return this.http.get<any>(`banner/exclusivo/${cidadeSlug}`).pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(Banner, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }

  getBannerAgro(cidadeSlug: string): Observable<Banner> {
    return this.http.get<any>(`banner/agro/${cidadeSlug}`).pipe(
      /* retryWithBackoff(), */
      map(result => plainToClass(Banner, result)),
      catchError(this.handleError),
      shareReplay(1)
    );
  }
}
