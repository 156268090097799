import { Component, Input } from '@angular/core';
import { StatusEnum } from '../../models/imovel';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  StatusEnum = StatusEnum;
  @Input() status?: StatusEnum = StatusEnum.Sell;
  @Input() cidade: string = 'Porto Alegre';
  @Input() cidadeSlug: string = 'porto-alegre';
  @Input() bairro: string;
  @Input() bairroSlug: string;

  constructor() {}
}
