import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SsrUtilService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  public get isServer(): boolean {
    return isPlatformServer(this.platformId);
  }

  public get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public getWindow(): Window | null {
    return this.doc.defaultView;
  }

  public getLocation(): Location {
    return this.doc.location;
  }

  public createElement(tag: string): HTMLElement {
    return this.doc.createElement(tag);
  }

  public isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
