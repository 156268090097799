import { Component, Input, OnInit } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Pagina } from 'src/app/shared/models/pagina';
import { PaginaService } from 'src/app/shared/services/pagina.service';
import { EventHandler } from 'src/app/shared/util/handler.event';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { Search } from '../../models/search';
import { AnalyticsService } from '../../util/analytics.service';
import { SsrUtilService } from '../../util/ssr-util.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  search: Search;
  @Input() isHome: boolean = false;
  menuOpened: Boolean = false;
  countFavorites$: Observable<number>;
  countDiscards$: Observable<number>;
  pages$: Observable<Pagina[]>;
  isClientLogged: boolean = false;
  clientName: string;

  constructor(
    private ssr: SsrUtilService,
    private ga: AnalyticsService,
    private pageService: PaginaService,
    private sm: SessionManager
  ) {}

  ngOnInit(): void {
    // Carrega e aguarda algum evento para atualizar o total de favoritos e descartados
    this.countFavorites$ = merge(EventHandler.builder().getFavoriteEventEmitter(), of(null)).pipe(
      switchMap(() => of(this.sm.totalFavorites()))
    );
    this.countDiscards$ = merge(EventHandler.builder().getDiscardEventEmitter(), of(null)).pipe(
      switchMap(() => {
        if (this.sm.hasToken()) return of(this.sm.totalDiscards());
        else return of(undefined);
      })
    );

    // Carrega páginas para o sub-menu institucional
    this.pages$ = this.pageService.getPaginas().pipe(
      catchError(error => {
        // console.error('Erro ao consultar páginas', error);
        return of([] as Pagina[]);
      })
    );

    // captura o evento de busca para condicionar o layout à cidade
    EventHandler.builder()
      .getSearchEventEmitter()
      .subscribe(result => {
        this.search = result;
      });

    // captura o evento de login/logout
    this.isClientLogged = this.sm.hasToken();
    this.clientName = this.sm.getSessionData()?.clientFirstName;
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  onWhatsapp() {
    let phone = this.sm.getWhatsappNumber(this.search);
    //
    this.ssr?.getWindow().open(`https://api.whatsapp.com/send?phone=${phone}`, '_blank');
    this.ga.trackEvent('menu click', 'whatsapp', phone);
  }

  onPhone() {
    let phone = this.sm.getPhoneNumber(this.search);
    //
    this.ssr?.getWindow().open(`tel://${phone}`, '_self');
    this.ga.trackEvent('menu click', 'phone', phone);
  }

  isRegionDefined() {
    return this.sm.isRegionDefined();
  }
}
