<div *ngIf="rsPhone" class="i-phones d-flex flex-column flex-md-row justify-content-around">
  <div class="text-center text-lg-left pt-3 pb-5 px-2">
    <div class="d-lg-flex d-lg-inline">
      <div class="mb-2 mb-lg-0">
        <picture>
          <source type="image/webp" lazyLoad="assets/img/ico_telefone.webp" />
          <img lazyLoad="assets/img/ico_telefone.png" style="width: 45px; height: 45px" alt="Telefone" />
        </picture>
      </div>
      <div class="i-subtitle pl-3">
        TELEFONE<br /><a href="tel:+555132762040" (click)="onGaEvent('click', 'phone', 'geral-poa')"
          >+ 55 51 3276 2040</a
        >
      </div>
    </div>
  </div>
  <div class="text-center text-lg-left pt-3 pb-5 px-2">
    <div class="d-lg-flex d-lg-inline">
      <div class="mb-2 mb-lg-0">
        <picture>
          <source type="image/webp" lazyLoad="assets/img/ico_whatsapp.webp" />
          <img lazyLoad="assets/img/ico_whatsapp.png" style="width: 45px; height: 47px" alt="Whatsapp" />
        </picture>
      </div>
      <div class="i-subtitle pl-3">
        WHATSAPP VENDAS<br /><a
          href="https://api.whatsapp.com/send?phone=5551996360961&text="
          target="_blank"
          rel="noopener"
          (click)="onGaEvent('click', 'whatsapp', 'vendas-poa')">
          +55 51 996 360 961</a
        >
      </div>
    </div>
  </div>
  <div class="text-center text-lg-left pt-3 pb-5 px-2">
    <div class="d-lg-flex d-lg-inline">
      <div class="mb-2 mb-lg-0">
        <picture>
          <source type="image/webp" lazyLoad="assets/img/ico_whatsapp.webp" />
          <img
            class="img-fluid"
            lazyLoad="assets/img/ico_whatsapp.png"
            style="width: 45px; height: 47px"
            alt="Whatsapp" />
        </picture>
      </div>
      <div class="i-subtitle pl-3">
        WHATSAPP LOCAÇÕES<br />
        <a
          href="https://api.whatsapp.com/send?phone=5551991106172&text="
          target="_blank"
          rel="noopener"
          (click)="onGaEvent('click', 'whatsapp', 'locacoes-poa')">
          + 55 51 991 106 172
        </a>
      </div>
    </div>
  </div>
</div>
<div *ngIf="spPhone" class="i-phones d-flex flex-column flex-md-row justify-content-around">
  <div class="text-center text-lg-left pt-3 pb-5 px-2">
    <div class="d-lg-flex d-lg-inline">
      <div class="mb-2 mb-lg-0">
        <picture>
          <source type="image/webp" lazyLoad="assets/img/ico_whatsapp.webp" />
          <img
            class="img-fluid"
            lazyLoad="assets/img/ico_whatsapp.png"
            style="width: 45px; height: 47px"
            alt="Whatsapp" />
        </picture>
      </div>
      <div class="i-subtitle pl-3">
        WHATSAPP VENDAS<br /><a
          href="https://api.whatsapp.com/send?phone=5511941240199&text="
          target="_blank"
          rel="noopener"
          (click)="onGaEvent('click', 'whatsapp', 'vendas-sp')">
          <!-- (click)="onGaEvent('click', 'whatsapp', 'geralspØ-94491')" -->
          + 55 11 94124 0199</a
        >
      </div>
    </div>
  </div>
</div>
