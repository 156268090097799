import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { BoxType } from 'src/app/shared/components/box/box.component';
import { Banner } from 'src/app/shared/models/banner';
import { Imovel } from 'src/app/shared/models/imovel';
import { ResultPage } from 'src/app/shared/models/search-result';
import { Search } from 'src/app/shared/models/search';
import { BannerService } from 'src/app/shared/services/banner.service';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { PAGE_TITLE } from 'src/globals';

@Component({
  selector: 'app-bola-da-vez-page',
  templateUrl: './bola-da-vez-page.component.html',
  styleUrls: ['./bola-da-vez-page.component.scss'],
})
export class BolaDaVezComponent implements OnInit {
  banner$: Observable<Banner>;
  BoxType = BoxType;
  search: Search;
  resultPage = new ResultPage<Imovel>();
  cidadeNome: string;

  constructor(
    private titleService: Title,
    private bannerService: BannerService,
    private imagemService: ImovelService,
    private router: Router,
    private route: ActivatedRoute,
    private sm: SessionManager
  ) {
    //
    this.search = new Search();
    this.search.cidade = undefined;
    this.search.bolaVez = ['true'];

    //
    this.route.queryParams.subscribe(params => {
      const { pag } = params;
      this.search.pag = pag;
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(`Imóveis Bola da vez - ${PAGE_TITLE}`);

    this.loadData();
  }

  loadData() {
    // banner
    this.banner$ = this.bannerService.getBanner(undefined).pipe(
      catchError(error => {
        console.log(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar banner.'));
        return of(null);
      }),
      shareReplay(1)
    );

    // imóveis
    // console.log('SEARCH', this.search);
    this.imagemService.search(this.search).subscribe(
      result => {
        this.resultPage = result;
        this.titleService.setTitle(`Imóveis Bola da vez - ${PAGE_TITLE}`);

        //
        this.sm.clearSearch();
      },
      error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar os imóveis bola da vez');
        return of({} as ResultPage<Imovel>);
      }
    );
  }

  async onChangePage(pag: number) {
    this.search.pag = pag;

    this.router.navigate([`/imoveis/bola-da-vez`], {
      queryParams: { pag: this.search.pag },
    });
  }
}
