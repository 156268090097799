<div class="i-page-top">
  <div class="container">
    <!--Menu-->
    <div class="i-menu row align-items-center pt-3 pb-2">
      <div class="navbar-brand col-6 col-sm-8 col-md-6 col-lg-4 mr-auto py-2 px-0 text-center text-sm-left">
        <a href="#" routerLink="/home" aria-label="Private"
          ><img src="assets/img/logo_cinza.svg" class="img-fluid my-auto" alt="Private"
        /></a>
      </div>
      <div class="col-6 col-sm-4 col-md-6 col-lg-8 ml-auto py-2">
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</div>
<div class="i-page-content">
  <!-- mais acessados -->
  <ng-container *ngIf="hottestVisible">
    <div class="i-main-call mb-4">
      <div class="container px-0">
        <div class="col-12 text-left py-4">EM ALTA</div>
      </div>
    </div>
    <div class="py-4">
      <app-property-list
        titulo="IMÓVEIS MAIS ACESSADOS"
        [cidadeSlug]="cidadeSlug"
        [autoplay]="true"
        [properties$]="hottest$"
        (bindComplete)="hottestVisible = $event">
      </app-property-list>
    </div>
  </ng-container>
  <!-- destaques -->
  <ng-container *ngIf="highlightVisible">
    <div class="i-main-call mb-4">
      <div class="container px-0">
        <div class="col-12 text-left py-4">DESTAQUES</div>
      </div>
    </div>
    <div class="py-4">
      <app-property-list
        titulo="IMÓVEIS SELECIONADOS"
        [cidadeSlug]="cidadeSlug"
        [autoplay]="true"
        (bindComplete)="highlightVisible = $event">
      </app-property-list>
    </div>
  </ng-container>
  <!-- lançamentos -->
  <ng-container *ngIf="releaseVisible">
    <div class="i-main-call mb-4">
      <div class="container px-0">
        <div class="col-12 text-left py-4">LANÇAMENTOS</div>
      </div>
    </div>
    <div class="py-4">
      <app-property-list
        titulo="OS MELHORES LANÇAMENTOS"
        [cidadeSlug]="cidadeSlug"
        [autoplay]="true"
        [properties$]="releases$"
        (bindComplete)="releaseVisible = $event">
      </app-property-list>
    </div>
  </ng-container>
  <!-- recentes -->
  <ng-container *ngIf="recentVisible">
    <div class="i-main-call mb-4">
      <div class="container px-0">
        <div class="col-12 text-left py-4">RECENTES</div>
      </div>
    </div>
    <div class="py-4">
      <app-property-list
        titulo="MAIS RECENTES DO CATÁLOGO"
        [cidadeSlug]="cidadeSlug"
        [autoplay]="true"
        [properties$]="recents$"
        (bindComplete)="recentVisible = $event">
      </app-property-list>
    </div>
  </ng-container>
</div>
