<div class="modal fade" id="modal_agende" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <form class="i-form pl-2" (ngSubmit)="onSend()" [formGroup]="fmGroup">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="i-title text-center mt-2 w-100">{{ contactTitle }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <picture>
              <source type="image/webp" srcset="assets/img/btn_fechar_b.webp" />
              <img src="assets/img/btn_fechar_b.png" style="width: 17px; height: 16px" alt="Fechar" />
            </picture>
          </button>
        </div>
        <div class="modal-body i-contact py-3">
          <div *ngIf="showScheduleForm" class="i-schedule col-12 mx-auto pb-3">
            <div class="i-text pb-2">
              DIA <small class="text-muted">{{ scheduleDate }}</small>
            </div>
            <div class="i-datetime pb-2">
              <button
                [id]="formId('date')"
                *ngFor="let sd of scheduleDays; let idx = index"
                type="button"
                class="i-btn py-2 px-3 mr-1 mb-1"
                [class.active]="sd.selected"
                (click)="onScheduleSelected(idx, sd.date, null)"
                [innerHtml]="sd.day">
                <!-- {{sd.day}} -->
              </button>
            </div>
            <div class="i-text py-2">
              HORÁRIO <small class="text-muted">{{ scheduleTime }}h</small>
            </div>
            <div class="i-datetime pb-2">
              <button
                [id]="formId('hour')"
                *ngFor="let st of scheduleTimes; let idx = index"
                type="button"
                class="i-btn py-2 px-1 mr-1 mb-1"
                [class.active]="st.selected"
                (click)="onScheduleSelected(idx, null, st.time)">
                {{ st.time }}h
              </button>
            </div>
          </div>
          <mat-form-field class="col-12" appearance="fill">
            <mat-label class="i-text">NOME</mat-label>
            <input
              [id]="formId('name')"
              matInput
              type="text"
              class="i-text pb-1"
              maxlength="255"
              formControlName="name"
              name="name" />
            <mat-error class="i-text-sm" *ngIf="fmGroup.get('name').errors?.required"> OBRIGATÓRIO * </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12" appearance="fill">
            <mat-label class="i-text">E-MAIL</mat-label>
            <input
              [id]="formId('email')"
              matInput
              type="email"
              class="i-text pb-2"
              maxlength="255"
              formControlName="email"
              name="email" />
            <mat-error
              class="i-text-sm"
              *ngIf="fmGroup.get('email').errors?.email && !fmGroup.get('email').errors?.required">
              POR FAVOR, INFORME UM E-MAIL VÁLIDO
            </mat-error>
            <mat-error class="i-text-sm" *ngIf="fmGroup.get('email').errors?.required"> OBRIGATÓRIO * </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 mb-2" appearance="fill">
            <mat-label class="i-text">TELEFONE</mat-label>
            <input
              [id]="formId('phone')"
              matInput
              type="text"
              class="i-text-sm pb-2"
              placeholder="(00) 00000-0000"
              mask="(00) 0000-0000||(00) 00000-0000"
              maxlength="15"
              formControlName="phone"
              name="phone" />
            <mat-hint>(DDD) 00000-0000</mat-hint>
            <mat-error
              class="i-text-sm"
              *ngIf="fmGroup.get('phone').errors?.minlength && !fmGroup.get('phone').errors?.required">
              POR FAVOR, INFORME NO MÍNIMO 14 DÍGITOS
            </mat-error>
            <mat-error class="i-text-sm mt-1" *ngIf="fmGroup.get('phone').errors?.phoneSintaxError">
              POR FAVOR, VERIFIQUE O NÚMERO DIGITADO
            </mat-error>
            <mat-error class="i-text-sm" *ngIf="fmGroup.get('phone').errors?.required"> OBRIGATÓRIO * </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12" appearance="fill">
            <mat-label class="i-text">MENSAGEM</mat-label>
            <textarea
              [id]="formId('message')"
              matInput
              type="text"
              class="i-text pb-2"
              #input
              maxlength="500"
              rows="4"
              formControlName="message"></textarea>
            <mat-hint align="end">{{ input.value?.length || 0 }}/500</mat-hint>
            <mat-error class="i-text-sm" *ngIf="fmGroup.get('message').errors?.required"> OBRIGATÓRIO * </mat-error>
          </mat-form-field>
        </div>
        <div class="modal-footer justify-content-center bg-white">
          <button [id]="formId('submit')" type="submit" class="i-btn" [disabled]="sendingContact">
            {{ !sendingContact ? 'ENVIAR' : 'ENVIANDO...' }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
