import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FilteringCallback } from 'src/app/shared/components/filtering/filtering.component';
import { Search } from 'src/app/shared/models/search';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { EventHandler } from 'src/app/shared/util/handler.event';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';
import { stripSpecialChars, transformCurrency } from 'src/globals';

declare let $: any; // jquery

@Component({
  selector: 'app-form-busca',
  templateUrl: './form-busca.component.html',
  styleUrls: ['./form-busca.component.scss'],
})
export class FormBuscaComponent implements OnInit {
  transformCurrency = transformCurrency;
  stripSpecialChars = stripSpecialChars;
  @Input() search: Search;
  @Input() enableCount: boolean = false;
  @Input() enableSticky: boolean = false;
  @Input() enableAdvanced: boolean = true;
  @Input() showFiltering: boolean = true;
  @Output() changeSearch = new EventEmitter<Search>(true);
  opened: boolean = false;
  propertyCount: number = 0;
  // faixa de valores
  rangesArea: number[] = [50, 100, 150, 200, 250, 300, 500];
  rangesPrice: number[] = [];
  filteredAreaMin: number[] = [];
  filteredAreaMax: number[] = [];
  filteredPriceMin: number[] = [];
  filteredPriceMax: number[] = [];
  runSearchOnQueryParam = false;

  constructor(
    private ssr: SsrUtilService,
    private sm: SessionManager,
    private propertyService: ImovelService
  ) {}

  ngOnInit(): void {
    // @diegodsp: Mudanças de queryString e rota, em cada local que usa o componente
    this.updateRangePrices();
    this.onChangePropertyCount();
  }

  // Show the sticked Search Bar
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (!this.enableSticky) return;
    if (!this.ssr?.isBrowser) return;
    if (window.pageYOffset > 550) {
      let element = document.getElementById('stickBar');
      element.classList.add('i-stick-bar', 'i-search-bar');
      element.classList.remove('d-none');
    } else {
      let element = document.getElementById('stickBar');
      element.classList.remove('i-stick-bar', 'i-search-bar');
      element.classList.add('d-none');
    }
  }

  // formata o valor em moeda e atualiza no campo input
  formatCurrencyValue(event: any) {
    let value = event.target.value.trim();
    if (value?.length > 0) event.target.value = transformCurrency(value).toString();
  }

  updateRangePrices() {
    let ranges: number[] = [...this.search.prices];
    this.rangesPrice = this.filteredPriceMin = this.filteredPriceMax = ranges;
  }

  /**
   * Retorna area min que for menor/igual que a area max
   * Filtra as faixas da area min, baseado na area max selecionada
   */
  onFilterAreaMin() {
    this.filteredAreaMin = this.rangesArea.filter(x => x <= Number(this.search?.areaMax || Number.MAX_VALUE));
  }

  /**
   * Retorna area max que for maior/igual que a area min
   * Filtra as faixas da area max, baseado na area min selecionada
   */
  onFilterAreaMax() {
    this.filteredAreaMax = this.rangesArea.filter(x => x >= Number(this.search?.areaMin || 0));
  }

  /**
   * Retorna valor min que for menor/igual que o valor max
   * Filtra as faixas do valor min, baseado no valor max selecionado
   */
  onFilterPriceMin() {
    this.filteredPriceMin = this.rangesPrice.filter(x => x <= Number(this.search?.valorMax || Number.MAX_VALUE));
  }

  /**
   * Retorna valor max que for maior/igual que o valor min
   * Filtra as faixas do valor max, baseado no valor min selecionado
   */
  onFilterPriceMax() {
    this.filteredPriceMax = this.rangesPrice.filter(x => x >= Number(this.search?.valorMin || 0));
  }

  validateRangesValues() {
    this.onFilterAreaMin();
    this.onFilterAreaMax();
    this.onFilterPriceMin();
    this.onFilterPriceMax();

    // Converte os valores para number, removendo os pontos do valor Ex
    const amEx = this.search?.areaMax;
    const vmEx = this.search?.valorMax;
    // Valida se a opção selecionada foi a última. Se sim, retorna o valor Ex, senão, valor nulo.
    this.search.areaMax =
      amEx && this.rangesArea?.indexOf(amEx) < this.rangesArea?.length - 1 ? this.search?.areaMax : null;
    this.search.valorMax =
      vmEx && this.rangesPrice?.indexOf(vmEx) < this.rangesPrice?.length - 1 ? this.search?.valorMax : null;
  }

  /**
   * Quando altera qualquer propriedade do searchbar, atualiza o total de imóveis possíveis para esta consulta
   */
  onChangePropertyCount() {
    this.validateRangesValues();

    if (this.enableCount)
      this.propertyService.searchCount(this.search).subscribe(result => {
        this.propertyCount = result;
      });
  }

  /**
   * Limpa filtros em utilização.
   *
   * @param callback Qual filtro está sendo limpo e o obj da busca.
   */
  onClearFilter(callback: FilteringCallback) {
    console.log('CLEAR', callback);
    this.search = callback.search;
    // remove tooltip
    if (this.ssr?.isBrowser) $('[role="tooltip"]').remove();
    // Consulta ver resultados
    this.onSearch();
  }

  /**
   * Quando o status da busca é alterado
   */
  onChangeStatus() {
    // atualiza a faixa de valores
    this.updateRangePrices();
    // Consulta ver resultados
    this.onChangePropertyCount();
    // avisa que o modo de busca mudou
    EventHandler.builder().searchChanged(this.search);
  }

  /**
   * Quando a busca é solicitada (botão clicado, etc.).
   */
  onSearch() {
    this.search.pag = 1;
    this.sm.saveCookie(this.search);
    this.changeSearch?.emit(this.search);
  }
}
