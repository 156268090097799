import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Imovel } from 'src/app/shared/models/imovel';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { SeoService } from 'src/app/shared/util/seo.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { PAGE_TITLE } from 'src/globals';

@Component({
  selector: 'app-bombando',
  templateUrl: './bombando.component.html',
  styleUrls: ['./bombando.component.scss'],
})
export class BombandoComponent implements OnInit {
  cidadeSlug: string;
  //
  hottest$: Observable<Imovel[]>;
  releases$: Observable<Imovel[]>;
  recents$: Observable<Imovel[]>;
  //
  hottestVisible: boolean = true;
  highlightVisible: boolean = true;
  releaseVisible: boolean = true;
  recentVisible: boolean = true;

  constructor(
    private titleService: Title,
    private seo: SeoService,
    private sm: SessionManager,
    private propertyService: ImovelService
  ) {}

  ngOnInit(): void {
    const title = `Em alta - ${PAGE_TITLE}`;
    this.titleService.setTitle(title);
    this.cidadeSlug = this.sm.getSessionData().search?.cidade || 'porto-alegre';

    // seo
    this.seo
      .reset()
      .addTitle(title)
      .addDescription(
        'Confira os imóveis que estão alta e que nós separamos especialmente para você. | Private Imóveis '
      );

    // mais acessados
    this.hottest$ = this.propertyService.getMaisAcessados(this.cidadeSlug).pipe(
      catchError(error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar os imóveis mais acessados');
        return of([] as Imovel[]);
      })
    );

    // destaques
    // não precisa pois a lista só com a cidade é automática

    // lançamentos
    this.releases$ = this.propertyService.getLancamentos(this.cidadeSlug).pipe(
      catchError(error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar os lançamentos');
        return of([] as Imovel[]);
      })
    );

    // recentes
    this.recents$ = this.propertyService.getRecentes(this.cidadeSlug).pipe(
      catchError(error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar os lançamentos');
        return of([] as Imovel[]);
      })
    );
  }
}
