<!-- Filtrando por -->
<div *ngIf="forceShow || search?.hasFilter" [class]="class">
  <div class="d-inline i-text {{ theme }} mt-1 mr-2">{{ title }}</div>
  <span *ngIf="search?.cidadeNome" class="badge badge-{{ theme }} mr-1 mb-2">
    {{ search?.cidadeNome | uppercase }}
  </span>
  <span class="badge badge-{{ theme }} mr-1 mb-2">
    {{ search?.status === '0' ? 'COMPRAR' : 'ALUGAR' }}
  </span>
  <span *ngIf="search?.categorias?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(0)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    TIPO (+{{ search?.categorias?.length }})
  </span>
  <span *ngIf="search?.bairros?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(1)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    BAIRROS (+{{ search?.bairros?.length }})
  </span>
  <span *ngIf="search?.construtoras?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(14)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    CONSTRUTORAS (+{{ search?.construtoras?.length }})
  </span>
  <span *ngIf="search?.empreendimentos?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(17)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    EMPREENDIMENTOS (+{{ search?.empreendimentos?.length }})
  </span>
  <span *ngIf="search?.codigo" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(2)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    CÓD. {{ search?.codigo }}
  </span>
  <span *ngIf="search?.dormitorios?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(3)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    DORM. (+{{ search?.dormitorios?.length }})
  </span>
  <span *ngIf="search?.suites?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(4)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    SUÍTES (+{{ search?.suites?.length }})
  </span>
  <span *ngIf="search?.vagas?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(5)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    VAGAS (+{{ search?.vagas?.length }})
  </span>
  <span *ngIf="search?.areaMin || search?.areaMax" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(6)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    ÁREA
    {{ search?.areaMin ? search?.areaMin + ' MIN' : '' }}
    {{ search?.areaMin && search?.areaMax ? ' - ' : '' }}
    {{ search?.areaMax ? search?.areaMax + ' MAX' : '' }}
  </span>
  <span *ngIf="search?.valorMin || search?.valorMax" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(7)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    VALOR
    {{ search?.valorMin ? 'R$ ' + transformCurrency(search?.valorMin) + ' MIN' : '' }}
    {{ search?.valorMin && search?.valorMax ? ' - ' : '' }}
    {{ search?.valorMax ? 'R$ ' + transformCurrency(search?.valorMax) + ' MAX' : '' }}
  </span>
  <span *ngIf="search?.desconto?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(8)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    COM DESCONTO
  </span>
  <span *ngIf="search?.permuta?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(9)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    ACEITA IMÓVEL
  </span>
  <!-- <span *ngIf="search?.exclusivo?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(12)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    EXCLUSIVIDADE
  </span> -->
  <span *ngIf="search?.destaque?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(13)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    DESTAQUE
  </span>
  <span *ngIf="search?.bolaVez?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(20)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    BOLA DA VEZ
  </span>
  <span *ngIf="search?.construcao?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(10)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    <!-- EM CONSTRUÇÃO -->
    LANÇAMENTO / CONSTRUÇÃO
  </span>
  <span *ngIf="search?.novo?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(11)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    NOVO
  </span>
  <span *ngIf="search?.usado?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(15)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    USADO
  </span>
  <span *ngIf="search?.mobiliado?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(16)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    MOBILIADO
  </span>
  <span *ngIf="search?.reforma?.length > 0" class="badge badge-{{ theme }} mr-1 mb-2">
    <a
      href="javascript:void(0)"
      (click)="onClearFilter(19)"
      data-toggle="tooltip"
      data-placement="bottom"
      title="REMOVER FILTRO"
      aria-label="REMOVER FILTRO">
      <i class="iconify" data-icon="mdi-close-circle-outline"></i>
    </a>
    PARA REFORMA
  </span>
  <button
    *ngIf="showClear && search?.hasFilter"
    type="button"
    class="i-btn-clear-filter i-btn-inverted {{ theme }} px-2 py-1"
    (click)="onClearFilter(-1)"
    aria-label="LIMPAR FILTROS">
    LIMPAR FILTROS
  </button>
</div>
