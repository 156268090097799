import { Pipe, PipeTransform } from '@angular/core';

/**
 * Ex.: 'favorito' | plural:countFavorites
 */
@Pipe({ name: 'plural' })
export class PluralPipe implements PipeTransform {
  transform(word: string, count: number = 0): string {
    return count > 1 ? this.plural(word) : word;
  }

  private regras = {
    /**
     * Palavras que terminam em a|e|i|o|u|ã|ãe|ão
     * apenas acrescenta a letra 's' no final
     * @type {Object}
     */
    acrescentar: {
      s: ['a', 'e', 'i', 'o', 'u', 'ã', 'ãe'],
      es: ['r', 'z', 'n', 'ás'],
      '': ['is', 'us', 'os'],
    },
    /**
     * Palavras que terminam em al|el|ol|ul|il|m
     * substitui a terminação
     * @type {Object}
     */
    substituir: {
      ais: 'al',
      eis: 'el',
      ois: 'ol',
      uis: 'ul',
      is: 'il',
      ns: 'm',
      eses: 'ês',
      ões: 'ão',
    },
    /**
     * Plural das sete exceções
     * @type {Object}
     */
    excecoes: {
      males: 'mal',
      cônsules: 'cônsul',
      méis: 'mel',
      féis: 'fel',
      cais: 'cal',
    },
    /**
     * Palavras que não tem plural
     * @type {Object}
     */
    sem_plural: ['sim', 'não'],
  };

  private plural(palavras: string): string {
    var palavrasPlural: string[] = palavras.split(' ');
    palavrasPlural.forEach((palavra, i) => {
      if (palavra.trim().length > 0) palavrasPlural[i] = this.compute(palavra);
    });
    return palavrasPlural.join(' ');
  }

  private compute(palavra: string): string {
    var regex_troca = '^([a-zA-Zà-úÀ-Ú]*)(%s)$';
    var plural = '';
    for (var regra in this.regras) {
      switch (regra) {
        case 'acrescentar':
          for (var adicao in this.regras[regra]) {
            var busca = regex_troca.replace('%s', this.regras[regra][adicao].join('|'));
            var regex = new RegExp(busca, 'i');
            if (regex.exec(palavra) !== null) {
              plural = palavra + adicao;
              break;
            }
          }
          break;
        case 'substituir':
          for (var substituicao in this.regras[regra]) {
            var busca = regex_troca.replace('%s', this.regras[regra][substituicao]);
            var regex = new RegExp(busca, 'i');
            if (regex.exec(palavra) !== null) {
              /**
               * Se a palavra for paroxítona ou proparoxítona,
               * troca-se 'il' por 'eis'
               */
              if (palavra.match(/([áéíóú])/) !== null && regex.exec(palavra)[2] == 'il') {
                plural = palavra.replace('il', 'eis');
                break;
              } else {
                var busca_sub = new RegExp(regex.exec(palavra)[2] + '$', 'i');
                plural = palavra.replace(busca_sub, substituicao);
                break;
              }
            }
          }
          break;
        case 'excecoes':
          for (var excecao in this.regras[regra]) {
            if (palavra == this.regras[regra][excecao]) {
              plural = excecao;
              break;
            }
          }
          break;
        case 'sem_plural':
          this.regras[regra].forEach(function (r) {
            if (palavra === r) plural = palavra;
          });
          break;
      }
    }
    return plural !== '' ? plural : palavra;
  }
}
