import { Location } from '@angular/common';
import { AfterContentInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { StateChange } from 'ng-lazyload-image';
import { NgxHmCarouselBreakPointUp } from 'ngx-hm-carousel';
import { GALLERY_IMAGE, NgxImageGalleryComponent } from 'ngx-image-gallery';
import { Observable } from 'rxjs';
import { BairroWalkScore } from 'src/app/shared/models/bairro-walkscore';
import { Corretor } from 'src/app/shared/models/corretor';
import { Imovel, StatusEnum } from 'src/app/shared/models/imovel';
import { ImovelFoto } from 'src/app/shared/models/imovel-foto';
import { ClientService } from 'src/app/shared/services/client.service';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { EventHandler } from 'src/app/shared/util/handler.event';
import { MetaSchemaOrganizationService } from 'src/app/shared/util/organization.service';
import { SeoService } from 'src/app/shared/util/seo.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';
import { PAGE_TITLE, formatPhone, iScrollTo } from 'src/globals';
import Swal from 'sweetalert2';
import { SchedulingType } from '../scheduling/scheduling.component';

declare let $: any; // jquery
declare let window: any; // window

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
})
export class PropertyComponent implements OnInit, AfterContentInit {
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;
  formatPhone = formatPhone;
  StatusEnum = StatusEnum;
  property: Imovel = new Imovel();
  corretor: Corretor = new Corretor();
  isFavorite: boolean;
  isDiscarded: boolean;
  showCompleteInfo: boolean;
  showValues: boolean = true;
  showFeature: boolean = true;
  showInfra: boolean = true;
  showLocal: boolean = true;
  shareText: string;
  whatsappInfoText: string;
  //
  walkscore$: Observable<BairroWalkScore>;
  //
  SchedulingType = SchedulingType;
  schedulingType: SchedulingType;
  // showProposalBtn: boolean = true;
  //
  scrollEvent = new Event('scroll');
  medias: any[] = [new ImovelFoto(), new ImovelFoto()];

  // configuração do ngx-hm-carousel
  currentIndex = 0;
  currentIndexThumbs = 0;
  breakpoint: NgxHmCarouselBreakPointUp[] = [
    {
      width: 500,
      number: 1,
    },
    {
      width: 800,
      number: 1,
    },
    {
      width: 1024,
      number: 1,
    },
    {
      width: 1140,
      number: 1.2,
    },
    {
      width: 2920,
      number: 1.22,
    },
  ];

  constructor(
    readonly ssr: SsrUtilService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private titleService: Title,
    private location: Location,
    private propertyService: ImovelService,
    private clientService: ClientService,
    private org: MetaSchemaOrganizationService,
    private seo: SeoService,
    private ga: AnalyticsService,
    private sm: SessionManager
  ) {
    this.titleService.setTitle(`Apartamentos e casas - ${PAGE_TITLE}`);

    // garante que a cidade está na busca
    this.route.params.subscribe(params => {
      const { cidade } = params;
      if (cidade) this.sm.regionDefined(cidade);
    });
  }

  ngOnInit(): void {
    // Observa as alterações nos parâmetros da rota
    this.route.params.subscribe(params => {
      const { code } = params;
      this.loadProperty(code);
    });

    // remove video do modal
    // tem que ser jquery
    if (this.ssr?.isBrowser)
      $(function () {
        $('#modalVideo').on('hidden.bs.modal', function (e) {
          document.querySelector('#modalVideo iframe').setAttribute('src', '');
        });
      });
  }

  ngAfterContentInit(): void {
    // manda verificar atualizações
    this.cd.detectChanges();

    this.refreshTooltip();
  }

  refreshTooltip() {
    // tooltip
    if (this.ssr?.isBrowser) {
      // tem que ser jquery
      $('[role="tooltip"]').remove();
      $('[data-toggle="tooltip"]').tooltip('dispose').tooltip();
    }
  }

  onChangeIndex() {
    // muda o carrossel dos thumbs junto
    this.currentIndexThumbs = this.currentIndex;
    // ng-lazyload-image
    if (this.ssr?.isBrowser)
      setTimeout(() => {
        this.ssr?.getWindow().dispatchEvent(this.scrollEvent);

        // muda a foto do zoom, se estiver sendo visualizada
        if ($('#modalImage').hasClass('show')) {
          let m = this.medias[this.currentIndex];
          if (m) this.onZoom(m.fotoUrl || m.fotoPequenaUrl);
        }
      }, 500);
    // Evento do Google Analytics
    this.ga.trackEvent('photo', 'carousel', this.property?.codigo, this.currentIndex);
  }

  onThumbSelected(idx: number) {
    // muda o carrossel principal com o click no thumb
    this.currentIndex = idx;
    // ng-lazyload-image
    if (this.ssr?.isBrowser)
      setTimeout(() => {
        this.ssr?.getWindow().dispatchEvent(this.scrollEvent);
      }, 500);
    // Evento do Google Analytics
    this.ga.trackEvent('photo', 'carousel-thumb', this.property?.codigo, idx);
  }

  loadProperty(code: string) {
    // detalhe do imóvel
    this.propertyService.getImovel(code).subscribe(
      result => {
        this.property = result;
        this.corretor = this.property.getCorretor(this.sm);
        EventHandler.instance.propertyViewChanged(result);

        // ajusta as medias
        this.medias = [];
        if (this.property?.fotos) this.medias.push(...this.property?.fotos);

        // ajusta imagens da galeria
        this.ngxImageGallery.images = this.property?.fotos?.map(
          x =>
            <GALLERY_IMAGE>{
              url: x.fotoUrl,
            }
        );

        // Define o titulo da página com a cidade
        // nova regra... MestreSEO
        this.titleService.setTitle(
          this.property?.titulo?.length > 65 ? this.property?.titulo : `${this.property?.titulo} - ${PAGE_TITLE}`
        );

        // Define o texto que será compartilhado
        let url = this.ssr?.getLocation().href;
        this.shareText = encodeURI(`${this.property?.titulo} - ${PAGE_TITLE}\n[${url}]`);

        // Adiciona mensagem padrão
        let infoText = `Olá, gostaria de saber mais informações do imóvel código ${this.property?.codigo}. Obrigado!`;
        // Faz o encoding para usar na url do whatsapp do corretor
        this.whatsappInfoText = encodeURI(infoText);

        // verifica se é favorito
        this.isFavorite = this.sm.isFavorite(this.property?.codigo);
        this.isDiscarded = this.sm.isDiscarded(this.property?.codigo);

        // nova descrição definida pela MestreSEO
        /*
TIPO DE IMÓVEL + TIPO DE NEGOCIAÇÃO + BAIRRO + CARACTERÍSTICA + TEXTO PADRÃO + CTA;
Toda essa formação precisa ter no máximo 165 caracteres.
Exemplo: APARTAMENTO PARA ALUGAR MORUMBI COM 2 SUÍTES. NA PRIVATE IMÓVEIS VOCÊ ENCONTRA IMÓVEIS DE ALTO PADRÃO COM ATENDIMENTO PREMIUM. CLIQUE E CONFIRA!
                    */
        let descricaoSeo = `${this.property.categoria} para ${
          this.property.statusEx == StatusEnum.Sell ? 'comprar' : 'alugar'
        } ${this.property.bairro}`;
        if (this.property.suites > 0) descricaoSeo += ` com ${this.property.suites} suítes`;
        descricaoSeo +=
          '. Na Private Imóveis você encontra imóveis de alto padrão com atendimento premium. Clique e confira!';

        // Adiciona/Atualiza as Tags ao head da pagina
        this.seo
          .addTitle(`${this.property?.titulo} - ${PAGE_TITLE} `)
          // .addDescription(this.property?.descricaoWeb)
          .addDescription(descricaoSeo)
          .addImage(this.property?.fotoDestaqueUrl);

        // Adiciona tag article
        this.org
          .addArticle({
            headline: this.property?.titulo,
            image: this.property?.fotos?.map(x => x.fotoUrl).slice(0, 6),
          })
          .render();

        // manda verificar atualizações
        this.cd.detectChanges();

        //
        // this.showProposalBtn = this.property?.statusEx == StatusEnum.Sell;

        // scroll para o início da foto
        if (this.ssr?.isBrowser)
          setTimeout(() => {
            iScrollTo(0, 120);
            this.refreshTooltip();
          }, 500);

        // gerando dados pra equipe de tracking
        if (this.ssr?.isBrowser)
          $(function () {
            if (result)
              window.privatePropertyData = {
                code: result.codigo,
                type: result.categoria ?? 'N/D',
                status: result.getStatus() ?? 'N/D',
                neighbourhood: result.bairro ?? 'N/D',
                city: result.cidade ?? 'N/D',
                region: result.uf ?? 'N/D',
                country: result.pais ?? 'BR',
                value: result.valor ?? 0,
                broker: result.getCorretor(this.sm)?.nome ?? 'N/D',
                suites: result.suites ?? 0,
                bedrooms: result.quartosEx ?? 0,
                area: result.areaPrivativa ?? 0,
              };
          });

        // walkscore
        this.walkscore$ = this.propertyService.getWalkscore(this.property.cidadeSlug, this.property.bairroSlug);
      },
      error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar o imóvel');
      }
    );
  }

  /**
   * Evento de alteração do favorito
   */
  onChangeFavorite() {
    this.isFavorite = this.sm.toggleFavorite(this.property?.codigo);
    this.clientService.saveFavorite(this.isFavorite, this.property?.codigo);
    // Evento do Google Analytics
    this.ga.trackEvent('favorite', this.isFavorite ? 'favorite' : 'unfavorite', this.property?.codigo);

    this.refreshTooltip();
  }

  /**
   * Evento de alteração do descartado
   */
  onChangeDiscard() {
    if (!this.sm.hasToken()) {
      this.refreshTooltip();
      Swal.fire({
        title: '',
        text: 'Para usar este recurso, faça seu login ou cadastre-se.',
        showCancelButton: true,
        confirmButtonText: 'Fazer login',
        cancelButtonText: 'Continuar navegando',
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        if (result.isConfirmed)
          this.router.navigate(['/login'], { queryParams: { redirectUrl: this.ssr?.getLocation().href } });
      });
    } else {
      this.isDiscarded = this.sm.toggleDiscarded(this.property?.codigo);
      this.clientService.saveDiscard(this.isDiscarded, this.property?.codigo);
      // Evento do Google Analytics
      this.ga.trackEvent('click', this.isDiscarded ? 'discard' : 'undiscard', this.property?.codigo);

      this.refreshTooltip();
    }
  }

  goBack() {
    this.location.back();
  }

  getImageUrl(i: number, p: ImovelFoto): string {
    if (!p) return;
    else return p.fotoUrl;
  }

  onArrowKeys(dir: string) {
    if (dir == 'left') this.currentIndex--;
    else this.currentIndex++;
  }

  onGaEvent(action: string, category: string, label: string, value: number = undefined) {
    this.ga.trackEvent(action, category, label, value);
  }

  // https://www.npmjs.com/package/ng-lazyload-image#onstatechange-optional
  mapLoadCallback(event: StateChange) {
    if (event.reason == 'loading-failed') this.property.mapaUrl = undefined;
  }

  onZoom(fotoIdx: number = 0) {
    if (!this.ssr?.isMobileDevice()) this.ngxImageGallery?.open(fotoIdx);
  }

  onVideoPlay(idx: number) {
    if (idx < this.property?.videos?.length || 0) {
      document.querySelector('#modalVideo iframe').setAttribute('src', this.property?.videos[idx].videoUrl);
      if (this.ssr?.isBrowser) $('#modalVideo').modal('show'); // tem que ser jquery
    }
  }

  onTour360() {
    if (!this.property?.passeio360) return;

    document.querySelector('#modalVideo iframe').setAttribute('src', this.property?.passeio360);
    if (this.ssr?.isBrowser) $('#modalVideo').modal('show'); // tem que ser jquery
  }

  onCompare() {
    if (!this.sm.hasToken()) {
      Swal.fire({
        title: '',
        text: 'Para usar este recurso, faça seu login ou cadastre-se.',
        showCancelButton: true,
        confirmButtonText: 'Fazer login',
        cancelButtonText: 'Continuar navegando',
        allowOutsideClick: () => !Swal.isLoading(),
      }).then(result => {
        if (result.isConfirmed)
          this.router.navigate(['/login'], {
            queryParams: { redirectUrl: `/ imoveis / comparar / ${this.property?.codigo} ` },
          });
      });
    } else {
      this.clientService.addToCompare(this.property?.codigo).subscribe(
        () => {
          Swal.fire({
            title: 'Imóvel adicionado',
            text: 'Este imóvel foi adicionando à sua lista de comparção. O que desejas fazer?',
            showCancelButton: true,
            confirmButtonText: 'Ver comparação',
            cancelButtonText: 'Continuar navegando',
            allowOutsideClick: () => !Swal.isLoading(),
          }).then(result => {
            if (result.isConfirmed) this.router.navigateByUrl('/imoveis/comparar');
          });
        },
        error => {
          SessionManager.handleHttpError(error, 'Não foi possível adicionar o imóvel à sua lista de comparção');
        }
      );
    }
  }
}
