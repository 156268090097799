<!--PAGE-TOP-->
<app-header></app-header>
<!--/PAGE-TOP-->
<!--Property-Detail-->
<div *ngIf="medias?.length || 0 > 0" class="i-detail container-fluid pb-5 px-0">
  <div class="carousel-container">
    <ngx-hm-carousel
      *ngIf="ssr.isBrowser"
      [(ngModel)]="currentIndex"
      (ngModelChange)="onChangeIndex()"
      show-num="auto"
      [breakpoint]="breakpoint"
      [infinite]="true"
      [drag-many]="true"
      [aniTime]="200"
      [data]="medias"
      class="carousel">
      <section
        ngx-hm-carousel-container
        class="content d-flex"
        tabindex="0"
        (keyup.arrowleft)="onArrowKeys('left')"
        (keyup.arrowright)="onArrowKeys('right')">
        <article
          class="item cursor-pointer"
          ngx-hm-carousel-item
          *ngFor="let photo of medias; let i = index"
          [class.img-center]="currentIndex === i">
          <div
            class="img d-flex justify-content-center align-items-center"
            defaultImage="assets/img/img_loading.webp"
            [lazyLoad]="photo?.fotoUrl || photo?.fotoPequenaUrl"
            (click)="onZoom(i)"
            style="cursor: pointer">
            <i *ngIf="!ssr.isMobileDevice()" class="iconify i-zoom" data-icon="mdi:magnify-plus-outline"></i>
          </div>
        </article>
        <ng-template #infiniteContainer></ng-template>
      </section>
      <ng-template #carouselContent let-photo let-i="index">
        <article class="item cursor-pointer">
          <div
            class="img d-flex justify-content-center align-items-center"
            defaultImage="assets/img/img_loading.webp"
            [lazyLoad]="photo?.fotoUrl || photo?.fotoPequenaUrl"></div>
        </article>
      </ng-template>
      <ng-template #carouselPrev>
        <div class="click-area">
          <img
            *ngIf="medias?.length > 1"
            class="material-icons"
            src="assets/img/btn_nav_esquerda.png"
            width="17px"
            height="70px"
            alt="Nav.esquerda" />
        </div>
      </ng-template>
      <ng-template #carouselNext>
        <div class="click-area">
          <img
            *ngIf="medias?.length > 1"
            class="material-icons"
            src="assets/img/btn_nav_direita.png"
            width="17px"
            height="70px"
            alt="Nav.direita" />
        </div>
      </ng-template>
    </ngx-hm-carousel>
  </div>
  <div class="carousel-container-thumbs">
    <ngx-hm-carousel
      *ngIf="ssr.isBrowser"
      [(ngModel)]="currentIndexThumbs"
      show-num="auto"
      [infinite]="true"
      [drag-many]="true"
      [data]="medias"
      class="carousel">
      <section
        ngx-hm-carousel-container
        class="content d-flex"
        tabindex="0"
        (keyup.arrowleft)="onArrowKeys('left')"
        (keyup.arrowright)="onArrowKeys('right')">
        <article
          class="item cursor-pointer"
          ngx-hm-carousel-item
          (click)="onThumbSelected(i)"
          *ngFor="let photo of medias; let i = index"
          [class.img-center]="currentIndexThumbs === i">
          <div
            class="img d-flex justify-content-center align-items-center"
            defaultImage="assets/img/img_loading.webp"
            [lazyLoad]="photo?.fotoPequenaUrl"></div>
        </article>
        <ng-template #infiniteContainer></ng-template>
      </section>
      <ng-template #carouselContent let-photo let-i="index">
        <article class="item cursor-pointer" (click)="onThumbSelected(i)">
          <div
            class="img d-flex justify-content-center align-items-center"
            defaultImage="assets/img/img_loading.webp"
            [lazyLoad]="photo?.fotoPequenaUrl"></div>
        </article>
      </ng-template>
      <ng-template #carouselPrev>
        <div class="click-area">
          <img
            *ngIf="medias?.length > 1"
            class="material-icons"
            src="assets/img/btn_nav_esquerda.png"
            width="17px"
            height="70px"
            alt="Nav.esquerda" />
        </div>
      </ng-template>
      <ng-template #carouselNext>
        <div class="click-area">
          <img
            *ngIf="medias?.length > 1"
            class="material-icons"
            src="assets/img/btn_nav_direita.png"
            width="17px"
            height="70px"
            alt="Nav.direita" />
        </div>
      </ng-template>
    </ngx-hm-carousel>
  </div>
  <div class="container py-3">
    <app-breadcrumb
      [status]="property?.statusEx"
      [cidade]="property?.cidade"
      [cidadeSlug]="property?.cidadeSlug"
      [bairro]="property?.bairro"
      [bairroSlug]="property?.bairroSlug">
    </app-breadcrumb>
    <div class="row">
      <div class="i-text col-12 d-flex flex-row pt-2">
        <div class="pt-2">{{ property?.categoria | uppercase }}</div>
        <div class="pt-2 ml-auto">CÓDIGO {{ property?.codigo }}</div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="i-title pt-2">{{ property?.bairro | uppercase }}</div>
      </div>
      <div class="col-12 col-sm-6 text-sm-right">
        <div *ngIf="property?.podeMostrarValorAnterior || false" class="i-text">
          <s>{{ property?.valorAnterior | R$ : 0 }}</s>
        </div>
        <div class="i-title pt-2 pt-sm-1">
          <span
            *ngIf="property?.statusEx === StatusEnum.Rent"
            data-toggle="tooltip"
            data-html="true"
            [title]="property?.valorAluguelDetalhado">
            <i class="iconify" data-icon="mdi-information-outline"></i>
          </span>

          <!--Etiqueta Laranja
                    <ng-container *ngIf="property?.etiquetaLaranja">
                        <img src="assets/img/logo-etiqueta-laranja.png" class="img-fluid mr-1" title="Etiqueta Laranja"
                            alt="Etiqueta Laranja" style="height: 1.8rem;" />
                    </ng-container>
                    -->

          <ng-container *ngIf="property?.valor > 0; else semValor">
            {{ property?.valor | R$ : 0 }}
          </ng-container>
          <ng-template #semValor> Sob consulta </ng-template>
        </div>
      </div>
    </div>
    <div class="row align-items-center py-2">
      <div class="col-12 col-lg-8 py-3">
        <div class="d-flex flex-row flex-wrap py-3">
          <div *ngIf="property?.quartosEx > 0" class="i-text pt-2 pr-2 pr-sm-3">
            {{ property?.quartosEx || 0 }} {{ property?.quartosLegendaEx }}
          </div>
          <!-- <div *ngIf="property?.totalBanheiros > 0" class="i-text pt-2 px-2 px-sm-3">
                        {{property?.totalBanheiros || 0}} BANHEIRO{{property?.totalBanheiros == 1 ? '' : 'S'}}</div> -->
          <div *ngIf="property?.vagas > 0" class="i-text pt-2 px-2 px-sm-3">
            {{ property?.vagas || 0 }} VAGA{{ property?.vagas === 1 ? '' : 'S' }}
          </div>
          <div *ngIf="property?.area > 0" class="i-text pt-2 px-sm-3">
            {{ property?.area }}
            <ng-container *ngIf="!property?.agro">m<sup>2</sup></ng-container>
            <ng-container *ngIf="property?.agro">ha</ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="property?.id" class="col-12 col-lg-4 p-0 py-lg-3">
        <div class="d-flex justify-content-around">
          <a
            class="i-btn"
            href="https://api.whatsapp.com/send?text={{ shareText }}"
            target="_blank"
            data-toggle="tooltip"
            rel="noopener"
            (click)="onGaEvent('click', 'whatsapp', property?.codigo)"
            title="COMPARTILHAR">
            <i class="iconify" data-icon="mdi-ios-share"></i>
            <!-- COMPARTILHAR -->
          </a>
          <a
            class="i-btn"
            href="javascript:void(0);"
            (click)="onChangeFavorite()"
            [class.i-btn-inverted]="isFavorite"
            [title]="!isFavorite ? 'ADICIONAR AOS FAVORITOS' : 'REMOVER DOS FAVORITOS'"
            data-toggle="tooltip"
            rel="noopener">
            <i *ngIf="!isFavorite"><i class="iconify" data-icon="mdi-heart-outline"></i></i>
            <i *ngIf="isFavorite"><i class="iconify" data-icon="mdi-heart"></i></i>
            <!-- FAVORITAR -->
          </a>
          <a
            class="i-btn"
            href="javascript:void(0);"
            (click)="onChangeDiscard()"
            [class.i-btn-inverted]="isDiscarded"
            [title]="!isDiscarded ? 'ADICIONAR AOS DESCARTADOS' : 'REMOVER DOS DESCARTADOS'"
            data-toggle="tooltip"
            rel="noopener"
            title="DESCARTAR">
            <i *ngIf="!isDiscarded"><i class="iconify" data-icon="mdi-trash-can-outline"></i></i>
            <i *ngIf="isDiscarded"><i class="iconify" data-icon="mdi-trash-can"></i></i>
            <!-- DESCARTAR -->
          </a>
          <a
            class="i-btn"
            href="javascript:void(0);"
            data-toggle="tooltip"
            rel="noopener"
            title="COMPARAR"
            (click)="onCompare()">
            <!-- [routerLink]="['/imoveis/comparar', property?.codigo]" -->
            <i class="iconify" data-icon="mdi-equal"></i>
            <!-- COMPARAR -->
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8 py-3 pr-3 pr-lg-5">
        <div class="i-title pt-1 pb-3">
          <!--Badge-->
          <ng-container
            *ngIf="
              !isDiscarded &&
              (property?.exclusivo ||
                property?.lancamento ||
                property?.destaqueWeb ||
                property?.superDestaqueWeb ||
                property?.agro ||
                property?.locacaoSobMedida)
            ">
            <span
              class="badge i-badge mr-1"
              [ngClass]="
                property?.agro
                  ? 'i-agro'
                  : property?.locacaoSobMedida
                  ? 'i-sob-medida'
                  : property?.paraReforma
                  ? 'i-reforma'
                  : property?.exclusivo
                  ? 'i-exclusivo'
                  : property?.incorp
                  ? 'i-incorp'
                  : property?.lancamento
                  ? 'i-lancamento'
                  : 'i-destaque'
              ">
              {{
                property?.agro
                  ? '+AGRO'
                  : property?.locacaoSobMedida
                  ? 'SOB MEDIDA'
                  : property?.paraReforma
                  ? 'P/REFORMA'
                  : property?.exclusivo
                  ? 'EXCLUSIVO'
                  : property?.lancamento
                  ? 'LANÇAMENTO'
                  : 'DESTAQUE'
              }}</span
            >
          </ng-container>

          {{ property?.titulo }}
        </div>
        <div class="i-text py-3">
          <p
            [innerHTML]="
              (this.property?.descricaoWeb?.length <= 500 || showCompleteInfo
                ? property?.descricaoWeb
                : (property?.descricaoWeb | slice : 0 : 500) + '...'
              ) | html
            "></p>
          <p *ngIf="this.property?.descricaoWeb?.length > 500" class="py-2">
            <a
              class="i-text-sm i-center-edge pb-1"
              href="javascript:void(0);"
              rel="nofollow"
              (click)="showCompleteInfo = !showCompleteInfo">
              {{ showCompleteInfo ? 'VER MENOS' : 'VER MAIS' }}
            </a>
          </p>
          <hr />
        </div>
        <div *ngIf="property?.passeio360" class="pb-4">
          <div class="i-subtitle mb-2">TOUR VIRTUAL</div>
          <div class="text-center i-video" (click)="onTour360()">
            <img
              defaultImage="assets/img/img_loading.webp"
              [lazyLoad]="property.fotoDestaqueUrl"
              width="480"
              class="img-fluid" />
            <div><i class="iconify" data-icon="iconoir:360-view"></i></div>
          </div>
          <hr />
        </div>
        <div *ngIf="property?.videos?.length > 0" class="pb-4">
          <div class="i-subtitle mb-2">VÍDEO</div>
          <div class="w-100 text-center">
            <div
              class="i-video cursor-pointer"
              *ngFor="let video of property.videos; let i = index"
              (click)="onVideoPlay(i)">
              <img
                defaultImage="assets/img/img_loading.webp"
                [lazyLoad]="video.fotoPequenaUrl"
                width="480"
                class="img-fluid" />
              <div><i class="iconify" data-icon="ci:play-circle-outline"></i></div>
            </div>
          </div>
          <hr />
        </div>
        <div *ngIf="property?.caracteristicas?.length > 0" class="pb-4">
          <a
            class="i-subtitle i-more w-100 d-flex justify-content-between"
            href="#"
            data-toggle="collapse"
            data-target="#collapseFeature"
            (click)="showFeature = !showFeature">
            IMÓVEL
            <img
              class="pt-1"
              [src]="showFeature ? 'assets/img/btn_menos.svg' : 'assets/img/btn_mais.svg'"
              style="width: 15px; height: 15px"
              alt="Mais/Menos" />
            <div class="i-tooltip i-position">{{ showFeature ? 'VER MENOS' : 'VER MAIS' }}</div>
          </a>
          <div class="collapse show mt-4" id="collapseFeature">
            <div *ngFor="let carac of property?.caracteristicas" class="badge badge-pill badge-private mr-2 mt-2">
              {{ carac?.nome }}
            </div>
          </div>
          <hr />
        </div>
        <div *ngIf="property?.infraestruturas?.length > 0" class="pb-4">
          <a
            class="i-subtitle i-more w-100 d-flex justify-content-between"
            href="#"
            data-toggle="collapse"
            data-target="#collapseInfra"
            (click)="showInfra = !showInfra">
            CONDOMÍNIO
            <img
              class="pt-1"
              [src]="showInfra ? 'assets/img/btn_menos.svg' : 'assets/img/btn_mais.svg'"
              style="width: 15px; height: 15px"
              alt="Mais/Menos" />
            <div class="i-tooltip i-position">{{ showInfra ? 'VER MENOS' : 'VER MAIS' }}</div>
          </a>
          <div class="collapse show mt-4" id="collapseInfra">
            <div *ngFor="let infra of property?.infraestruturas" class="badge badge-pill badge-private mr-2 mt-2">
              {{ infra?.nome }}
            </div>
          </div>
          <hr />
        </div>
        <div *ngIf="property?.statusEx === StatusEnum.Rent" class="pb-4">
          <a
            class="i-subtitle i-more w-100 d-flex justify-content-between"
            href="#"
            data-toggle="collapse"
            data-target="#collapseValues"
            (click)="showValues = !showValues">
            VALORES
            <img
              class="pt-1"
              [src]="showValues ? 'assets/img/btn_menos.svg' : 'assets/img/btn_mais.svg'"
              style="width: 15px; height: 15px"
              alt="Mais/Menos" />
            <div class="i-tooltip i-position">{{ showValues ? 'VER MENOS' : 'VER MAIS' }}</div>
          </a>
          <div class="collapse show mt-3 mb-4" id="collapseValues">
            <div [innerHTML]="property?.valorAluguelDetalhado"></div>
          </div>
          <hr />
        </div>
        <div class="pb-4">
          <a
            class="i-subtitle i-more w-100 d-flex justify-content-between"
            href="#"
            data-toggle="collapse"
            data-target="#collapseLocal"
            (click)="showLocal = !showLocal">
            LOCALIZAÇÃO
            <img
              class="pt-1"
              [src]="showLocal ? 'assets/img/btn_menos.svg' : 'assets/img/btn_mais.svg'"
              style="width: 15px; height: 15px"
              alt="Mais/Menos" />
            <div class="i-tooltip i-position">{{ showLocal ? 'VER MENOS' : 'VER MAIS' }}</div>
          </a>
          <div class="collapse show text-center mt-4" id="collapseLocal">
            <ng-container *ngIf="property?.mapaUrl">
              <img
                defaultImage="assets/img/img_loading.webp"
                [lazyLoad]="property?.mapaUrl"
                errorImage="assets/img/mapa_placeholder.png"
                class="img-fluid"
                (onStateChange)="mapLoadCallback($event)" />
            </ng-container>
            <ng-container *ngIf="walkscore$ | async as ws">
              <div
                *ngIf="ws?.walkscore > 10"
                class="d-flex flex-row align-items-center mx-auto mt-2"
                style="width: 85%">
                <div>
                  <div class="i-walkscore">
                    <a href="https://www.redfin.com/how-walk-score-works" target="_blank">{{ ws.walkscore }}</a>
                  </div>
                </div>
                <div style="font-size: 90%">
                  O Walk Score&reg; mede a capacidade de caminhada em qualquer endereço, analisando centenas de rotas a
                  pé, densidade populacional, comprimento do quarteirão, entre outros fatores.
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4 pb-3 pr-3 pr-lg-0">
        <div class="sticky-top pt-3">
          <!-- Broker / Corretor -->
          <ng-container *ngIf="corretor">
            <div class="i-broker text-center pb-3">
              <img *ngIf="corretor?.fotoUrl" [src]="corretor?.fotoUrl" [alt]="corretor?.nome" />
              <div class="i-subtitle pt-3">
                {{ corretor?.nome }}
                <span
                  *ngIf="corretor?.creci"
                  data-toggle="tooltip"
                  data-html="true"
                  [title]="'CRECI ' + corretor?.creci">
                  <i class="iconify" data-icon="mdi-information-outline"></i>
                </span>
              </div>
              <!-- <div class="pt-1 d-flex flex-column small">
                                CRECI {{property?.corretor?.creci}}
                            </div> -->
              <div *ngIf="corretor" class="i-phone pt-1 d-flex flex-column">
                <!-- <ng-container *ngIf="!property?.agro; else agroPhone">
                  <a *ngIf="!property?.lancamento && property?.uf !== 'SP'" href="tel:+555132762040">51 3276 2040</a>
                  <ng-template #spPhone>
                    <a href="tel:+55{{ formatPhone(property?.corretor) }}">
                      {{ property?.corretor?.celular ? property?.corretor?.celular : property?.corretor?.fone }}</a
                    >
                  </ng-template>
                </ng-container>
                <ng-template #agroPhone>
                  <a href="tel:{{ formatPhone(property?.corretor) }}">
                    {{ property?.corretor?.celular || property?.corretor?.fone }}
                  </a>
                </ng-template> -->

                <!-- <a
                  href="https://api.whatsapp.com/send?phone=55{{ formatPhone(property?.corretor) }}&text={{
                    whatsappInfoText
                  }}"
                  class="mt-2"
                  target="_blank"
                  rel="noopener"
                  title="Whatsapp do corretor"
                  (click)="onGaEvent('click', 'whatsapp', property?.corretor?.celular)">
                  <div class="badge badge-whatsapp p-3">
                    CONTATE O ESPECIALISTA <i class="iconify" data-icon="mdi-whatsapp" style="font-size: 150%"></i>
                  </div>
                </a> -->
                <app-corretor-whatsapp [propertyCode]="property?.codigo" [broker]="corretor"></app-corretor-whatsapp>
              </div>
            </div>
          </ng-container>
          <div class="py-3">
            <button
              *ngIf="property?.status === StatusEnum.Sell"
              id="i-btn-agende"
              type="button"
              class="i-btn i-btn-cta my-1 col"
              (click)="this.schedulingType = SchedulingType.Schedule"
              data-toggle="modal"
              data-target="#modal_agende">
              AGENDAR VISITA
            </button>
            <button
              *ngIf="property.uf !== 'SP'"
              type="button"
              class="i-btn i-btn-cta my-1 col"
              (click)="this.schedulingType = SchedulingType.Info"
              data-toggle="modal"
              data-target="#modal_agende">
              MAIS INFORMAÇÕES
            </button>
            <!-- <button *ngIf="showProposalBtn" type="button" class="i-btn i-btn-cta my-1 col"
                            (click)="this.schedulingType = SchedulingType.Proposal" data-toggle="modal"
                            data-target="#modal_agende">FAZER
                            PROPOSTA</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Imóveis do empreendimento -->
<app-property-list
  *ngIf="property?.id && (property?.isBuilding || property?.codigoEmpreendimento)"
  class="pb-5"
  titulo="IMÓVEIS DESTE EMPREENDIMENTO"
  [empCode]="property?.isBuilding ? property?.codigo : property?.codigoEmpreendimento"></app-property-list>
<!-- Se agência 8, então não mostra sugestões e outros imóveis -->
<ng-container *ngIf="property?.codigoAgencia !== '8'">
  <!-- Sugestões de busca -->
  <app-search-suggest *ngIf="property?.id && property?.codigo" class="pb-5" [code]="property?.codigo">
  </app-search-suggest>
  <!-- Outros imóveis -->
  <app-property-list
    *ngIf="property?.id && property?.codigo"
    class="pb-5"
    titulo="VOCÊ TAMBÉM PODE SE INTERESSAR"
    [code]="property?.codigo">
  </app-property-list>
  <!-- Modal carousel -->
  <ngx-image-gallery
    [conf]="{
      imageOffset: '0px',
      showThumbnails: false,
      reactToMouseWheel: false,
      reactToKeyboard: true,
      reactToRightClick: true,
    }">
  </ngx-image-gallery>
</ng-container>
<!-- Modal video -->
<div id="modalVideo" class="modal fade">
  <div class="modal-dialog">
    <div class="modal-content bg-transparent">
      <div class="modal-header p-0 m-0">
        <button type="button" class="close text-white" data-dismiss="modal" aria-hidden="true">×</button>
      </div>
      <div class="modal-body embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="" frameborder="0" allowfullscreen> </iframe>
      </div>
    </div>
  </div>
</div>
<!-- Modal agendamento -->
<app-scheduling [type]="this.schedulingType" [codigo]="this.property.codigo" [imovel]="this.property"></app-scheduling>
