export class ImovelLancamento {
  codigoEmpreendimento: string;
  empreendimento: string;
  fotoDestaqueEmpreendimento: string;
  dataEntrega: number;
  bairro: string;
  cidadeSlug: string;
  empreendimentoSlug: string;
  status: number;
  minArea: number = 0;
  maxArea: number = 0;
  minDormitorios: number = 0;
  maxDormitorios: number = 0;
  minSuites: number = 0;
  maxSuites: number = 0;
  minVagas: number = 0;
  maxVagas: number = 0;
  minValor: number;
  maxValor: number;
  qtdUnidades: number = 0;
  statusUrl: string;
  relativeUrl: string;
  //
  incorporadoraNome?: string;
  incorporadoraSlug?: string;
  logoIncorporadoraUrl?: string;

  constructor(values?: any) {
    this.codigoEmpreendimento = '000000';
    if (values) Object.assign(this, values);
  }
}
