import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { CorretorPropertiesComponent } from './corretor-properties/corretor-properties.component';
import { CorretorRoutingModule } from './corretor-routing.module';
import { CorretorWhatsappComponent } from './corretor-whatsapp/corretor-whatsapp.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
  dropSpecialCharacters: false,
};

@NgModule({
  declarations: [CorretorPropertiesComponent, CorretorWhatsappComponent],
  imports: [
    CommonModule,
    CorretorRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  exports: [CorretorWhatsappComponent],
})
export class CorretorModule {}
