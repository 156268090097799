import { Type } from 'class-transformer';
import { SearchItem } from './search-item';

export class Bairro {
  grupo: string;
  @Type(() => SearchItem) bairros: SearchItem[];

  constructor(values?: any) {
    this.grupo = 'Bairros';
    this.bairros = [];

    if (values) Object.assign(this, values);
  }
}
