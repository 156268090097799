import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ErrorStateMatcher, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks } from 'ng-lazyload-image';
import { CookieService } from 'ngx-cookie-service';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import 'reflect-metadata';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { CorretorLayoutComponent } from './layout/corretor/corretor.component';
import { DefaultLayoutComponent } from './layout/default/default.component';
import { LandingLayoutComponent } from './layout/landing/landing.component';
import { BolaDaVezModule } from './modules/bola-da-vez/bola-da-vez.module';
import { BombandoModule } from './modules/bombando/bombando.module';
import { CampaignModule } from './modules/campaign/campaign.module';
import { ExclusiveModule } from './modules/exclusive/exclusive.module';
import { IncorporadoraModule } from './modules/incorporadora/incorporadora.module';
import { NewReleasesModule } from './modules/new-releases/new-releases.module';
import { PropertyModule } from './modules/property/property.module';
import { MenuComponent } from './shared/components/menu/menu.component';
import { SharedModule } from './shared/shared.module';
import { BaseErrorStateMatcher } from './shared/util/base-errorstatematcher';
import { RequestOptionsService } from './shared/util/request-options.service';
import { SessionManager } from './shared/util/session-manager';

import localePt from '@angular/common/locales/pt';
import packageInfo from '../../package.json';

registerLocaleData(localePt, 'pt-BR');

// Bugsnag
Bugsnag.start({
  apiKey: 'acc66423e904db06eb23b8b86ff7fe5c',
  appVersion: packageInfo.version,
  // onError: function (event) {
  //     event.addMetadata('company', {
  //         name: "Acme Co.",
  //         country: "uk"
  //     })
  // }
});
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

// https://tinesoft.github.io/ngx-cookieconsent/home
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  position: 'bottom-right',
  theme: 'block',
  palette: {
    popup: {
      background: '#778594',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#222222',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message: 'Nosso site usa cookies para melhorar sua experiência de navegação.',
    dismiss: 'Ok, entendi',
    deny: 'Recusar',
    link: 'Saiba mais',
    href: 'https://cookiesandyou.com',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  declarations: [AppComponent, DefaultLayoutComponent, CorretorLayoutComponent, LandingLayoutComponent, HomeComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatListModule,
    NgxHmCarouselModule,
    LazyLoadImageModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    SharedModule.forRoot(),
    PropertyModule.forRoot(),
    NewReleasesModule.forRoot(),
    ExclusiveModule.forRoot(),
    BombandoModule.forRoot(),
    CampaignModule.forRoot(),
    BolaDaVezModule.forRoot(),
    IncorporadoraModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: RequestOptionsService, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: ErrorStateMatcher, useClass: BaseErrorStateMatcher },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    SessionManager,
    CookieService,
  ],
  exports: [MenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
