import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'preposition' })
export class PrepositionPipe implements PipeTransform {
  transform(word: string, femaleWord: string, maleWord: string) {
    if (!word) return maleWord;
    let firstWord = word.split(' ')[0].toUpperCase();
    if (firstWord == 'MOEMA') return 'em';
    return firstWord.endsWith('A') ? femaleWord : maleWord;
  }
}
