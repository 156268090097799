import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CorretorPropertiesComponent } from './corretor-properties/corretor-properties.component';

const routes: Routes = [
  { path: ':slug/imoveis', component: CorretorPropertiesComponent },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CorretorRoutingModule {}
