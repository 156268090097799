<nav class="navbar navbar-expand-xl px-0">
  <div class="button-burger ml-auto px-2">
    <button class="btn btn-default navbar-toggler" type="button" (click)="toggleMenu()" title="Menu">
      <i class="iconify" data-icon="mdi-menu" [class.text-white]="isHome"></i>
    </button>
  </div>
  <div class="collapse navbar-collapse">
    <ul class="navbar-nav i-navbar ml-auto text-center">
      <ng-container *ngIf="!menuOpened">
        <ng-container *ngIf="isRegionDefined()">
          <li class="nav-item px-2 pt-1">
            <a
              class="nav-link i-center-edge"
              href="#"
              [routerLink]="['/imoveis/status/', 'venda']"
              routerLinkActive="active"
              [ngClass]="{ 'text-white': isHome }"
              >COMPRAR</a
            >
          </li>
          <li class="nav-item px-2 pt-1">
            <a
              class="nav-link i-center-edge"
              href="#"
              [routerLink]="['/imoveis/status/', 'alugar']"
              routerLinkActive="active"
              [ngClass]="{ 'text-white': isHome }"
              >ALUGAR</a
            >
          </li>
          <li class="nav-item px-2 pt-1 d-none d-xl-block">
            <a
              class="nav-link i-center-edge"
              href="#"
              routerLink="/imoveis/agro"
              routerLinkActive="active"
              [ngClass]="{ 'text-white': isHome }"
              >AGRO</a
            >
          </li>
          <li class="nav-item px-2 pt-1 d-none d-xl-block">
            <a
              class="nav-link i-center-edge"
              href="#"
              routerLink="/imoveis/corporate"
              routerLinkActive="active"
              [ngClass]="{ 'text-white': isHome }"
              >CORP</a
            >
          </li>
          <li class="nav-item px-2 pt-1 d-none d-xl-block">
            <a
              class="nav-link i-center-edge"
              href="#"
              routerLink="/imoveis/lancamentos"
              routerLinkActive="active"
              [ngClass]="{ 'text-white': isHome }"
              >LANÇAMENTOS</a
            >
          </li>
          <li class="nav-item px-2 pt-1 d-none d-xl-block">
            <a
              class="nav-link i-center-edge"
              href="#"
              routerLink="/imoveis/exclusividades"
              routerLinkActive="active"
              [ngClass]="{ 'text-white': isHome }"
              >EXCLUSIVIDADES</a
            >
          </li>
          <li class="nav-item px-2 pt-1 d-none d-xl-block">
            <a
              class="nav-link i-center-edge text-nowrap"
              href="#"
              routerLink="/em-alta"
              routerLinkActive="active"
              [ngClass]="{ 'text-white': isHome }"
              >EM ALTA</a
            >
          </li>
        </ng-container>
        <li class="nav-item mnu-icon mnu-phone d-none d-xl-block">
          <button
            class="btn btn-default pt-1"
            type="button"
            (click)="onPhone()"
            [ngClass]="{ 'text-white': isHome }"
            title="Telefone">
            <i class="iconify" data-icon="mdi-phone"></i>
            <!-- LIGAR -->
          </button>
        </li>
        <li class="nav-item mnu-icon">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-link dropdown-toggle"
              data-toggle="dropdown"
              data-display="static"
              aria-haspopup="true"
              aria-expanded="false"
              [ngClass]="{ 'text-white': isHome }"
              title="Meu menu">
              <i class="iconify" data-icon="mdi-account"></i>
              <!-- USUARIO -->
            </button>
            <div class="dropdown-menu dropdown-menu-lg-right" [class.i-logged]="isClientLogged">
              <ng-container *ngIf="!isClientLogged">
                <a class="dropdown-item" type="button" routerLink="/cadastre-se">CADASTRE-SE</a>
                <a class="dropdown-item" type="button" routerLink="/login">ENTRAR</a>
              </ng-container>
              <ng-container *ngIf="isClientLogged">
                <h3 class="dropdown-header">Olá {{ clientName }}</h3>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" type="button" routerLink="/meus-dados">MEUS DADOS</a>
                <a class="dropdown-item text-nowrap" type="button" routerLink="/imoveis/favoritos">
                  FAVORITOS
                  <span class="float-right">{{ countFavorites$ | async }}</span>
                </a>
                <a class="dropdown-item text-nowrap" type="button" routerLink="/imoveis/descartados">
                  DESCARTADOS
                  <span class="float-right">{{ countDiscards$ | async }}</span>
                </a>
                <a class="dropdown-item text-nowrap" type="button" routerLink="/imoveis/comparar">COMPARAÇÃO</a>
                <div class="dropdown-divider"></div>
                <!-- <a
                  class="dropdown-item text-nowrap"
                  type="button"
                  target="_blank"
                  href="https://b24-evnmlj.bitrix24.site/private_chamados_internos/"
                  >PEDIDO DE MANUTENÇÃO</a
                >
                <a
                  class="dropdown-item text-nowrap"
                  type="button"
                  target="_blank"
                  href="https://b24-evnmlj.bitrix24.site/pedido_desocupa%C3%A7%C3%A3o/"
                  >PEDIDO DE DESOCUPAÇÃO</a
                >
                <a
                  class="dropdown-item text-nowrap"
                  type="button"
                  target="_blank"
                  href="https://b24-k9pb9b.bitrix24.site/private-interno/"
                  >ADMINISTRATIVO E FINANCEIRO</a
                > -->
                <a class="dropdown-item text-nowrap" type="button" target="_blank" href="/boletos">2ª VIA DE BOLETO</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" type="button" routerLink="/logout">SAIR</a>
              </ng-container>
            </div>
          </div>
        </li>
      </ng-container>
      <li class="button-burger nav-item pl-3">
        <button
          class="btn btn-default pt-1"
          type="button"
          (click)="toggleMenu()"
          [ngClass]="{ 'text-white': isHome }"
          title="Menu">
          <i class="iconify" data-icon="mdi-menu"></i>
        </button>
      </li>
    </ul>
  </div>
</nav>
<!--Fixed-Menu-->
<div class="fixed-menu invisible" (click)="menuOpened ? toggleMenu() : false" [class.invisible]="!menuOpened">
  <div class="menus-nav px-2 pt-0 pt-sm-3">
    <div class="button-close py-0 py-sm-2 text-right">
      <button class="btn btn-default" type="button" (click)="toggleMenu()" title="Menu">
        <i class="iconify" data-icon="mdi-window-close"></i>
      </button>
    </div>
    <ul class="navbar-nav pl-4 pr-5 pt-0 pt-sm-1">
      <!-- USUARIO -->

      <ng-container *ngIf="isClientLogged">
        <li class="nav-item"><a href="#" class="nav-link text-left" routerLink="/meus-dados">MEUS DADOS</a></li>
        <li class="nav-item">
          <a
            class="nav-link text-left"
            target="_blank"
            href="https://b24-evnmlj.bitrix24.site/private_chamados_internos/"
            >PEDIDO DE MANUTENÇÃO</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-left"
            target="_blank"
            href="https://b24-evnmlj.bitrix24.site/pedido_desocupa%C3%A7%C3%A3o/"
            >PEDIDO DE DESOCUPAÇÃO</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link text-left" target="_blank" href="https://b24-k9pb9b.bitrix24.site/private-interno/"
            >ADMINISTRATIVO E FINANCEIRO</a
          >
        </li>
        <li class="nav-item"><a href="#" class="nav-link text-left" routerLink="/logout">SAIR</a></li>
      </ng-container>

      <li *ngIf="!isClientLogged" class="nav-item">
        <a href="#" class="nav-link text-left" routerLink="/login">LOGIN</a>
      </li>

      <li class="dropdown-divider"></li>

      <!-- INSTITUCIONAL -->

      <li class="nav-item">
        <a
          href="#collapseItem"
          class="nav-link text-left"
          routerLink="/institucional"
          aria-expanded="false"
          routerLinkActive="active"
          >INSTITUCIONAL</a
        >
      </li>
      <li *ngFor="let p of pages$ | async" class="i-sub-item nav-item">
        <a href="#" class="nav-link text-left" [routerLink]="'/institucional/' + p.slug" routerLinkActive="active"
          ><small>{{ p.menu | uppercase }}</small></a
        >
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link text-left" routerLink="/trabalhe-conosco" routerLinkActive="active">
          TRABALHE CONOSCO
        </a>
      </li>

      <li class="dropdown-divider"></li>

      <li class="nav-item">
        <a href="#" class="nav-link text-left" routerLink="/boletos" routerLinkActive="active">
          2ª VIA BOLETOS DE ALUGUEL
        </a>
      </li>

      <li class="dropdown-divider"></li>

      <!-- IMOVEIS -->

      <li *ngIf="isRegionDefined()" class="nav-item text-left">
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/imoveis/status/', 'venda']">IMÓVEIS</a>
      </li>
      <li class="nav-item text-left">
        <a class="nav-link" routerLink="/imoveis/agro" routerLinkActive="active">AGRO</a>
      </li>
      <li class="nav-item text-left">
        <a class="nav-link" routerLink="/imoveis/corporate" routerLinkActive="active">CORPORATE</a>
      </li>
      <!-- <li class="nav-item text-left">
        <a class="nav-link" routerLink="/imoveis/bola-da-vez" routerLinkActive="active">BOLA DA VEZ</a>
      </li> -->
      <li class="nav-item text-left">
        <a class="nav-link" routerLink="/imoveis/lancamentos" routerLinkActive="active">LANÇAMENTOS</a>
      </li>
      <li class="nav-item text-left">
        <a class="nav-link" routerLink="/imoveis/exclusividades" routerLinkActive="active">EXCLUSIVIDADES</a>
      </li>
      <li class="nav-item text-left">
        <a class="nav-link" routerLink="/em-alta" routerLinkActive="active">EM ALTA</a>
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link text-left" routerLink="/imoveis/favoritos">
          FAVORITOS
          <span class="float-right">{{ countFavorites$ | async }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link text-left" routerLink="/imoveis/descartados">
          DESCARTADOS
          <span class="float-right">{{ countDiscards$ | async }}</span>
        </a>
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link text-left" routerLink="/imoveis/comparar">COMPARAÇÃO</a>
      </li>

      <li class="dropdown-divider"></li>

      <!-- REDES -->

      <li class="nav-item i-social d-flex justify-content-around">
        <a href="https://www.instagram.com/privateimobiliaria/" class="nav-link" target="_blank" rel="noopener"
          ><i class="iconify" data-icon="mdi-instagram"></i><br /><small>POA</small></a
        >
        <a href="https://www.instagram.com/privateimobiliaria_sp/" class="nav-link" target="_blank" rel="noopener"
          ><i class="iconify" data-icon="mdi-instagram"></i><br /><small>SP</small></a
        >
        <a href="https://www.instagram.com/privateimoveisagro/" class="nav-link" target="_blank" rel="noopener"
          ><i class="iconify" data-icon="mdi-instagram"></i><br /><small>+AGRO</small></a
        >
        <!-- <a href="https://www.facebook.com/privateimoveis" class="nav-link" target="_blank" rel="noopener"
          ><i class="iconify" data-icon="mdi-facebook"></i><br /><small></small
        ></a> -->
        <a class="nav-link" href="https://blog.privateimoveis.com" target="_blank" rel="noopener"
          ><i class="iconify" data-icon="mdi-blogger"></i><br /><small>BLOG</small></a
        >
      </li>
      <li class="dropdown-divider"></li>

      <!-- CONTATO -->

      <li class="nav-item text-left">
        <a href="#" class="nav-link" routerLink="/contato" routerLinkActive="active">CONTATO</a>
      </li>
    </ul>
    <!-- fixed-bottom -->
    <div class="i-menu-brand navbar-nav pl-4 py-1 py-sm-3">
      <div class="nav-item text-left">
        <a href="#" class="nav-link i-text-sm" routerLink="/home" routerLinkActive="active">&copy; Private 2024</a>
      </div>
    </div>
  </div>
</div>
