import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Search } from 'src/app/shared/models/search';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';

@Component({
  selector: 'app-corretor-layout',
  templateUrl: './corretor.component.html',
  styleUrls: ['./corretor.component.scss'],
})
export class CorretorLayoutComponent {
  fmGroup: UntypedFormGroup;
  search: Search;

  constructor(
    private ssr: SsrUtilService,
    private router: Router,
    private ga: AnalyticsService
  ) {
    // @diegodsp: Google Analytics em SPA
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ga.trackPage(event.urlAfterRedirects);

        // @diegodsp: bug tooltip, fica preso quando se clica nele
        if (this.ssr?.isBrowser) document.querySelectorAll('[role="tooltip"]').forEach(x => x.remove());
      }
    });

    // força nova rota
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
}
