<div class="i-page-top">
  <div class="container">
    <!--Menu-->
    <div class="i-menu row align-items-center pt-3 pb-2">
      <div class="navbar-brand col-6 col-sm-8 col-md-6 col-lg-4 mr-auto py-2 px-0 text-center text-sm-left">
        <a href="#" routerLink="/home" aria-label="Private"
          ><img src="assets/img/logo_cinza.svg" class="img-fluid my-auto" alt="Private"
        /></a>
      </div>
      <div class="col-6 col-sm-4 col-md-6 col-lg-8 ml-auto py-2">
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</div>
<div class="i-page-content">
  <div class="i-favorite container pb-5">
    <div class="row text-center py-5">
      <h1 class="i-title mx-auto">{{ this.title.toUpperCase() }}</h1>
    </div>
    <div class="row">
      <ng-container *ngIf="properties$ | async as properties">
        <div class="d-flex flex-row flex-wrap col-12 px-0 pt-5">
          <div *ngFor="let property of properties" class="i-box col-12 col-md-6 col-lg-4 mb-5">
            <app-box [property]="property"></app-box>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
