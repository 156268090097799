import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewReleasesPageComponent } from './components/page/new-releases-page.component';

const routes: Routes = [
  // redundância, para novo padrão
  { path: 'imoveis/lancamentos', component: NewReleasesPageComponent },
  { path: 'imoveis/lancamentos/:cidade', component: NewReleasesPageComponent },
  // conforme solicitado inicialmente
  { path: 'lancamentos', component: NewReleasesPageComponent },
  { path: 'lancamentos/:cidade', component: NewReleasesPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewReleasesRoutingModule {}
