import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'R$' })
export class RealCurrencyPipe implements PipeTransform {
  transform(v: number, decimals: number = 2) {
    try {
      if (isNaN(v)) return 'R$ 0,00';
      else
        return Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }).format(v);
    } catch (error) {
      return 'R$ 0,00';
    }
  }
}
