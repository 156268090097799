import { Component, OnInit } from '@angular/core';
import { MetaSchemaOrganizationService } from './shared/util/organization.service';
import { SeoService } from './shared/util/seo.service';
import { SessionManager } from './shared/util/session-manager';
import { SsrUtilService } from './shared/util/ssr-util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // ver DefaultLayoutComponent

  constructor(
    private ssr: SsrUtilService,
    private org: MetaSchemaOrganizationService,
    private seo: SeoService,
    private sm: SessionManager
  ) {}

  ngOnInit(): void {
    if (this.ssr?.isBrowser) {
      if (this.ssr?.isMobileDevice()) document.querySelector('body').classList.add('i-mobile');
      this.sm.defineLandingPage(this.ssr?.getLocation());
    }

    // Adiciona/Atualiza as Tags ao head da pagina
    this.seo
      .reset()
      .addSitename('Private Imóveis')
      .addTitle('Private')
      .addDescription('+55 51 3276.2040 - Rua 24 de Outubro, 1440 - sala 1605 - Porto Alegre, RS')
      .addRelativeImage('/assets/img/thumb_default.png')
      .addUrl(this.ssr?.getLocation().href)
      .addTwitterSite('@privateimoveis');

    // Adiciona Organization
    this.org
      .addOrganization({
        name: 'Private Imóveis',
        url: 'https://www.privateimoveis.com',
        logo: {
          url: 'https://cms.privateimoveis.com/assets/img/thumb_default.png',
          width: 1051,
          height: 700,
        },
        address: {
          addressLocality: 'Porto Alegre',
          addressRegion: 'RS',
          postalCode: '90510-001',
          streetAddress: 'Rua 24 de outubro, 1440, sala 1605',
          // addressLocality: 'São Paulo',
          // addressRegion: 'SP',
          // postalCode: '01452-001',
          // streetAddress: 'Av. Cidade Jardim, 400'
        },
      })
      .render();
  }
}
