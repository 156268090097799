import { Type } from 'class-transformer';
import { Attachment } from './attachment';

export class Pagina {
  id: string;
  tipo: number;
  menu: string;
  titulo: string;
  conteudo: string;
  slug: string;
  @Type(() => Pagina) filhasAtivas: Pagina[];
  @Type(() => Attachment) anexos: Attachment[];
}
