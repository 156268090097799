import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SsrUtilService } from './ssr-util.service';

@Injectable({
  providedIn: 'root',
})
// @diegodsp: based on https://stackoverflow.com/a/50826724/231391
export class SeoService {
  constructor(
    private ssr: SsrUtilService,
    @Inject(DOCUMENT) private doc,
    private meta: Meta
  ) {
    // base tags
    this.reset();
  }

  reset(): SeoService {
    // remover
    this.meta.removeTag("name='description'");
    this.meta.removeTag("name='og:type'");
    this.meta.removeTag("name='og:url'");
    this.meta.removeTag("name='og:locale'");
    this.meta.removeTag("name='og:site_name'");
    this.meta.removeTag("name='og:title'");
    this.meta.removeTag("name='og:description'");
    this.meta.removeTag("name='og:image'");
    this.meta.removeTag("name='twitter:card'");
    this.meta.removeTag("name='twitter:title'");
    this.meta.removeTag("name='twitter:description'");
    this.meta.removeTag("name='twitter:image'");
    this.meta.removeTag("name='twitter:site'");
    //
    this.setCanonicalURL();
    this.addLocale('pt_BR');
    this.addType('website');
    this.addUrl(this.ssr?.getLocation().href);
    this.meta.updateTag({ name: 'twitter:card', content: 'summary_large_image' });

    return this;
  }

  setCanonicalURL(): SeoService {
    let link: HTMLLinkElement = this.doc.querySelectorAll('[rel="canonical"]')[0];
    if (!link) {
      link = this.doc.createElement('link');
      this.doc.head.appendChild(link);
    }
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', this.doc.URL);

    return this;
  }

  addType(type): SeoService {
    this.meta.updateTag({ property: 'og:type', content: type });

    return this;
  }

  addUrl(url): SeoService {
    this.meta.updateTag({ property: 'og:url', content: url });

    return this;
  }

  addLocale(locale): SeoService {
    this.meta.updateTag({ property: 'og:locale', content: locale });

    return this;
  }

  addSitename(name): SeoService {
    this.meta.updateTag({ property: 'og:site_name', content: name });

    return this;
  }

  addTitle(title): SeoService {
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });

    return this;
  }

  addDescription(description): SeoService {
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ name: 'twitter:description', content: description });

    return this;
  }

  addRelativeImage(url): SeoService {
    this.addImage(`${this.ssr?.getLocation().origin}/${url}`);

    return this;
  }

  addImage(url): SeoService {
    this.meta.updateTag({ property: 'og:image', content: url });
    this.meta.updateTag({ name: 'twitter:image', content: url });

    return this;
  }

  addTwitterSite(site): SeoService {
    this.meta.updateTag({ name: 'twitter:site', content: site });

    return this;
  }
}
