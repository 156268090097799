import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../util/base.service';
import { SessionManager } from '../util/session-manager';
import { Md5 } from 'ts-md5';

@Injectable()
export class AuthService extends BaseService {
  constructor(
    private sm: SessionManager,
    private http: HttpClient
  ) {
    super();
  }

  /**
   * Realiza o logout
   */
  logout(): Observable<any> {
    const headers = {
      'x-device-id': 'web',
    };

    return this.http.post<any>('auth/logout', {}, { headers }).pipe(catchError(this.handleError));
  }

  /**
   * Realiza o login
   *
   * @param email
   * @param password
   */
  login(email: string, password: string): Observable<any> {
    const json = {
      username: email,
      password: password,
    };
    const headers = {
      'x-device-id': 'web',
    };

    return this.http.post<any>(`auth/login`, json, { headers }).pipe(catchError(this.handleError));
  }

  /**
   * Realiza o login 2FA
   *
   * @param email
   * @param password
   */
  login2fa(cod: string): Observable<any> {
    // const options = {
    //     headers: {
    //         'cod': cod
    //     }
    // };
    const headers = {
      cod: cod,
      'x-device-id': 'web',
    };

    return this.http.post<any>(`auth/login2fa`, null, { headers }).pipe(catchError(this.handleError));
  }

  /**
   * Atualiza token.
   *
   * @returns
   */
  refreshToken(): Observable<any> {
    const id = this.sm.getSessionDataAuth()?.id;
    const token = this.sm.getRefreshToken();
    const json = { token };
    const headers = {
      'x-device-id': 'web',
    };

    return this.http
      .post<any>(`auth/${id}/atualizar-token`, json, { headers }) //, { withCredentials: true })
      .pipe(catchError(this.handleError));
  }

  /**
   * Lembrete de senha, envia um email para o usuário
   *
   * @param email
   */
  lembreteSenha(email: string): Observable<any> {
    const json = {
      username: email,
    };
    const headers = {
      'x-device-id': 'web',
    };

    return this.http.post<any>(`auth/lembrar-senha`, json, { headers }).pipe(catchError(this.handleError));
  }

  // /**
  //  * Solicita os dados para troca de senha
  //  *
  //  * @param id Id do token
  //  * @returns
  //  */
  // getTrocaSenha(id: string): Observable<UsuarioTrocaSenha> {
  //   const headers = {
  //     'x-device-id': 'web',
  //   };

  //   return this.http.get<any>(`auth/${id}/trocar-senha`, { headers }).pipe(
  //     map(x => new UsuarioTrocaSenha(x)),
  //     catchError(this.handleError)
  //   );
  // }

  // trocaSenha(id: string, senha: string): Observable<any> {
  //   const json = { id, senha };
  //   const headers = {
  //     'x-device-id': 'web',
  //   };

  //   return this.http.put<any>(`auth/${id}/trocar-senha`, json, { headers }).pipe(catchError(this.handleError));
  // }
}
