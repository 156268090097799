import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { SharedModule } from 'src/app/shared/shared.module';
import { PropertyModule } from '../property/property.module';
import { BombandoRoutingModule } from './bombando-routing.module';
import { BombandoComponent } from './components/bombando.component';

@NgModule({
  declarations: [BombandoComponent],
  exports: [BombandoComponent],
  imports: [
    CommonModule,
    BombandoRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    NgxHmCarouselModule,
    PropertyModule,
  ],
})
export class BombandoModule {
  static forRoot(): ModuleWithProviders<BombandoModule> {
    return {
      ngModule: BombandoModule,
    };
  }
}
