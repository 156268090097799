import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { PropertyModule } from '../property/property.module';
import { BolaDaVezRoutingModule } from './bola-da-vez-routing.module';
import { BolaDaVezComponent } from './components/page/bola-da-vez-page.component';

@NgModule({
  declarations: [BolaDaVezComponent],
  imports: [
    CommonModule,
    BolaDaVezRoutingModule,
    SharedModule,
    PropertyModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    NgxPaginationModule,
    NgxHmCarouselModule,
  ],
})
export class BolaDaVezModule {
  static forRoot(): ModuleWithProviders<BolaDaVezModule> {
    return {
      ngModule: BolaDaVezModule,
    };
  }
}
