import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { Cidade } from 'src/app/shared/models/cidade';
import { Search } from 'src/app/shared/models/search';
import { Uf } from 'src/app/shared/models/uf';
import { SateliteService } from 'src/app/shared/services/satelite.service';
import { EventHandler } from 'src/app/shared/util/handler.event';
import { SessionManager } from 'src/app/shared/util/session-manager';

@Component({
  selector: 'app-select-cidade',
  templateUrl: './select-cidade.component.html',
  styleUrls: ['./select-cidade.component.scss'],
})
export class SelectCidadeComponent implements OnInit {
  ufs$ = new BehaviorSubject<Uf[]>(null);
  @Input() search: Search;
  @Input() agro: boolean = false;
  @Output() changeSearch = new EventEmitter<Search>(true);

  constructor(
    private cd: ChangeDetectorRef,
    private sateliteService: SateliteService
  ) {}

  ngOnInit(): void {
    this.sateliteService
      .getUfs(this.agro)
      .pipe(
        // Adiciona no top as cidades de Porto Alegre e São Paulo (também uma linha vazia)
        map(result => {
          result.unshift(null);
          if (this.agro)
            result.unshift(
              new Uf({
                nome: '',
                cidades: [new Cidade({ nome: 'Todas', slug: '' })],
              })
            );
          else
            result.unshift(
              new Uf({
                nome: 'DESTAQUE',
                cidades: [
                  new Cidade({ nome: 'Porto Alegre', uf: 'RS', slug: 'porto-alegre' }),
                  new Cidade({ nome: 'São Paulo', uf: 'SP', slug: 'sao-paulo' }),
                ],
              })
            );
          return result;
        }),
        // valores default
        tap(() => {
          if (!this.search.cidade) this.search.cidade = 'porto-alegre';
          if (!this.search.status) this.search.status = '0'; // status;
        }),
        // pega o nome da cidade selecionada
        tap(result => {
          this.search.cidadeNome = this.getCityName(result);
        }),
        catchError(error => {
          console.log(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar cidades.'));
          return of([] as Uf[]);
        })
      )
      .subscribe(this.ufs$);
  }

  getCityName(estados) {
    try {
      // busca o nome da cidade
      const cid = estados?.flatMap(x => x?.cidades).find(y => y?.slug == this.search?.cidade);
      return cid ? cid?.nome : '';
    } catch (error) {
      return '';
    }
  }

  onChangeCity() {
    // busca o nome para mostrar
    if (this.search.cidade) this.search.cidadeNome = '';
    else this.search.cidadeNome = this.getCityName(this.ufs$?.getValue());

    // avisa que o modo de busca mudou
    this.changeSearch.emit(this.search);
    EventHandler.builder().searchChanged(this.search);
  }
}
