import { Injectable } from '@angular/core';

const ANALYTICS_ID = 'UA-17498431-1';
declare var ga: Function;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {
    if (this.isDefined) ga('create', ANALYTICS_ID, 'auto');
  }

  public get isDefined(): boolean {
    return typeof ga == 'function';
  }

  trackPage(url: string) {
    if (this.isDefined) ga('send', 'pageview', url);
  }

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits
  trackEvent(action: string, category: string, label: string, value: number = undefined) {
    if (this.isDefined)
      ga('send', {
        hitType: 'event',
        eventAction: action,
        eventCategory: category,
        eventLabel: label,
        eventValue: value,
      });
  }

  trackException(description: string, fatal: boolean) {
    if (this.isDefined)
      ga('send', 'exception', {
        exDescription: description,
        exFatal: fatal,
      });
  }
}
