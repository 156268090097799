import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { Incorporadora } from 'src/app/shared/models/incorporadora';
import { IncorporadoraService } from 'src/app/shared/services/incorporadora.service';
import { SessionManager } from 'src/app/shared/util/session-manager';

@Component({
  selector: 'app-banner-incorporadora',
  templateUrl: './banner-incorporadora.component.html',
  styleUrls: ['./banner-incorporadora.component.scss'],
})
export class IncorporadoraBannerComponent implements OnInit {
  @Input() isBanner: boolean = false;
  @Input() incorporadora$?: Observable<Incorporadora>;

  constructor(private service: IncorporadoraService) {}

  ngOnInit(): void {
    if (!this.incorporadora$)
      this.incorporadora$ = this.service.getBanner().pipe(
        map(info => {
          info.imagemFundoUrl = info.imagemFundoUrl ?? '../assets/img/incorporadora/banner-incorporadora.jpg';
          return info;
        }),
        catchError(error => {
          console.log(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar banner da incorporadora.'));
          // this.router.navigateByUrl('/home');
          return of(null);
        })
      );
  }
}
