import { Type } from 'class-transformer';
import { SearchItem } from './search-item';

export class Category {
  @Type(() => SearchItem) comercial: SearchItem[];
  @Type(() => SearchItem) residencial: SearchItem[];

  constructor(values?: any) {
    if (values) Object.assign(this, values);
  }
}
