import { Component, OnInit } from '@angular/core';
import { EventHandler } from '../../util/handler.event';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  logo: string = 'assets/img/logo_cinza.svg';

  constructor() {}

  ngOnInit(): void {
    EventHandler.builder()
      .getPropertyViewEventEmitter()
      .subscribe(result => {
        if (result.categoriaGrupo == 'Agronegócio') this.logo = 'assets/img/logo-agro_cinza.svg';
      });
  }
}
