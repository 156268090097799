import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Imovel } from 'src/app/shared/models/imovel';
import { ContatoService } from 'src/app/shared/services/contato.service';
import { showFeedbackDanger, showFeedbackSuccess } from 'src/app/shared/util/alert-swal';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { RealCurrencyPipe } from 'src/app/shared/util/real-currency-pipe';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';
import { checkPhoneSintax } from 'src/globals';

declare let $: any; // jquery

export enum SchedulingType {
  Info = 0,
  Schedule = 1,
  Proposal = 2,
}

interface VisitDay {
  selected: boolean;
  day: string;
  date: string;
}

interface VisitTime {
  selected: boolean;
  time: string;
}

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss'],
})
export class SchedulingComponent implements OnInit, OnChanges {
  @Input() codigo: string;
  @Input() imovel: Imovel;
  @Input() type: SchedulingType;
  fmGroup: UntypedFormGroup;
  //
  contactTitle: string = 'MAIS INFORMAÇÕES';
  showScheduleForm: boolean = false;
  //
  scheduleDays: VisitDay[] = [];
  scheduleTimes: VisitTime[] = [];
  scheduleDate: string;
  scheduleTime: string;
  sendingContact: boolean = false;

  constructor(
    private ssr: SsrUtilService,
    private formBuilder: UntypedFormBuilder,
    private contatoService: ContatoService,
    private ga: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.type?.previousValue == changes?.type?.currentValue) return;

    this.showScheduleForm = false;
    //
    switch (this.type) {
      case SchedulingType.Schedule:
        this.loadSchedules();
        this.contactTitle = 'AGENDAR VISITA';
        this.showScheduleForm = true;
        this.fmGroup?.controls['message'].setValue(
          `Olá, gostaria de agendar uma visita ao imóvel código ${this.imovel.codigo}.`
        );
        break;
      case SchedulingType.Proposal:
        this.contactTitle = 'FAZER PROPOSTA';
        let realPipe = new RealCurrencyPipe();
        this.fmGroup?.controls['message'].setValue(
          `Olá, gostaria de fazer uma proposta para o imóvel código ${
            this.imovel.codigo
          } no valor de ${realPipe.transform(this.imovel.valor)} `
        );
        this.clearSelectedSchedule();
        break;
      case SchedulingType.Info:
      default:
        this.contactTitle = 'MAIS INFORMAÇÕES';
        this.fmGroup?.controls['message'].setValue(
          `Olá, gostaria de mais informações sobre o imóvel código ${this.imovel.codigo}.`
        );
        this.clearSelectedSchedule();
        break;
    }
    //
    // setTimeout(() => {
    //     console.log('focus')
    //     var el: HTMLElement = document.querySelector('.i-contact input');
    //     el.focus();
    // }, 1000);
  }

  createForm() {
    this.fmGroup = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, Validators.required, Validators.email],
      phone: [
        null,
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(15),
        Validators.compose([checkPhoneSintax]),
      ],
      message: [null, Validators.required],
    });
  }

  formId(suffix: string): string {
    return `form-${this.showScheduleForm ? 'schedule' : 'info'}-${suffix}`;
  }

  /**
   *
   */
  loadSchedules() {
    // -----------------------------------------------------------
    // Carrega os dias
    const dtFormat = Intl.DateTimeFormat('pt-BR');
    const dtShortFormat = Intl.DateTimeFormat('pt-BR', {
      weekday: 'short',
      day: '2-digit',
    });
    for (var i = 1; i < 7; i++) {
      const dt = new Date(Date.now() + i * 24 * 60 * 60 * 1000);
      const parts = dtShortFormat?.formatToParts(dt);
      this.scheduleDays.push({
        selected: i == 1,
        day: `${parts?.find(x => x.type == 'weekday')?.value}<br/>${parts?.find(x => x.type == 'day')?.value}`,
        date: dtFormat?.format(dt),
      });
    }
    // Seleciona o primeiro dia
    this.scheduleDate = this.scheduleDays[0].date;
    // -----------------------------------------------------------
    // Carrega as horas
    const hrFormat = Intl.DateTimeFormat('pt-BR', {
      hour: 'numeric',
      minute: 'numeric',
    });
    const now = new Date();
    for (var i = 6; i < 24; i++) {
      // Deixa a hora como 00:00
      const dh = new Date(now.getFullYear(), now.getMonth(), now.getDay(), i, 0, 0);
      this.scheduleTimes.push({
        selected: i == 9,
        time: hrFormat.format(dh),
      });
    }
    // Seleciona o primeiro horário
    this.scheduleTime = this.scheduleTimes[0].time;
  }

  /**
   * Seleciona a data e/ou hora para agendar visita
   *
   * @param index
   * @param day
   * @param time
   */
  onScheduleSelected(index: number, day: string, time: string) {
    // Seleciona a data
    if (day) {
      // Desmarca a data selecionada anteriormente
      this.clearSelectedDateSchedule();
      this.scheduleDays[index].selected = true;
      this.scheduleDate = day;
    } else {
      // Desmarca a data selecionada anteriormente
      this.clearSelectedTimeSchedule();
      this.scheduleTimes[index].selected = true;
      this.scheduleTime = time;
    }
  }

  clearSelectedDateSchedule() {
    this.scheduleDays.forEach(x => {
      x.selected = false;
    });
    this.scheduleDate = undefined;
  }

  clearSelectedTimeSchedule() {
    this.scheduleTimes.forEach(x => {
      x.selected = false;
    });
    this.scheduleTime = undefined;
  }

  clearSelectedSchedule() {
    this.clearSelectedDateSchedule();
    this.clearSelectedTimeSchedule();
  }

  onSend() {
    // Valida os campos do form
    if (this.fmGroup.invalid) return;
    if (!this.codigo || !this.imovel?.codigo) {
      showFeedbackDanger('Não foi possível enviar sua mensagem', 'Atualize a página e tente novamente.');
      return;
    }

    this.sendingContact = true;
    const { name, phone, email, message } = this.fmGroup.value;
    this.contatoService
      .enviaAgendamento(
        name,
        phone,
        email,
        this.codigo || this.imovel?.codigo,
        message,
        this.scheduleDate,
        this.scheduleTime,
        this.codigo || this.imovel?.codigo
      )
      .subscribe(
        _ => {
          // limpa form
          this.fmGroup.reset();
          this.showScheduleForm = false;

          // desmarca a data selecionada anteriormente
          this.clearSelectedSchedule();

          // eventos
          this.ga.trackEvent('contact', 'property', this.imovel?.codigo);

          // feedback
          this.sendingContact = false;
          showFeedbackSuccess(
            'Sua mensagem foi enviada!',
            'Em breve, um de nossos consultores entrará em contato para confirmar o agendamento.'
          ).then(() => {
            if (this.ssr?.isBrowser) $('#modal_agende').modal('hide');
          });
        },
        error => {
          SessionManager.handleHttpError(error, 'Falha ao enviar o contato');
          this.sendingContact = false;
        }
      );
  }
}
