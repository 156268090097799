import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { PropertyModule } from '../property/property.module';
import { ExclusivePageComponent } from './components/page/exclusive-page.component';
import { ExclusiveRoutingModule } from './exclusive-routing.module';

@NgModule({
  declarations: [ExclusivePageComponent],
  imports: [
    CommonModule,
    ExclusiveRoutingModule,
    SharedModule,
    PropertyModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    NgxPaginationModule,
    NgxHmCarouselModule,
  ],
})
export class ExclusiveModule {
  static forRoot(): ModuleWithProviders<ExclusiveModule> {
    return {
      ngModule: ExclusiveModule,
    };
  }
}
