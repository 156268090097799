<div class="i-page-top">
  <div class="container">
    <!--Menu-->
    <div class="i-menu row align-items-center pt-3 pb-2">
      <div class="navbar-brand col-6 col-sm-8 col-md-6 col-lg-4 mr-auto py-2 px-0 text-center text-sm-left">
        <a href="#" routerLink="/home" aria-label="Private"
          ><img src="assets/img/logo_cinza.svg" class="img-fluid my-auto" alt="Private"
        /></a>
      </div>
      <div class="col-6 col-sm-4 col-md-6 col-lg-8 ml-auto py-2">
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="this.incorporadora$ | async as incorp">
  <div class="i-page-content">
    <!--Call-Text-->
    <app-banner-incorporadora [isBanner]="false" [incorporadora$]="this.incorporadora$"></app-banner-incorporadora>
    <div class="i-favorite container py-5">
      <div class="row text-center py-5">
        <h1 class="i-title mx-auto">IMÓVEIS {{ construtoraSlug?.toUpperCase() }}</h1>
      </div>
      <ng-container *ngIf="releases$ | async as releases">
        <ng-container *ngIf="releases?.length > 0; else empty">
          <div class="row row-cols-1 row-cols-md-3 no-gutters">
            <div *ngFor="let release of releases" class="col p-4">
              <app-summary [release]="release" [incorporadora]="true"></app-summary>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #empty>
        <div class="col i-text text-center my-3 py-5">
          <!-- SEM REGISTROS -->
          <h2 class="mb-2">Não há imóveis nesta incorporadora.</h2>
          Vá para busca e encontre o imóvei ideal para você.

          <div class="d-block mt-5">
            <a href="#" routerLink="/imoveis/status/venda" class="i-btn">Buscar imóveis</a>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade p-0" id="modal_incorp" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
    <div
      class="i-modal-bg"
      [style]="incorp?.imagemFundoUrl ? 'background-image: url(' + incorp?.imagemFundoUrl + ');' : ''">
      <div class="modal-dialog mt-0" role="document">
        <div class="modal-content">
          <div class="row">
            <div class="i-title col-12 text-left">INCORPORADORA {{ incorp?.nome?.toLocaleUpperCase() }}</div>
            <div class="i-subtitle col-12 text-left pt-3 px-lg-5">
              <div class="i-hr"></div>
              <span [innerHTML]="incorp?.textoLongo"></span>
              <div class="i-hr"></div>
            </div>
            <div class="px-2 px-lg-5">
              <button type="submit" class="i-btn" data-dismiss="modal">VOLTAR</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
