import { EventEmitter, Injectable } from '@angular/core';
import { FilteringCallback } from '../components/filtering/filtering.component';
import { Search } from '../models/search';
import { Imovel } from '../models/imovel';

@Injectable()
export class EventHandler {
  private readonly eventSearch: EventEmitter<Search> = new EventEmitter();
  private readonly eventPropertyView: EventEmitter<Imovel> = new EventEmitter();
  private readonly eventFavorite: EventEmitter<boolean> = new EventEmitter();
  private readonly eventDiscard: EventEmitter<boolean> = new EventEmitter();
  private readonly eventFilterClearing: EventEmitter<FilteringCallback> = new EventEmitter();

  constructor() {
    EventHandler.instance = this;
  }

  searchChanged(search: Search) {
    this.eventSearch.emit(search);
  }

  propertyViewChanged(property: Imovel) {
    this.eventPropertyView.emit(property);
  }

  favoriteChanged() {
    this.eventFavorite.emit();
  }

  discardChanged() {
    this.eventDiscard.emit();
  }

  filterClearing(callback: FilteringCallback) {
    this.eventFilterClearing.emit(callback);
  }

  getSearchEventEmitter(): EventEmitter<Search> {
    return this.eventSearch;
  }

  getPropertyViewEventEmitter(): EventEmitter<Imovel> {
    return this.eventPropertyView;
  }

  getFavoriteEventEmitter(): EventEmitter<boolean> {
    return this.eventFavorite;
  }

  getDiscardEventEmitter(): EventEmitter<boolean> {
    return this.eventDiscard;
  }

  getFilterClearingEventEmitter(): EventEmitter<FilteringCallback> {
    return this.eventFilterClearing;
  }

  // singleton
  static instance: EventHandler;
  static builder() {
    if (!EventHandler.instance) EventHandler.instance = new EventHandler();
    return EventHandler.instance;
  }
}
