<div
  class="i-main-call mt-3 py-5"
  [style]="
    'background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + '/assets/img/bg_bombando.jpg' + ');'
  ">
  <!-- [style]="'background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + (this.banner$ | async)?.arquivoUrl || '/assets/img/bg_bombando.jpg' + ');'"> -->
  <div class="container py-4 px-0">
    <div class="container" style="background-color: rgba(255, 255, 255, 0.35)">
      <!--Broker info-->
      <div class="i-broker d-flex flex-column flex-md-row align-items-center justify-content-center p-5">
        <div *ngIf="broker?.fotoUrl" class="mr-md-4">
          <img defaultImage="assets/img/img_loading.webp" [lazyLoad]="broker?.fotoUrl" [alt]="broker?.nome" />
        </div>
        <div class="text-center">
          <div class="i-subtitle pt-3 pb-2">
            {{ broker?.nome }}
            <span *ngIf="broker?.creci" data-toggle="tooltip" data-html="true" [title]="'CRECI ' + broker?.creci">
              <i class="iconify" data-icon="mdi-information-outline"></i>
            </span>
          </div>
          <div *ngIf="broker?.celular || broker?.fone" class="i-phone">
            <!-- <a
              href="https://api.whatsapp.com/send?phone=55{{ formatPhone(broker) }}&text="
              class="mx-2 i-btn-whatsapp"
              aria-label="Telefone corretor"
              target="_blank"
              (click)="onGaEvent('click', 'whatsapp', 'corretor')">
              <i class="iconify" data-icon="mdi-whatsapp"></i>
            </a> -->
            <app-corretor-whatsapp [iconOnly]="true" [propertyCode]="" [broker]="broker"></app-corretor-whatsapp>
            <a href="tel:{{ formatPhone(broker) }}" class="text-nowrap">
              {{ broker?.celular || broker?.fone }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <!--Results-->
  <div
    class="row"
    [ngClass]="{ 'i-results-list py-5': boxType !== BoxType.Grid, 'i-results-grid pt-5': boxType === BoxType.Grid }">
    <div
      *ngFor="
        let property of resultPage.items
          | paginate
            : {
                id: 'pagination',
                itemsPerPage: resultPage?.size,
                currentPage: search.pag,
                totalItems: resultPage?.total
              };
        let first = first
      "
      class="i-box col-12"
      [ngClass]="{ 'col-md-6 col-lg-4 mb-5': boxType === BoxType.Grid }">
      <app-box [property]="property" [targetBlank]="true"> </app-box>
    </div>
  </div>
  <!--Pagination-->
  <div class="i-pagination row justify-content-center flex-column pt-3 pb-5">
    <app-loading *ngIf="loading"></app-loading>
    <pagination-controls (pageChange)="onChangePage($event)" id="pagination" class="mx-auto" [directionLinks]="false">
    </pagination-controls>
  </div>
</div>
