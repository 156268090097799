import { Component } from '@angular/core';
import { NgxHmCarouselBreakPointUp } from 'ngx-hm-carousel';
import { SsrUtilService } from '../../util/ssr-util.service';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss'],
})
export class AwardsComponent {
  // configuração do ngx-hm-carousel
  currentIndex = 0;
  breakpoint: NgxHmCarouselBreakPointUp[] = [
    {
      width: 425,
      number: 2,
    },
    {
      width: 768,
      number: 3,
    },
    {
      width: 1024,
      number: 4,
    },
    {
      width: 1440,
      number: 5,
    },
    {
      width: 2560,
      number: 8,
    },
  ];
  // dados
  public awards = [
    {
      name: 'Campeã de vendas Melnick 2022',
      image: 'assets/img/premios/premio_00001.svg',
    },
    {
      name: 'Campeã Alto Padrão Melnick Day 2020',
      image: 'assets/img/premios/premio_00002.svg',
    },
    {
      name: '2º lugar em vendas Melnick Day 2022',
      image: 'assets/img/premios/premio_00003.svg',
    },
    {
      name: '3º lugar comercial Melnick Digital Days 2021',
      image: 'assets/img/premios/premio_00004.svg',
    },
    {
      name: 'Campeã de vendas Iguassu',
      image: 'assets/img/premios/premio_00005.svg',
    },
    {
      name: 'Campeã de vendas Orsay',
      image: 'assets/img/premios/premio_00006.svg',
    },
    {
      name: 'Maior VGV Rottaely 2021',
      image: 'assets/img/premios/premio_00007.svg',
    },
    {
      name: 'Campeã de vendas Pulse',
      image: 'assets/img/premios/premio_00008.svg',
    },
    {
      name: 'Campeã de vendas WOSS',
      image: 'assets/img/premios/premio_00009.svg',
    },
  ];

  constructor(readonly ssr: SsrUtilService) {}
}
