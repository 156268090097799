<!--Search-->
<form #f="ngForm">
  <!--Sticked-Search-Bar-->
  <div class="d-none py-2" id="stickBar">
    <div class="container d-none d-lg-flex">
      <div class="row justify-content-center align-items-center text-center">
        <div class="col-6 col-sm-8 col-md-6 col-lg-2 text-sm-left">
          <a href="#" routerLink="/home">
            <img src="assets/img/logo_cinza.svg" class="img-fluid my-auto pb-2" alt="Private" />
          </a>
        </div>
        <!-- cidades -->
        <div class="col-12 col-md-6 col-lg-2 pl-4">
          <app-select-cidade [(search)]="search"></app-select-cidade>
        </div>
        <!-- status -->
        <mat-form-field class="col-12 col-md-6 col-lg-2" floatLabel="never">
          <mat-select
            name="status"
            class="i-text"
            [(ngModel)]="search.status"
            (selectionChange)="onChangeStatus()"
            role="combobox">
            <mat-option value="0">COMPRAR</mat-option>
            <mat-option value="1">ALUGAR</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- tipo -->
        <div class="col-12 col-md-6 col-lg-2">
          <app-select-tipo-imovel [search]="search" (changeSearch)="onChangePropertyCount()"> </app-select-tipo-imovel>
        </div>
        <!-- bairros -->
        <div class="col-12 col-md-6 col-lg-2">
          <app-select-bairro
            uniqueId="-search-bar"
            [search]="search"
            [showModal]="false"
            (changeSearch)="onChangePropertyCount()"></app-select-bairro>
        </div>
        <div class="col-12 col-md-6 col-lg-2">
          <!--Button-->
          <button type="button" class="i-btn-stick" (click)="onSearch()">
            <i class="iconify pr-2" data-icon="mdi-magnify" title="Lupa" style="font-size: 2.5rem"></i>
            <ng-container *ngIf="enableCount"> / {{ propertyCount }} </ng-container>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--Default-bar-->
  <div *ngIf="opened" class="i-search-bar pt-3">
    <div class="row justify-content-center align-items-center text-center">
      <!-- cidades -->
      <div class="col-12 col-md-6 col-lg-4">
        <app-select-cidade [(search)]="search"></app-select-cidade>
      </div>
      <!-- status -->
      <mat-form-field class="col-12 col-md-6 col-lg-3" floatLabel="never">
        <mat-select
          name="status"
          class="i-text"
          [(ngModel)]="search.status"
          (selectionChange)="onChangeStatus()"
          role="combobox">
          <mat-option value="0">COMPRAR</mat-option>
          <mat-option value="1">ALUGAR</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- tipo -->
      <div class="col-12 col-md-6 col-lg-3">
        <app-select-tipo-imovel [search]="search" (changeSearch)="onChangePropertyCount()"> </app-select-tipo-imovel>
      </div>
      <!-- codigo -->
      <mat-form-field class="col-12 col-md-6 col-lg-2">
        <mat-label>CÓDIGO IMÓVEL</mat-label>
        <input
          type="tel"
          name="codigo"
          matInput
          class="pb-2"
          maxlength="10"
          autocomplete="off"
          [(ngModel)]="search.codigo"
          (blur)="onChangePropertyCount()"
          onkeydown="formatNumber(event, this);"
          (keyup.enter)="onSearch()" />
      </mat-form-field>
      <!-- bairros -->
      <div class="col-12 col-md-12 col-lg-4">
        <app-select-bairro
          uniqueId="-search"
          [search]="search"
          (changeSearch)="onChangePropertyCount()"></app-select-bairro>
      </div>
      <!-- construtoras -->
      <div class="col-12 col-md-6 col-lg-4">
        <app-select-construtora [(search)]="search" (changeSearch)="onChangePropertyCount()"></app-select-construtora>
      </div>
      <!-- empreendimentos -->
      <div class="col-12 col-md-6 col-lg-4">
        <app-select-empreendimento
          [(search)]="search"
          (changeSearch)="onChangePropertyCount()"></app-select-empreendimento>
      </div>
    </div>
    <!--Advanced Search-->
    <div *ngIf="enableAdvanced" class="i-search-advanced row pt-4 pt-lg-2">
      <div class="col-12 col-lg-3 d-flex flex-row">
        <!--Bedrooms-->
        <div class="i-advanced-box i-text text-left" style="width: 33%">
          <div class="pb-2">DORMIT.</div>
          <mat-selection-list
            name="dormitory"
            disableRipple
            [(ngModel)]="search.dormitorios"
            (selectionChange)="onChangePropertyCount()"
            role="checkbox">
            <mat-list-option [value]="1" checkboxPosition="before">01</mat-list-option>
            <mat-list-option [value]="2" checkboxPosition="before">02</mat-list-option>
            <mat-list-option [value]="3" checkboxPosition="before">03</mat-list-option>
            <mat-list-option [value]="4" checkboxPosition="before">04+</mat-list-option>
          </mat-selection-list>
        </div>
        <!--Suites-->
        <div class="i-advanced-box i-text text-left" style="width: 33%">
          <div class="pb-2">SUÍTES</div>
          <mat-selection-list
            name="suite"
            disableRipple
            [(ngModel)]="search.suites"
            (selectionChange)="onChangePropertyCount()"
            role="checkbox">
            <mat-list-option [value]="1" checkboxPosition="before">01</mat-list-option>
            <mat-list-option [value]="2" checkboxPosition="before">02</mat-list-option>
            <mat-list-option [value]="3" checkboxPosition="before">03</mat-list-option>
            <mat-list-option [value]="4" checkboxPosition="before">04+</mat-list-option>
          </mat-selection-list>
        </div>
        <!--Parkings-->
        <div class="i-advanced-box i-text text-left" style="width: 33%">
          <div class="pb-2">VAGAS</div>
          <mat-selection-list
            name="space"
            disableRipple
            [(ngModel)]="search.vagas"
            (selectionChange)="onChangePropertyCount()"
            role="checkbox">
            <mat-list-option [value]="1" checkboxPosition="before">01</mat-list-option>
            <mat-list-option [value]="2" checkboxPosition="before">02</mat-list-option>
            <mat-list-option [value]="3" checkboxPosition="before">03</mat-list-option>
            <mat-list-option [value]="4" checkboxPosition="before">04+</mat-list-option>
          </mat-selection-list>
        </div>
      </div>
      <!--Area-->
      <div class="i-advanced-box i-text col-12 col-lg-2 text-left">
        <div class="pb-2">ÁREA (m&#178;)</div>
        <div class="d-flex flex-column align-items-between pt-2">
          <mat-form-field class="mb-2 pr-2 pt-1" floatLabel="never" role="checkbox">
            <input
              name="areaMin"
              matInput
              type="tel"
              placeholder="MIN"
              maxlength="10"
              [matAutocomplete]="autoAreaMin"
              [(ngModel)]="search.areaMin"
              #triggerAutoAreaMin="matAutocompleteTrigger"
              (ngModelChange)="onFilterAreaMax()"
              (focus)="onChangePropertyCount()"
              (focusout)="onChangePropertyCount()"
              (keyup)="triggerAutoAreaMin.closePanel()"
              onkeydown="formatNumber(event, this);" />

            <mat-autocomplete #autoAreaMin="matAutocomplete">
              <mat-option *ngFor="let option of filteredAreaMin" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="mb-4 pr-2 pt-1" floatLabel="never">
            <input
              name="areaMax"
              matInput
              type="tel"
              placeholder="MAX"
              maxlength="10"
              [matAutocomplete]="autoAreaMax"
              [(ngModel)]="search.areaMax"
              #triggerAutoAreaMax="matAutocompleteTrigger"
              (ngModelChange)="onFilterAreaMin()"
              (focus)="onChangePropertyCount()"
              (focusout)="onChangePropertyCount()"
              (keyup)="triggerAutoAreaMax.closePanel()"
              onkeydown="formatNumber(event, this);" />

            <mat-autocomplete #autoAreaMax="matAutocomplete">
              <mat-option *ngFor="let option of filteredAreaMax; let lastPosition = last" [value]="option">
                {{ option }}{{ lastPosition ? ' +' : '' }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <!--Price-->
      <div class="i-advanced-box i-text col-12 col-lg-2 text-left">
        <div class="pb-2">VALOR</div>
        <div class="d-flex flex-column align-items-between pt-2">
          <mat-form-field class="mb-2 pr-2 pt-1" floatLabel="never">
            <span matPrefix>R$&nbsp;</span>
            <input
              name="valorMin"
              matInput
              type="tel"
              placeholder="MIN"
              [matAutocomplete]="autoPriceMin"
              [ngModel]="transformCurrency(search.valorMin)"
              #triggerAutoPriceMin="matAutocompleteTrigger"
              (ngModelChange)="search.valorMin = stripSpecialChars($event); onFilterPriceMax()"
              (focus)="onChangePropertyCount()"
              (focusout)="onChangePropertyCount()"
              onkeydown="formatNumber(event, this);"
              (keyup)="formatCurrencyValue($event); triggerAutoPriceMin.closePanel()" />

            <mat-autocomplete #autoPriceMin="matAutocomplete">
              <mat-option *ngFor="let option of filteredPriceMin" [value]="option | number">
                {{ option | number }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="mb-2 pr-2 pt-1" floatLabel="never">
            <span matPrefix>R$&nbsp;</span>
            <input
              name="valorMax"
              matInput
              type="tel"
              placeholder="MAX"
              [matAutocomplete]="autoPriceMax"
              [ngModel]="transformCurrency(search.valorMax)"
              #triggerAutoPriceMax="matAutocompleteTrigger"
              (ngModelChange)="search.valorMax = stripSpecialChars($event); onFilterPriceMin()"
              (focus)="onChangePropertyCount()"
              (focusout)="onChangePropertyCount()"
              onkeydown="formatNumber(event, this);"
              (keyup)="formatCurrencyValue($event); triggerAutoPriceMax.closePanel()" />

            <mat-autocomplete #autoPriceMax="matAutocomplete">
              <mat-option *ngFor="let option of filteredPriceMax; let lastPosition = last" [value]="option | number">
                {{ option | number }}{{ lastPosition ? ' +' : '' }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
      <!--Others-->
      <div class="i-advanced-box i-text col-12 col-lg-5 text-left pr-2">
        <div class="d-flex flex-column align-items-between i-exchange">
          <div class="row">
            <div class="col">
              <div class="pb-1">ESTÁGIO</div>
              <mat-selection-list
                name="construction"
                disableRipple
                [(ngModel)]="search.construcao"
                (selectionChange)="onChangePropertyCount()">
                <mat-list-option value="true" checkboxPosition="before"> LANÇAMENTO & OBRA </mat-list-option>
              </mat-selection-list>
              <mat-selection-list
                class="pt-0"
                name="ready"
                disableRipple
                [(ngModel)]="search.novo"
                (selectionChange)="onChangePropertyCount()">
                <mat-list-option value="true" checkboxPosition="before"> PRONTO NOVO </mat-list-option>
              </mat-selection-list>
              <mat-selection-list
                class="pt-0"
                name="used"
                disableRipple
                [(ngModel)]="search.usado"
                (selectionChange)="onChangePropertyCount()">
                <mat-list-option value="true" checkboxPosition="before"> PRONTO PARA MORAR </mat-list-option>
              </mat-selection-list>
              <!--
                    <mat-selection-list class="pt-0" name="launch" disableRipple
                        [(ngModel)]="search.lancamento" (selectionChange)="onChangePropertyCount()">
                        <mat-list-option value="true" checkboxPosition='before'>
                            LANÇAMENTO
                        </mat-list-option>
                    </mat-selection-list>
                  -->
              <mat-selection-list
                class="pt-0"
                name="remodeling"
                disableRipple
                [(ngModel)]="search.reforma"
                (selectionChange)="onChangePropertyCount()">
                <mat-list-option value="true" checkboxPosition="before"> PARA REFORMA </mat-list-option>
              </mat-selection-list>
            </div>
            <div class="col">
              <div class="pb-1">OPORTUNIDADE</div>
              <!-- <mat-selection-list
                    name="discount"
                    disableRipple
                    [(ngModel)]="search.desconto"
                    (selectionChange)="onChangePropertyCount()">
                    <mat-list-option value="true" checkboxPosition="before"> COM DESCONTO </mat-list-option>
                  </mat-selection-list>
                  <mat-selection-list
                    class="pt-0"
                    name="exchange"
                    disableRipple
                    [(ngModel)]="search.permuta"
                    (selectionChange)="onChangePropertyCount()">
                    <mat-list-option value="true" checkboxPosition="before"> ACEITA IMÓVEL </mat-list-option>
                  </mat-selection-list>
                  <mat-selection-list
                    class="pt-0"
                    name="exclusive"
                    disableRipple
                    [(ngModel)]="search.exclusivo"
                    (selectionChange)="onChangePropertyCount()">
                    <mat-list-option value="true" checkboxPosition="before"> EXCLUSIVIDADE </mat-list-option>
                  </mat-selection-list>
                  <mat-selection-list
                    class="pt-0"
                    name="spotlight"
                    disableRipple
                    [(ngModel)]="search.destaque"
                    (selectionChange)="onChangePropertyCount()">
                    <mat-list-option value="true" checkboxPosition="before"> DESTAQUE </mat-list-option>
                  </mat-selection-list>
                  <mat-selection-list
                    class="pt-0"
                    name="ballTime"
                    disableRipple
                    [(ngModel)]="search.bolaVez"
                    (selectionChange)="onChangePropertyCount()">
                    <mat-list-option value="true" checkboxPosition="before"> BOLA DA VEZ </mat-list-option>
                  </mat-selection-list> -->
              <!--
                    <mat-selection-list *ngIf="!search?.isSP" class="pt-0" name="etiquetaLaranja"
                        disableRipple [(ngModel)]="search.etiquetaLaranja"
                        (selectionChange)="onChangePropertyCount()">
                        <mat-list-option value="true" checkboxPosition='before'>
                            <div>
                                <img src="assets/img/logo-etiqueta-laranja.png" title="Etiqueta Laranja"
                                    alt="Etiqueta Laranja" style="height: 1.6rem;" />
                            </div>
                        </mat-list-option>
                    </mat-selection-list>
                  -->
              <!--
                    <mat-selection-list class="pt-0" name="furnished" disableRipple
                        [(ngModel)]="search.mobiliado" (selectionChange)="onChangePropertyCount()">
                        <mat-list-option value="true" checkboxPosition='before'>
                            MOBILIADO
                        </mat-list-option>
                    </mat-selection-list>
                  -->
              <mat-selection-list
                name="furnished"
                disableRipple
                [(ngModel)]="search.mobiliado"
                (selectionChange)="onChangePropertyCount()">
                <mat-list-option value="true" checkboxPosition="before"> MOBILIADO </mat-list-option>
              </mat-selection-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 offset-lg-7 col-lg-5 p-0 pb-4">
      <!--Button-->
      <button id="btnSearch" type="button" class="i-btn i-btn-cta" (click)="onSearch()">
        VER RESULTADOS
        <ng-container *ngIf="enableCount"> / {{ propertyCount }} </ng-container>
      </button>
    </div>
  </div>
</form>
<!-- Filtrando por -->
<div *ngIf="showFiltering" class="d-flex">
  <div class="pt-1">
    <app-filtering [forceShow]="true" [search]="search" (clear)="onClearFilter($event)" class="mb-3" theme="gray">
    </app-filtering>
  </div>
  <div class="pl-1">
    <button
      type="button"
      class="i-btn-filter i-btn-inverted px-2 py-1"
      aria-label="AJUSTAR FILTROS"
      (click)="opened = !opened">
      <i class="iconify" data-icon="mdi-tune-variant"></i>
      {{ opened ? 'OCULTAR' : 'AJUSTAR' }} FILTROS
    </button>
  </div>
</div>
<!-- <code>{{ search | json }}</code> -->
