import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

export class MetaSchemaOrganization {
  '@context'?: string;
  '@type'?: string;
  name: string;
  url: string;
  email?: string;
  logo?: MetaSchemaOrganizationLogo;
  address: MetaSchemaOrganizationAddress;
}

export class MetaSchemaOrganizationLogo {
  '@type'?: string;
  url: string;
  width: number;
  height: number;
}

export class MetaSchemaOrganizationAddress {
  '@type'?: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  streetAddress: string;
}

export class MetaSchemaArticle {
  '@context'?: string;
  '@type'?: string;
  headline: string;
  image: string[];
  datePublished?: string;
  dateModified?: string;
}

@Injectable({
  providedIn: 'root',
})
export class MetaSchemaOrganizationService {
  organization: MetaSchemaOrganization;
  article: MetaSchemaArticle;

  constructor(@Inject(DOCUMENT) private doc) // private ssr: SsrUtilService
  {}

  addOrganization(org: MetaSchemaOrganization): MetaSchemaOrganizationService {
    this.organization = org;
    return this;
  }

  addArticle(article: MetaSchemaArticle): MetaSchemaOrganizationService {
    this.article = article;
    return this;
  }

  private renderScript(id: string, content: string) {
    // remove anterior
    const tag = this.doc.getElementById(id);
    if (tag) tag.remove();

    // adiciona
    let script = this.doc.createElement('script');
    script.id = id;
    script.type = 'application/ld+json';
    script.textContent = content;
    this.doc.head.appendChild(script);
  }

  render() {
    if (this.organization) {
      // verifica se os obrigatórios estão presentes
      if (!this.organization['@context']) this.organization['@context'] = 'https://schema.org';
      if (!this.organization['@type']) this.organization['@type'] = 'Organization';
      if (this.organization.logo && !this.organization.logo['@type']) this.organization.logo['@type'] = 'ImageObject';
      if (this.organization.address && !this.organization.address['@type'])
        this.organization.address['@type'] = 'PostalAddress';

      this.renderScript('organizationJson', JSON.stringify(this.organization));
    }

    if (this.article) {
      // verifica se os obrigatórios estão presentes
      if (!this.article['@context']) this.article['@context'] = 'https://schema.org';
      if (!this.article['@type']) this.article['@type'] = 'NewsArticle';

      this.renderScript('articleJson', JSON.stringify(this.article));
    }
  }
}
