<div class="row justify-content-center m-4 pb-5">
  <div class="card shadow" style="border-width: 3px">
    <div class="card-body">
      <h5 class="card-title text-center">Não encontrou o imóvel que procura?</h5>
      <p class="card-text">Encomende seu imóvel conosco para você não perder tempo!</p>
      <div class="d-flex flex-column flex-md-row justify-content-around mt-3">
        <a id="i-btn-encomende" data-toggle="modal" data-target="#modal_encomende" class="i-btn i-btn-cta text-center"
          >Encomende seu imóvel aqui</a
        >
      </div>
    </div>
  </div>
</div>
<!-- modal encomende seu imóvel -->
<div class="modal fade" id="modal_encomende" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog" role="document">
    <form (ngSubmit)="onSend()" [formGroup]="fmGroup">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="i-title text-center mt-2 w-100">ENCOMENDE SEU IMÓVEL</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <picture>
              <source type="image/webp" srcset="assets/img/btn_fechar_b.webp" />
              <img src="assets/img/btn_fechar_b.png" style="width: 17px; height: 16px" alt="Fechar" />
            </picture>
          </button>
        </div>

        <div class="modal-body row px-1 px-lg-4">
          <div class="d-flex flex-column flex-wrap i-text text-left px-4 py-3">
            <div class="mb-4" style="font-size: 110%">
              Ao encomendar seu imóvel na Private, colocamos nosso time de especialistas para auxiliá-lo.<br /><br />
              Preencha e confirme os dados de contato abaixo, se desejares, descreva com mais detalhes o que procura que
              encontraremos para você.
            </div>
            <app-filtering
              title="SUA BUSCA FOI:"
              [showClear]="false"
              [forceShow]="true"
              [search]="search"
              class="mb-3"
              theme="gray">
            </app-filtering>
            <div class="form-row px-2 px-lg-5">
              <mat-form-field class="col-12 mx-auto mb-3 i-text" appearance="fill">
                <mat-label class="i-text">SEU NOME</mat-label>
                <input
                  matInput
                  id="form-order-name"
                  type="text"
                  class="i-text pb-1"
                  maxlength="255"
                  formControlName="name" />
                <mat-error class="i-text-sm mt-1" *ngIf="fmGroup.get('name').errors?.required">
                  CAMPO OBRIGATÓRIO
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-12 mx-auto mb-3 mt-1" appearance="fill">
                <mat-label class="i-text">SEU TELEFONE</mat-label>
                <input
                  matInput
                  id="form-order-phone"
                  type="text"
                  class="i-text pb-2"
                  placeholder="(00) 0000-00000"
                  mask="(00) 0000-0000||(00) 00000-0000"
                  minlength="14"
                  maxlength="15"
                  formControlName="phone" />
                <mat-error
                  class="i-text-sm mt-1"
                  *ngIf="fmGroup.get('phone').errors?.minlength && !fmGroup.get('phone').errors?.required">
                  POR FAVOR, INFORME NO MÍNIMO 14 DÍGITOS
                </mat-error>
                <mat-error class="i-text-sm mt-1" *ngIf="fmGroup.get('phone').errors?.phoneSintaxError">
                  POR FAVOR, VERIFIQUE O NÚMERO DIGITADO
                </mat-error>
                <mat-error class="i-text-sm mt-1" *ngIf="fmGroup.get('phone').errors?.required">
                  CAMPO OBRIGATÓRIO
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-12 mx-auto mb-3 mt-1" appearance="fill">
                <mat-label class="i-text">SEU E-MAIL</mat-label>
                <input
                  matInput
                  id="form-order-email"
                  type="email"
                  class="i-text pb-2"
                  maxlength="255"
                  formControlName="email" />
                <mat-error
                  class="i-text-sm mt-1"
                  *ngIf="fmGroup.get('email').errors?.email && !fmGroup.get('email').errors?.required">
                  POR FAVOR, INFORME UM E-MAIL VÁLIDO
                </mat-error>
                <mat-error class="i-text-sm mt-1" *ngIf="fmGroup.get('email').errors?.required">
                  CAMPO OBRIGATÓRIO
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-12 mx-auto mb-4 mt-1" appearance="fill">
                <mat-label class="i-text">DESCREVA SEU IMÓVEL IDEAL</mat-label>
                <textarea
                  matInput
                  id="form-order-message"
                  type="text"
                  class="i-text pb-2"
                  #input
                  maxlength="500"
                  rows="5"
                  formControlName="message"></textarea>
                <mat-hint align="end">{{ input?.value?.length || 0 }}/500</mat-hint>
                <!-- <mat-error class="i-text-sm mt-1" *ngIf="fmGroup.get('message').errors?.required">
                                    CAMPO OBRIGATÓRIO
                                </mat-error> -->
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center bg-white">
          <!-- data-dismiss="modal" aria-label="Close" -->
          <button id="form-order-submit" type="submit" class="i-btn">ENCOMENDAR</button>
        </div>
      </div>
    </form>
  </div>
</div>
