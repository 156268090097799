import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'html' })
export class HTMLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(v: any, type: string = 'html'): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if (!v) return v;

    switch (type) {
      case 'html': {
        if (v.indexOf('<br') <= 0) v = v.replace(/\r\n/g, '<br/>');
        return this.sanitizer.bypassSecurityTrustHtml(v);
      }
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(v);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(v);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(v);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(v);
      default:
        throw new Error(`Tipo inválido: ${type}`);
    }
  }
}
