export class Corretor {
  id: string;
  codigo: string;
  nome: string;
  creci: string;
  creciSlug: string;
  celular: string;
  fone: string;
  fotoUrl: string;
  slug: string;

  constructor(values?: any) {
    if (values) Object.assign(this, values);
  }

  public get url(): string {
    return `/corretor/${this.slug}/imoveis`;
  }
}
