import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExclusivePageComponent } from './components/page/exclusive-page.component';

const routes: Routes = [
  // conforme solicitado
  { path: 'imoveis/exclusividades', component: ExclusivePageComponent },
  { path: 'imoveis/exclusividades/:cidade', component: ExclusivePageComponent },
  // redundância (em breve tirar o singular)
  { path: 'imoveis/exclusividade', component: ExclusivePageComponent },
  { path: 'imoveis/exclusividade/:cidade', component: ExclusivePageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExclusiveRoutingModule {}
