export class Banner {
  arquivoUrl: string;
  infoTexto: string;
  infoUrl: string;

  constructor(values?: any) {
    if (values) Object.assign(this, values);
  }

  public get hasInfo(): boolean {
    return this.infoTexto != undefined;
  }

  public get infoTitle(): string {
    return (
      '' +
      this.infoTexto +
      (this.infoUrl
        ? '<br/><a href="' + this.infoUrl + '" class="btn-link" target="_blank">' + this.infoUrl + '</a>'
        : '')
    );
  }
}
