<ng-container *ngIf="this.incorporadora$ | async as incorp">
  <div
    class="i-main-call d-flex align-items-center mt-3"
    [style]="
      'background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' + incorp?.imagemFundoUrl + ');'
    ">
    <div class="container px-0">
      <div class="col-12 text-left px-2 px-lg-5">
        INCORPORADORA<br />
        {{ this.incorp?.nome?.toLocaleUpperCase() }}
      </div>
      <div class="i-subtitle col-12 col-md-7 text-left px-2 px-lg-5">
        {{ this.incorp?.textoCurto }}
        <br />
        <a *ngIf="isBanner" [routerLink]="['/incorporadora', incorp.firstSlug]" class="btn btn-outline-light mt-4"
          >CONHEÇA OS IMÓVEIS</a
        >
        <a *ngIf="!isBanner" data-toggle="modal" data-target="#modal_incorp" class="btn btn-outline-light mt-4"
          >CONHEÇA MAIS</a
        >
      </div>
    </div>
  </div>
</ng-container>
