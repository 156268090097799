import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Search } from 'src/app/shared/models/search';
import { SearchItem } from 'src/app/shared/models/search-item';
import { SateliteService } from 'src/app/shared/services/satelite.service';
import { SessionManager } from 'src/app/shared/util/session-manager';

@Component({
  selector: 'app-select-empreendimento',
  templateUrl: './select-empreendimento.component.html',
  styleUrls: ['./select-empreendimento.component.scss'],
})
export class SelectEmpreendimentoComponent implements OnInit {
  enterprises: SearchItem[];
  filteredEnterprises: SearchItem[];
  @Input() search: Search;
  @Output() changeSearch = new EventEmitter<Search>(true);

  constructor(
    private cd: ChangeDetectorRef,
    private sateliteService: SateliteService) {}

  ngOnInit(): void {
    this.sateliteService.getEmpreendimentos(this.search.cidade, this.search.status).subscribe(
      result => {
        this.enterprises = result;
        this.filteredEnterprises = Object.assign([], result); // copy
      },
      error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar empreendimentos');
      }
    );
  }

  /**
   * Ajusta filtro dos empreendimentos.
   *
   * @param value
   */
  onFilterEnterprises(value: string) {
    if (value) {
      this.filteredEnterprises = this.enterprises.filter(
        item => item?.nome?.toLowerCase().indexOf(value.toLowerCase()) >= 0
      );
    } else this.filteredEnterprises = Object.assign([], this.enterprises);
  }

  /**
   * Limpa filtro de empreendimentos.
   */
  onClearEnterprises() {
    this.search.empreendimentos = [];

    this.cd.detectChanges();
    this.changeSearch.emit(this.search);
  }
}
