import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { SessionManager } from 'src/app/shared/util/session-manager';

@Component({
  selector: 'app-phones',
  templateUrl: './phones.component.html',
  styleUrls: ['./phones.component.scss'],
})
export class PhonesComponent implements OnInit {
  rsPhone: boolean = true;
  spPhone: boolean = false;

  constructor(
    private ga: AnalyticsService,
    private sm: SessionManager
  ) {}

  ngOnInit(): void {
    this.checkCity(this.sm.getSearch()?.cidade);
  }

  checkCity(city: string) {
    if (this.sm.isRegionDefined()) {
      this.rsPhone = city != 'sao-paulo';
      this.spPhone = city == 'sao-paulo';
    } else this.rsPhone = this.spPhone = true;
  }

  onGaEvent(action: string, category: string, label: string, value: number = undefined) {
    this.ga.trackEvent(action, category, label, value);
  }
}
