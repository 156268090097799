<div class="i-page-top">
  <div class="container">
    <!--Menu-->
    <div class="i-menu row align-items-center pt-3 pb-2">
      <div class="navbar-brand col-6 col-sm-8 col-md-6 col-lg-4 mr-auto py-2 px-0 text-center text-sm-left">
        <a href="#" routerLink="/home" aria-label="Private"
          ><img src="assets/img/logo_cinza.svg" class="img-fluid my-auto" alt="Private"
        /></a>
      </div>
      <div class="col-6 col-sm-4 col-md-6 col-lg-8 ml-auto py-2">
        <app-menu></app-menu>
      </div>
    </div>
  </div>
</div>
<div class="i-page-content">
  <!--Call-Text-->
  <div
    class="i-main-call mt-3 py-5"
    [style]="
      'background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' +
      (this.banner$ | async)?.arquivoUrl +
      ');'
    ">
    <div class="container py-4 px-0">
      <div class="col-12 text-left px-2 px-lg-5 py-5">EXCLUSIVIDADES<br /><br />PRIVATE</div>
    </div>
  </div>
  <div class="i-favorite container py-5">
    <div class="row text-center py-5">
      <h1 class="i-title mx-auto">
        EXCLUSIVIDADES PRIVATE
        <ng-container *ngIf="search?.cidadeNome">
          <br />
          <div class="mt-4">
            {{ search?.cidadeNome }}
          </div>
        </ng-container>
      </h1>
    </div>
    <!-- BUSCA -->
    <div class="row justify-content-between pb-5">
      <app-form-busca
        [(search)]="search"
        (changeSearch)="onChangeRoute($event)"
        [enableSticky]="true"
        [enableAdvanced]="false"></app-form-busca>
    </div>
    <!-- -->
    <ng-container *ngIf="this.imoveis$ | async as resultPage">
      <ng-container *ngIf="resultPage?.items?.length > 0; else empty">
        <!-- <div class="row row-cols-1 row-cols-md-3 no-gutters"> -->
        <div class="row no-gutters">
          <div
            *ngFor="
              let property of resultPage.items
                | paginate
                  : {
                      id: 'pagination',
                      itemsPerPage: resultPage?.size,
                      currentPage: search.pag,
                      totalItems: resultPage?.total
                    }
            "
            class="col-12 p-4">
            <app-box [property]="property" [boxType]="BoxType.Detail" [showMoreLessButton]="false"></app-box>
          </div>
        </div>
        <!--Pagination-->
        <div class="i-pagination row justify-content-center flex-column pt-3 pb-5">
          <pagination-controls
            (pageChange)="onChangePage($event)"
            id="pagination"
            class="mx-auto"
            [directionLinks]="false">
          </pagination-controls>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #empty>
      <div class="col i-text text-center my-3 py-5">
        <!-- SEM REGISTROS -->
        <h2 class="mb-2">Não há imóveis em exclusividade nesta cidade.</h2>
        Vá para busca e encontre o imóvei ideal para você.

        <div class="mx-auto col-12 col-lg-8">
          <div class="d-flex flex-column flex-md-row justify-content-around mt-5 mb-5">
            <a href="#" routerLink="/imoveis/exclusividades/porto-alegre" class="i-btn i-btn-inverted"
              >Exclusividades em POA</a
            >
            <a href="#" routerLink="/imoveis/exclusividades/sao-paulo" class="i-btn i-btn-inverted"
              >Exclusividades em SP</a
            >
          </div>
        </div>

        <div class="d-block mt-5">
          <a href="#" routerLink="/imoveis/status/venda" class="i-btn">Buscar imóveis</a>
        </div>
      </div>
    </ng-template>
  </div>
</div>
