<mat-form-field class="w-100" floatLabel="never">
  <mat-select
    #selectEnterprises
    name="empreendimentos"
    placeholder="EMPREENDIMENTOS"
    multiple
    class="i-text i-closable-select"
    [(ngModel)]="search.empreendimentos"
    role="combobox">
    <mat-select-trigger>
      EMPREENDIMENTOS
      <span *ngIf="search?.empreendimentos?.length > 0"> (+{{ search?.empreendimentos?.length }}) </span>
    </mat-select-trigger>
    <div class="d-flex justify-content-end">
      <button
        disableRipple
        class="pr-5 pr-sm-3 pt-2"
        (click)="selectEnterprises.close()"
        style="background-color: transparent">
        <picture>
          <source type="image/webp" srcset="assets/img/btn_fechar_b.webp" />
          <img src="assets/img/btn_fechar_b.png" style="width: 17px; height: 16px" alt="Fechar" />
        </picture>
      </button>
    </div>
    <div class="py-3">
      <div class="input-group w-100">
        <input
          #filtroEmpreendimentos
          name="filtroEmpreendimentos"
          class="form-control i-input-filter pb-2"
          placeholder="FILTRAR EMPREENDIMENTOS..."
          autocomplete="off"
          (input)="onFilterEnterprises(filtroEmpreendimentos.value)" />
        <div *ngIf="search?.empreendimentos?.length > 0" class="input-group-append">
          <button disableRipple class="i-btn" (click)="onClearEnterprises()">
            <i class="iconify" data-icon="mdi-trash-can"></i>
          </button>
        </div>
      </div>
    </div>
    <mat-option *ngFor="let enterprise of filteredEnterprises" [value]="enterprise.slug">
      {{ enterprise?.nome }}
    </mat-option>
  </mat-select>
</mat-form-field>
