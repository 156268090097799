import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyOptionSelectionChange as MatOptionSelectionChange } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Category } from 'src/app/shared/models/category';
import { Search } from 'src/app/shared/models/search';
import { SateliteService } from 'src/app/shared/services/satelite.service';
import { SessionManager } from 'src/app/shared/util/session-manager';

@Component({
  selector: 'app-select-tipo-imovel',
  templateUrl: './select-tipo-imovel.component.html',
  styleUrls: ['./select-tipo-imovel.component.scss'],
})
export class SelectTipoImovelComponent implements OnInit {
  categories: Category;
  @ViewChild('selectType') selectType: MatSelect;
  @Input() search: Search;
  @Output() changeSearch = new EventEmitter<Search>(true);

  constructor(
    private cd: ChangeDetectorRef,
    private sateliteService: SateliteService
  ) {}

  ngOnInit(): void {
    this.sateliteService.getCategorias().subscribe(
      result => (this.categories = result),
      error => console.log(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar categorias.'))
    );
  }

  onItemsChecked(b: boolean, group: string) {
    if (!this.search.categorias) return;

    let lst: string[];
    // pega lista
    if (group == 'residencial') lst = this.categories?.residencial?.map(x => x.slug);
    if (group == 'comercial') lst = this.categories?.comercial?.map(x => x.slug);
    if (lst?.length > 0) {
      // sempre limpa tudo
      this.search.categorias = this.search.categorias.filter(x => !lst.includes(x));
      // adiciona lista, se necessário
      if (b) this.search.categorias = this.search.categorias.concat(lst);
    }

    // this.selectType.value = this.search.categorias;
    this.cd.detectChanges();
    this.changeSearch?.emit(this.search);
  }

  onItemChecked(ev: MatOptionSelectionChange, slug: string) {
    if (ev?.isUserInput && ev?.source?.selected && slug == 'apartamento') {
      if (!this.search?.categorias?.includes('apartamento-duplex')) this.search?.categorias?.push('apartamento-duplex');
      if (!this.search?.categorias?.includes('apartamento-garden')) this.search?.categorias?.push('apartamento-garden');
      if (!this.search?.categorias?.includes('cobertura')) this.search?.categorias?.push('cobertura');

      // atualiza lista na tela
      this.selectType.value = this.search.categorias;
      this.cd.detectChanges();
      this.changeSearch?.emit(this.search);
    }
  }
}
