<mat-form-field class="w-100" floatLabel="never">
  <mat-label>
    BAIRROS
    <span *ngIf="search?.bairros?.length > 0"> (+{{ search?.bairros?.length }}) </span>
  </mat-label>
  <ng-container *ngIf="showModal; else localSelect">
    <mat-select value="BAIRROS" data-toggle="modal" [attr.data-target]="'#neighborModal' + uniqueId" role="combobox">
    </mat-select>
  </ng-container>
  <ng-template #localSelect>
    <mat-select
      #selectBairros
      name="bairro"
      placeholder="BAIRROS"
      multiple
      class="i-text"
      [(ngModel)]="search.bairros"
      (selectionChange)="changeSearch?.emit(search)"
      role="combobox">
      <mat-select-trigger>
        BAIRROS
        <span *ngIf="search?.bairros?.length > 0"> (+{{ search?.bairros?.length }}) </span>
      </mat-select-trigger>
      <div class="d-flex justify-content-end">
        <button
          disableRipple
          class="close pr-5 pr-sm-3 pt-2"
          (click)="selectBairros.close()"
          style="background-color: transparent"
          role="button">
          <picture>
            <source type="image/webp" srcset="assets/img/btn_fechar_b.webp" />
            <img src="assets/img/btn_fechar_b.png" width="17px" height="16px" alt="Fechar" />
          </picture>
        </button>
      </div>
      <ng-container *ngFor="let g of grupoBairros">
        <mat-checkbox disableRipple (change)="onItemsChecked($event?.checked, 'principais')">
          {{ g.grupo }}
        </mat-checkbox>
        <mat-option *ngFor="let main of g.bairros" [value]="main.slug" role="option">
          {{ main?.nome | uppercase }}
        </mat-option>
      </ng-container>
      <div class="d-flex justify-content-center">
        <button type="button" class="i-btn mr-2 my-2" (click)="selectBairros.close()" role="button">OK</button>
      </div>
    </mat-select>
  </ng-template>
</mat-form-field>
<!-- modal -->
<div
  class="modal fade"
  [id]="'neighborModal' + uniqueId"
  tabindex="-1"
  role="dialog"
  [attr.aria-labelledby]="'neighborModal' + uniqueId"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-center pt-4 px-1 px-lg-4">
        <div class="d-flex flex-column mx-auto pl-3 pt-3">
          <span class="i-title text-center pt-2">{{ search?.cidadeNome }}</span>
          <span class="i-text-sm text-center pt-2">SELECIONE OS BAIRROS DESEJADOS</span>
        </div>
        <div class="d-flex my-auto px-3">
          <button type="button" class="close text-right" data-dismiss="modal" aria-label="Close">
            <picture>
              <source type="image/webp" srcset="assets/img/btn_fechar_b.webp" />
              <img src="assets/img/btn_fechar_b.png" style="width: 17px; height: 16px" alt="Fechar" />
            </picture>
          </button>
        </div>
      </div>
      <div class="modal-body row px-1 px-lg-4">
        <div class="d-flex flex-column flex-wrap i-text px-4 py-3">
          <mat-selection-list
            #selectNeighborhood
            name="neighborhood"
            disableRipple
            (selectionChange)="onSelected(selectNeighborhood)"
            role="combobox">
            <ng-container *ngFor="let g of grupoBairros">
              <div class="pb-2 text-left clearfix">
                <mat-checkbox
                  #mainNeighborhood
                  matSubheader
                  disableRipple
                  class="mt-0 pl-0"
                  (change)="onItemsChecked($event?.checked, g.grupo)">
                  {{ g.grupo }}
                </mat-checkbox>
                <div class="clearfix"></div>
                <mat-list-option
                  *ngFor="let main of g.bairros"
                  class="pl-3 text-left"
                  [selected]="isItemSelected(main.slug)"
                  [value]="main.slug"
                  checkboxPosition="before">
                  {{ main.nome | uppercase }}
                </mat-list-option>
              </div>
            </ng-container>
          </mat-selection-list>
        </div>
      </div>
      <div class="modal-footer justify-content-center bg-white">
        <button type="button" class="i-btn" data-dismiss="modal" aria-label="Close">OK</button>
      </div>
    </div>
  </div>
</div>
