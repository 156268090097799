import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, merge, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BoxType } from 'src/app/shared/components/box/box.component';
import { Imovel } from 'src/app/shared/models/imovel';
import { Search, SearchOrderEnum } from 'src/app/shared/models/search';
import { ResultPage } from 'src/app/shared/models/search-result';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { AnalyticsService } from 'src/app/shared/util/analytics.service';
import { EventHandler } from 'src/app/shared/util/handler.event';
import { SeoService } from 'src/app/shared/util/seo.service';
import { PropertyViewType, SessionManager } from 'src/app/shared/util/session-manager';
import { SsrUtilService } from 'src/app/shared/util/ssr-util.service';
import { PAGE_TITLE, iScrollTo, stripSpecialChars, transformCurrency } from 'src/globals';

declare let $: any; // jquery

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  transformCurrency = transformCurrency;
  stripSpecialChars = stripSpecialChars;
  BoxType = BoxType;
  PropertyViewType = PropertyViewType;
  //
  search = new Search();
  resultPage = new ResultPage<Imovel>(); // resultado da busca dos imóveis
  countFavorites$: Observable<number>;
  scrollEvent = new Event('scroll');
  // paginação
  loading: boolean;
  // ordenação
  orderName: string = 'ORDENAR POR';
  runSearchOnQueryParam = false;

  constructor(
    private ssr: SsrUtilService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    private propertyService: ImovelService,
    private seo: SeoService,
    private ga: AnalyticsService,
    private sm: SessionManager,
    @Inject(DOCUMENT) document
  ) {
    this.search.bairros = [];
  }

  ngOnInit(): void {
    // Carrega do sessionData os parâmetros da busca
    this.search = this.sm.getSearch();
    // Carrega do sessionData a configuração do tipo de view
    const { viewType } = this.sm.getSessionData();
    this.search.boxType = !viewType || viewType == PropertyViewType.GRID ? BoxType.Grid : BoxType.List;

    // Observa as alterações nos params
    this.route.queryParams.subscribe(params => {
      this.search.fromQuerySearch(params);
      this.checkParamsRoute(params, this.runSearchOnQueryParam);
    });

    // Observa as alterações na rota
    this.route.params.subscribe(params => {
      const { status, cidade } = params;
      if (status) this.search.status = status === 'venda' ? '0' : '1';
      if (cidade) this.search.cidade = cidade;
      else {
        if (!this.search.cidade) this.search.cidade = 'porto-alegre'; // Define a cidade default
        this.onChangeRoute();
      }
    });

    // Carrega e aguarda evento sobre os favoritos
    this.countFavorites$ = merge(EventHandler.builder().getFavoriteEventEmitter(), of(null)).pipe(
      switchMap(() => of(this.sm.totalFavorites())),
      tap(() => {
        if (this.ssr?.isBrowser)
          $(function () {
            // tem que ser jquery
            $('#countFavorites').tooltip('dispose').tooltip();
          });
      })
    );

    // habilita o query param subscribe a consultar qdo mudar a rota
    this.runSearchOnQueryParam = true;
    this.doSearch();
  }

  // Show the sticked Search Bar
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (this.ssr?.isBrowser)
      if (window.pageYOffset > 550) {
        let element = document.getElementById('stickBar');
        element.classList.add('i-stick-bar', 'i-search-bar');
        element.classList.remove('d-none');
      } else {
        let element = document.getElementById('stickBar');
        element.classList.remove('i-stick-bar', 'i-search-bar');
        element.classList.add('d-none');
      }
  }

  checkParamsRoute(params: Params, searchAfter = false) {
    const { gclid, utm_source, utm_medium, utm_campaign } = params;

    // se proveniente de campanhas, resetar a busca
    if (gclid || utm_source || utm_medium || utm_campaign) {
      console.log('Proveniente de campanha.');
      // this.search = this.sm.clearSearch();
      // EventHandler.instance.searchChanged(this.search);
    }

    // ordem
    const o = this.search.ordem;
    // if (o == 'menor-area')
    if (o == SearchOrderEnum.MenorArea) this.orderName = 'MENOR ÁREA';
    // else if (o == 'maior-area')
    else if (o == SearchOrderEnum.MaiorArea) this.orderName = 'MAIOR ÁREA';
    // else if (o == 'menor-valor')
    else if (o == SearchOrderEnum.MenorValor) this.orderName = 'MENOR VALOR';
    // else if (o == 'maior-valor')
    else if (o == SearchOrderEnum.MaiorValor) this.orderName = 'MAIOR VALOR';
    // else if (o == 'recencia')
    else if (o == SearchOrderEnum.Recencia) this.orderName = 'RECENTES';
    else this.orderName = 'DESTAQUE';

    if (searchAfter) this.doSearch();
  }

  updateSEO() {
    // status
    let status = this.search?.status == '0' ? 'Comprar' : 'Alugar';
    let title = status;
    // category
    if (this.search?.categorias?.length || 0 <= 10) {
      title += ` ${this.search?.categorias.join(', ').replace(/,([^,]*)$/, ' e$1')} `;
    } else title += ` imóveis `;
    // city name
    let cityName = this.search?.cidadeNome;
    if (cityName?.length > 0) title += ` em ${cityName} `;
    // title
    this.titleService.setTitle(`${title} - ${PAGE_TITLE}`);

    // Adiciona/Atualiza as Tags ao head da pagina
    this.seo
      .reset()
      .addTitle(this.titleService.getTitle())
      // .addDescription(`Veja agora ${this.resultPage?.total || ''} imóveis encontrados para ${status?.toUpperCase()} em ${cityName?.toUpperCase()}`)
      .addUrl(this.ssr?.getLocation().href);
    // description condicional
    if (this.search?.status == '0')
      if (this.search?.cidade == 'sao-paulo')
        this.seo?.addDescription(
          `Procurando por imóveis de alto padrão em São Paulo? Entre aqui no nosso site e veja todas as opções que temos para você. Os melhores imóveis nas melhores localizações você só encontra na Private Imóveis`
        );
      else
        this.seo?.addDescription(
          `Procurando por imóveis em ${cityName}? Veja as opções que separamos! Os melhores imóveis de alto padrão você só encontra na Private Imóveis.`
        );
    else
      this.seo?.addDescription(
        `Procurando imóveis de alto padrão para alugar em ${cityName}? A Private Imóveis tem os melhores imóveis disponíveis. Entre aqui e veja o que separamos para você!`
      );
  }

  onChangeRoute(s: Search = this.search) {
    this.search = s;
    // monta params
    const params = this.search?.toQuerySearch();

    // muda url
    this.router.navigate(
      ['/imoveis/status', this.search?.status === '0' ? 'venda' : 'alugar', 'cidade', this.search?.cidade],
      {
        queryParams: params,
      }
    );
  }

  onChangeViewType(type: BoxType) {
    this.search.boxType = type;

    // Atualiza o valor do viewType para grid ou lista
    this.sm.defineViewType(type == BoxType.Grid ? PropertyViewType.GRID : PropertyViewType.LIST);

    if (this.ssr?.isBrowser)
      setTimeout(() => {
        let _this = this;
        $(function () {
          $('[data-toggle="tooltip"]').tooltip(); // tem que ser jquery
          // ng-lazyload-image
          this.ssr?.getWindow().dispatchEvent(_this.scrollEvent);
        });
      }, 500);
  }

  onChangeOrder(order: string) {
    // Evento do Google Analytics
    this.ga.trackEvent('change', 'order', order);

    this.search.ordem = order;
    this.search.pag = 1;
    this.onChangeRoute();
  }

  async onChangePage(pag: number) {
    this.search.pag = pag;
    this.onChangeRoute();
  }

  doSearch() {
    this.loading = true;
    this.propertyService.search(this.search).subscribe(
      result => {
        this.resultPage = result;
        this.loading = false;
        // @diegodsp: se buscou pelo código, vai direto para o imóvel
        if (this.search.codigo && result.items?.length == 1) {
          // @diegodsp: para poder entrar na busca novamente, limpa o código da busca utilizada
          this.search.codigo = undefined;
          this.sm.saveCookie(this.search);
          // @diegodsp: direciona para o imóvel encontrado (não usar navigateUrl)
          this.router.navigate([result?.items[0]?.internalUrl], { replaceUrl: true });
        }
        //
        this.updateSEO();
      },
      error => {
        this.loading = false;
        this.resultPage = new ResultPage<Imovel>();
        console.error(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar imóveis. Tente novamente'));
      }
    );

    // scroll to top
    if (this.search?.pag != 1) iScrollTo(0, 0);
  }

  // /**
  //  * Pega o nome do bairro selecionado pelo slug.
  //  */
  // public get bairroSelecionado(): string {
  //   const v = this.search?.bairros[0];
  //   return this.gruposBairros?.flatMap(x => x.bairros)?.filter(x => x.slug == v)?.[0]?.nome || '';
  // }

  // /**
  //  * Pega o nome do empreendimento selecionado pelo slug.
  //  */
  // public get empreendimentoSelecionado(): string {
  //   if (this.search.empreendimentos?.length === 1) {
  //     const x = this.enterprises?.filter(x => x.slug === this.search.empreendimentos?.[0])?.[0].nome;
  //     return x ? `<br/>Empreendimento ${x}` : '';
  //   }
  //   return '';
  // }
}
