import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Search } from 'src/app/shared/models/search';
import { SearchItem } from 'src/app/shared/models/search-item';
import { SateliteService } from 'src/app/shared/services/satelite.service';
import { SessionManager } from 'src/app/shared/util/session-manager';

@Component({
  selector: 'app-select-construtora',
  templateUrl: './select-construtora.component.html',
  styleUrls: ['./select-construtora.component.scss'],
})
export class SelectConstrutoraComponent implements OnInit {
  builders: SearchItem[];
  filteredBuilders: SearchItem[];
  @Input() search: Search;
  @Output() changeSearch = new EventEmitter<Search>(true);

  constructor(
    private cd: ChangeDetectorRef,
    private sateliteService: SateliteService) {}

  ngOnInit(): void {
    this.sateliteService.getConstrutoras(this.search?.cidade).subscribe(
      result => {
        this.builders = result;
        this.filteredBuilders = Object.assign([], result); // copy
      },
      error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar construtoras');
      }
    );
  }

  /**
   * Ajusta filtro de construtoras.
   *
   * @param value
   */
  onFilterBuilders(value: string) {
    if (value) {
      this.filteredBuilders = this.builders.filter(item => item?.nome?.toLowerCase().indexOf(value.toLowerCase()) >= 0);
    } else this.filteredBuilders = Object.assign([], this.builders);
  }

  /**
   * Limpa filtro de construtoras.
   */
  onClearBuilders() {
    this.search.construtoras = [];

    this.cd.detectChanges();
    this.changeSearch.emit(this.search);
  }
}
