import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';
import { BoxType } from 'src/app/shared/components/box/box.component';
import { Banner } from 'src/app/shared/models/banner';
import { Imovel } from 'src/app/shared/models/imovel';
import { Search } from 'src/app/shared/models/search';
import { ResultPage } from 'src/app/shared/models/search-result';
import { BannerService } from 'src/app/shared/services/banner.service';
import { ImovelService } from 'src/app/shared/services/imovel.service';
import { SessionManager } from 'src/app/shared/util/session-manager';
import { PAGE_TITLE } from 'src/globals';

@Component({
  selector: 'app-exclusive-page',
  templateUrl: './exclusive-page.component.html',
  styleUrls: ['./exclusive-page.component.scss'],
})
export class ExclusivePageComponent implements OnInit {
  BoxType = BoxType;
  banner$: Observable<Banner>;
  imoveis$: Observable<ResultPage<Imovel>>;
  search: Search = new Search();

  constructor(
    private titleService: Title,
    private bannerService: BannerService,
    private imovelService: ImovelService,
    private router: Router,
    private route: ActivatedRoute,
    private sm: SessionManager
  ) {
    // Observa as alterações nos params
    this.route.queryParams.subscribe(params => {
      this.search.fromQuerySearch(params);
      this.doSearch();
    });
    // Observa as alterações na rota
    this.route.params.subscribe(params => {
      const { status, cidade } = params;
      if (status) this.search.status = status === 'venda' ? '0' : '1';
      if (cidade) this.search.cidade = cidade;
      else {
        // define pelo cookie e retorna
        const search = this.sm.defineSearchFromCookie();
        // muda url para auxiliar o gatilho dos chats
        if (search && search.cidade) this.router.navigateByUrl(`/imoveis/exclusividades/${search.cidade}`);
      }
      this.doSearch();
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(`Exclusividades - ${PAGE_TITLE}`);

    this.loadData();
  }

  loadData() {
    // banner
    this.banner$ = this.bannerService.getExclusivo(undefined).pipe(
      catchError(error => {
        console.log(SessionManager.getHttpErrorMessage(error, 'Falha ao consultar banner.'));
        return of(null);
      }),
      shareReplay(1)
    );
  }

  async onChangePage(pag: number) {
    this.search.pag = pag;
    this.onChangeRoute();
  }

  onChangeRoute(s: Search = this.search) {
    this.search = s;
    // monta params
    const params = this.search?.toQuerySearch();

    // muda url
    if (this.search && this.search.cidade)
      this.router.navigate(['/imoveis/exclusividades/', this.search?.cidade], {
        queryParams: params,
      });
  }

  doSearch() {
    this.search.exclusivo = ['true'];
    this.imoveis$ = this.imovelService.search(this.search).pipe(
      tap(x => {
        if (this.search.cidadeNome)
          this.titleService.setTitle(`Exclusividades em ${this.search.cidadeNome} - ${PAGE_TITLE}`);
      }),
      catchError(error => {
        SessionManager.handleHttpError(error, 'Falha ao consultar os exclusividades');
        return of({} as ResultPage<Imovel>);
      })
    );
  }
}
